import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewPublicContainer } from './public'
import { getViewSharedContainer } from './shared'
import { getViewAdminContainer } from './admin'

export function getViewContainer(container = defaultContainer) {
  return compose(
    getViewPublicContainer,
    getViewSharedContainer,
    getViewAdminContainer
  )(container)
}
