/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @description playground buat view terkait purchasing organization
 * referensi:
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */

import {
  IOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById,
  IOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_GET_RESOURCE_DATA_BY_ID,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_CREATE,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_UPDATE,
  useOrganizationalStructureControllerAdminPurchasingOrganizationDataTableManagementGetComponentPropsBase,
} from '@/controllers'
import { DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit } from '@/resources'
import {
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'

export interface OrganizationalStructureViewPageAdminPurchasingOrganizationListsBaseProps
  extends PropsWithInnerRef {}

const OrganizationalStructureViewPageAdminPurchasingOrganizationListsBase = <
  PropType extends
    OrganizationalStructureViewPageAdminPurchasingOrganizationListsBaseProps = OrganizationalStructureViewPageAdminPurchasingOrganizationListsBaseProps,
  PurchasingOrganizationByIdType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getPurchasingOrganizationByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById<PurchasingOrganizationByIdType>
  >(
    ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_GET_RESOURCE_DATA_BY_ID
  )
  const modalUpdatePurchasingOrganizationByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate<PurchasingOrganizationByIdType>
  >(
    ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_UPDATE
  )
  const modalCreateSinglePurchasingOrganizationCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_CREATE
    )
  const { getComponentProps, refreshTableData } =
    useOrganizationalStructureControllerAdminPurchasingOrganizationDataTableManagementGetComponentPropsBase<PurchasingOrganizationByIdType>(
      getPurchasingOrganizationByIdCtrl,
      modalUpdatePurchasingOrganizationByIdCtrl,
      modalCreateSinglePurchasingOrganizationCtrl
    )
  const handleHide = () => refreshTableData?.()
  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSinglePurchasingOrganizationCtrl.ModalComponent
        onHide={handleHide}
      />
      <modalUpdatePurchasingOrganizationByIdCtrl.ModalComponent
        onHide={handleHide}
      />
    </>
  )
}

export const OrganizationalStructureViewPageAdminPurchasingOrganizationLists =
  addRefProps(
    OrganizationalStructureViewPageAdminPurchasingOrganizationListsBase
  )
