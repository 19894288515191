import { useFormikContext } from 'formik'

import { Button, ButtonBaseProps, addRefProps } from '@procuraid-frontend/core'

export interface CoreViewAtomButtonReactiveFormBaseProps<Value = unknown>
  extends ButtonBaseProps {
  checkDisabled(value: Value): boolean
}

const CoreViewAtomButtonReactiveFormBase = <
  Value = unknown,
  PropType extends
    CoreViewAtomButtonReactiveFormBaseProps<Value> = CoreViewAtomButtonReactiveFormBaseProps<Value>,
>({
  innerRef,
  checkDisabled,
  ...otherProps
}: PropType) => {
  const { values } = useFormikContext<Value>()
  return (
    <Button ref={innerRef} {...otherProps} disabled={checkDisabled(values)} />
  )
}

export const CoreViewAtomButtonReactiveForm = addRefProps<
  HTMLButtonElement,
  CoreViewAtomButtonReactiveFormBaseProps
>(CoreViewAtomButtonReactiveFormBase)
