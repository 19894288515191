/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContainerGet } from 'inversify-hooks-esm'
import { useCallback, useState } from 'react'

import {
  CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE,
  CORE_SERVICE_LOOKUP_BASE,
  DefaultDropdownOptionsType,
  ICoreControllerSharedPageDropdownManagementOptionsFunctionAsync,
  ICoreResourceOrganizationalStructureOnlySearch,
  ICoreServiceLookupBase,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedTransformToDropdownOptionsBase,
  useCoreControllerSharedTransformToDropdownOptionsFromResource,
} from '@procuraid-frontend/core'

export interface DefaultBankOptionsFunctionAsyncKeys {
  keys: 'country' | 'district' | 'bankType'
}

export function useGeneralConfigurationControllerAdminBankDropdownManagementOptionsCreateOrUpdate<
  KeyType extends
    DefaultBankOptionsFunctionAsyncKeys = DefaultBankOptionsFunctionAsyncKeys,
  DropdownOptionsType extends
    DefaultDropdownOptionsType = DefaultDropdownOptionsType,
>(): ICoreControllerSharedPageDropdownManagementOptionsFunctionAsync<
  KeyType['keys'],
  null | undefined,
  DropdownOptionsType
> {
  const [loadings, setLoadings] = useState<Record<KeyType['keys'], boolean>>({
    country: true,
    district: true,
    bankType: false,
  } as Record<KeyType['keys'], boolean>)

  const { transformDataToDropdownOptions } =
    useCoreControllerSharedTransformToDropdownOptionsBase()
  const { transformResourceDataToDropdownOptions } =
    useCoreControllerSharedTransformToDropdownOptionsFromResource()
  const translate = useCoreControllerSharedLocalizationTranslate()

  const lookupBaseService = useContainerGet<ICoreServiceLookupBase>(
    CORE_SERVICE_LOOKUP_BASE
  )
  const regionResource = useContainerGet<
    ICoreResourceOrganizationalStructureOnlySearch<{ data: { items: any[] } }>
  >(CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE)

  const lookupBankType =
    lookupBaseService.getValue<string[]>('bank_types') ?? []

  const bankTypeOptions = transformDataToDropdownOptions(
    lookupBankType,
    (name) => translate(name)
  )

  const [options, setOptions] = useState<
    Record<KeyType['keys'], DropdownOptionsType[]>
  >({
    country: [],
    district: [],
    bankType: bankTypeOptions,
  } as Record<KeyType['keys'], DropdownOptionsType[]>)

  const createOptions = useCallback(async () => {
    try {
      const country = await transformResourceDataToDropdownOptions<
        any,
        any,
        any,
        DropdownOptionsType
      >(
        () =>
          regionResource.search({
            params: {
              region_type: 'COUNTRY',
            },
          }),
        (item) => `${item?.code} - ${item?.name}`
      )
      const district = await transformResourceDataToDropdownOptions<
        any,
        any,
        any,
        DropdownOptionsType
      >(
        () =>
          regionResource.search({
            params: {
              region_type: 'DISTRICT',
            },
          }),
        (item) => `${item?.code} - ${item?.name}`
      )
      return {
        country,
        district,
        bankType: [],
      }
    } finally {
      setLoadings(
        (prevValue) =>
          ({
            ...prevValue,
            country: false,
            district: false,
          }) as Record<KeyType['keys'], boolean>
      )
    }
  }, [regionResource, transformResourceDataToDropdownOptions])

  const loadOptions = useCallback(async () => {
    const { country, district } = await createOptions()
    setOptions((prevValue) => ({ ...prevValue, country, district }))
  }, [createOptions])

  return {
    options,
    createOptions,
    loadings,
    loadOptions,
  }
}
