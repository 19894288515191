import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getSharedContainer } from '../../shared'
import { getServiceMainThreadToContainer } from './services'
import { getControllersMainThreadIntoContainer } from './controllers'
import { getRoutersMainThreadIntoContainer } from './routers'
import { getResourcesMainThreadIntoContainer } from './resources'
import { getViewContainer } from './views'
import { getMainThreadSharedServices } from '../shared'

function getMainThreadComponentContainer(container = defaultContainer) {
  return compose(
    getViewContainer,
    getRoutersMainThreadIntoContainer,
    getControllersMainThreadIntoContainer,
    getResourcesMainThreadIntoContainer,
    getServiceMainThreadToContainer,
    getMainThreadSharedServices,
    getSharedContainer
  )(container)
}

export const componentContainer = getMainThreadComponentContainer()
