import {
  DefaultSpreadsheetResponseBodyImport,
  PropsWithInnerRef,
  addRefProps,
} from '@procuraid-frontend/core'

export interface CoreViewMoleculeLabelSpreadsheetPreviewErrorsBaseProps<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
> extends PropsWithInnerRef {
  /**
   * @default null
   */
  data?: null | Record<string, DataType['data']['errors']>
}

const CoreViewMoleculeLabelSpreadsheetPreviewErrorsBase = <
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  PropType extends
    CoreViewMoleculeLabelSpreadsheetPreviewErrorsBaseProps<DataType> = CoreViewMoleculeLabelSpreadsheetPreviewErrorsBaseProps<DataType>,
>({
  innerRef,
  data = null,
}: PropType) => {
  if (data === null) {
    return null
  }
  const [key, items] = Object.entries(data)[0]
  return (
    <div
      ref={innerRef}
      className="d-flex flex-column align-items-start justify-content-center alert alert-danger mb-2"
      role="alert"
      style={{ flex: 1, width: '100%' }}
    >
      <span>{`Terjadi ketidaksesuaian data di baris ${
        parseInt(key) + 1
      }`}</span>
      <ul>
        {items.map((item, index) => (
          <li key={`error-spreadsheet-${key}-${index}`}>{item.message}</li>
        ))}
      </ul>
    </div>
  )
}

export const CoreViewMoleculeLabelSpreadsheetPreviewErrors = addRefProps(
  CoreViewMoleculeLabelSpreadsheetPreviewErrorsBase
)
