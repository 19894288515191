import { container as defaultContainer } from 'inversify-hooks-esm'

import { CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE } from '@procuraid-frontend/core'
import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_UPDATE,
  useGeneralConfigurationControllerAdminRegionGetResourceDataById,
  useGeneralConfigurationControllerAdminRegionModalManagementCreate,
  useGeneralConfigurationControllerAdminRegionModalManagementCreateFromSpreadsheetFile,
  useGeneralConfigurationControllerAdminRegionModalManagementUpdate,
} from '@procuraid-frontend/general-configuration'

export function getViewAdminGeneralConfigurationRegionListsContainer(
  container = defaultContainer
) {
  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE)
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminRegionModalManagementCreate()
    )

  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_UPDATE)
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminRegionModalManagementUpdate()
    )

  container
    .bind(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminRegionModalManagementCreateFromSpreadsheetFile()
    )

  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_GET_RESOURCE_DATA_BY_ID)
    .toDynamicValue(({ container: c }) =>
      useGeneralConfigurationControllerAdminRegionGetResourceDataById(
        c.get(CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE)
      )
    )
  return container
}
