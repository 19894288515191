import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  CORE_SERVICE_APP_CONFIGURATION_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_NUMBRO_BASE,
  CORE_SERVICE_WEB_WORKER_BASE,
  //   CoreServiceStateManagementSelectorUserBase,
  CoreServiceWebWorkerBase,
} from '@procuraid-frontend/core'

import {
  CoreServiceThirdPartyIntegratorNumbroBase,
  getCoreServiceAppConfigurationBase,
} from '../../services'

export function addSharedServicesIntoContainer(container = defaultContainer) {
  container
    .bind(CORE_SERVICE_APP_CONFIGURATION_BASE)
    .toDynamicValue(() => getCoreServiceAppConfigurationBase())
    .inSingletonScope()

  container.addSingleton(CoreServiceWebWorkerBase, CORE_SERVICE_WEB_WORKER_BASE)

  //   container
  //     .bind(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
  //     .toDynamicValue(() => getCoreServiceStateManagementSelectorUserBase())
  //     .inSingletonScope();

  //   container
  //     .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_HISTORY_BASE)
  //     .toConstantValue(CoreServiceThirdPartyIntegratorHistoryBase);

  //   container
  //     .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_AXIOS_BASE)
  //     .toDynamicValue(({ container: c }) =>
  //       getCoreServiceThirdPartyIntegratorAxiosBase(
  //         c.get(CORE_SERVICE_APP_CONFIGURATION_BASE),
  //         c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_HISTORY_BASE)
  //       )
  //     );

  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_NUMBRO_BASE)
    .toConstantValue(CoreServiceThirdPartyIntegratorNumbroBase)

  //   container
  //     .bind(CORE_SERVICE_REMOTE_BASE)
  //     .toDynamicValue(({ container: c }) => {
  //       console.log(c);
  //       return useCoreServiceRemoteBase(
  //         c.get<AxiosInstance>(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_AXIOS_BASE),
  //         c.get(CORE_SERVICE_WEB_WORKER_BASE),
  //         c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
  //       );
  //     });
  // .inSingletonScope();

  return container
}
