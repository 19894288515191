import {
  CoreViewMoleculeInputFileBaseProps,
  CoreViewMoleculeInputFile,
  addRefProps,
} from '@procuraid-frontend/core'

export interface CoreViewMoleculeInputFileSpreadsheetBaseProps
  extends CoreViewMoleculeInputFileBaseProps {
  /**
   * @default attachmentData
   */
  attributeNameForAttachmentData?: string

  /**
   * @default spreadsheetData
   */
  attributeNameForSpreadsheetData?: string
}

const CoreViewMoleculeInputFileSpreadsheetBase = <
  PropType extends
    CoreViewMoleculeInputFileSpreadsheetBaseProps = CoreViewMoleculeInputFileSpreadsheetBaseProps,
>({
  innerRef,
  attributeNameForAttachmentData = 'attachmentData',
  multiple = false,
  ...otherProps
}: PropType) => {
  const name = otherProps?.name ?? attributeNameForAttachmentData

  return (
    <CoreViewMoleculeInputFile
      ref={innerRef}
      {...otherProps}
      name={name}
      multiple={multiple}
      accept={{
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
          '.xlsx',
        ],
      }}
    />
  )
}

export const CoreViewMoleculeInputFileSpreadsheet = addRefProps(
  CoreViewMoleculeInputFileSpreadsheetBase
)
