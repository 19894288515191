import {
  ICoreServiceRemoteBase,
  useCoreResourceOrganizationalStructureBase,
} from '@procuraid-frontend/core'

export const CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE =
  'CoreResourceOrganizationalStructurePurchasingOrganizationBase'

/**
 * @description playground buat resource terkait purchasing organization.
 * referensi:
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */
export function useCoreResourceOrganizationalStructurePurchasingOrganizationBase(
  remoteService: ICoreServiceRemoteBase
) {
  return useCoreResourceOrganizationalStructureBase({
    pathname: '/prcmt/purch_orgs',
    remoteService,
  })
}
