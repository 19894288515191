import { container as defaultContainer } from 'inversify-hooks-esm'
import { AxiosInstance } from 'axios'
import {
  CORE_SERVICE_APP_CONFIGURATION_BASE,
  CORE_SERVICE_ARRAY_HANDLER_BASE,
  CORE_SERVICE_ATTACHMENT_BASE,
  CORE_SERVICE_ATTACHMENT_FILE_EXTENSION_CATEGORIES,
  CORE_SERVICE_ATTACHMENT_FORM_DATA_HANDLER,
  CORE_SERVICE_BOOLEAN_LIKE_VALUE_HANDLER_BASE,
  CORE_SERVICE_DATA_TABLE_FILTER_DATE,
  CORE_SERVICE_DATA_TABLE_SIZE_PER_PAGE,
  CORE_SERVICE_DATE_FORMATTER_BASE,
  CORE_SERVICE_DATE_FORMATTER_INPUT_FORM,
  CORE_SERVICE_DATE_FORMATTER_SCHEDULING,
  CORE_SERVICE_DATE_FORMATTER_SERVER_TIME,
  CORE_SERVICE_EXCEPTION_NETWORK_ERROR,
  CORE_SERVICE_EXCEPTION_NOT_AUTHORIZED,
  CORE_SERVICE_EXPORTER_BASE,
  CORE_SERVICE_EXPORTER_PDF,
  CORE_SERVICE_EXPORTER_XLS,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_DATA_TABLE,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW,
  CORE_SERVICE_JSON_PROCESSOR_BASE,
  CORE_SERVICE_LOCALIZATION_BASE,
  CORE_SERVICE_LOOKUP_BASE,
  CORE_SERVICE_NAVIGATION_ITEM_BASE,
  CORE_SERVICE_NAVIGATION_ITEM_PRIVATE,
  CORE_SERVICE_NUMBER_BASE,
  CORE_SERVICE_NUMBER_SECTIONIZE,
  CORE_SERVICE_PRIVILEGE_BASE,
  CORE_SERVICE_REMOTE_BASE,
  CORE_SERVICE_ROUTER_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_DATA_TABLE_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_SERVER_TIME_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_DATA_TABLE_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_SERVER_TIME_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_AXIOS_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_HISTORY_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_INIT,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_NUMBRO_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_LANGUAGE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_THEME_COLORS,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_USER_AVATAR_FALLBACK,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_VALUE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_FIRST_HISTORY_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_MIDDLEWARE_ASSIGN_STORE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_PERSIST_CONFIGURATION,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_REDUCER_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_DATA_TABLE,
  CORE_SERVICE_TOAST_BASE,
  CoreServiceDataTableSizePerPage,
  CoreServiceLookupBase,
  CoreServicePrivilegeBase,
  CoreServiceRouterBase,
  CoreServiceThirdPartyIntegratorHistoryBase,
  CoreServiceThirdPartyIntegratorReduxMiddlewareAssignStore,
  ICoreServiceAppConfigurationBase,
  ICoreServiceExporterBase,
  ICoreServiceNavigationItemBase,
  ICoreServicePrivilegeBase,
  ICoreServiceStateManagementInstanceDataTableBase,
  ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue,
  ICoreServiceThirdPartyIntegratorReduxPersistConfiguration,
  ICoreServiceThirdPartyIntegratorReduxReducerBase,
  getCoreServiceAttachmentFileExtensionCategories,
  getCoreServiceBooleanLikeValueHandlerBase,
  getCoreServiceDataTableFilterDate,
  getCoreServiceJsonProcessorBase,
  // getCoreServiceNavigationItemBase,
  getCoreServiceStateManagementSelectorAppBase,
  getCoreServiceStateManagementSelectorUserBase,
  getCoreServiceThirdPartyIntegratorReduxFirstHistoryBase,
  getCoreServiceThirdPartyIntegratorReduxStoreBase,
  useCoreServiceArrayHandlerBase,
  useCoreServiceAttachmentBase,
  useCoreServiceAttachmentFormDataHandler,
  useCoreServiceDateFormatterBase,
  useCoreServiceDateFormatterInputForm,
  useCoreServiceDateFormatterScheduling,
  useCoreServiceDateFormatterServerTime,
  useCoreServiceExceptionNetworkError,
  useCoreServiceExceptionNotAuthorized,
  useCoreServiceExporterBase,
  useCoreServiceExporterPDF,
  useCoreServiceExporterXLS,
  useCoreServiceJavaScriptGlobalDocumentObjectModelDataTable,
  useCoreServiceLocalizationBase,
  useCoreServiceNumberBase,
  useCoreServiceNumberSectionize,
  useCoreServiceRemoteBase,
  useCoreServiceStateManagementInstanceAppBase,
  useCoreServiceStateManagementInstanceDataTableBase,
  useCoreServiceStateManagementInstanceServerTimeBase,
  useCoreServiceStateManagementInstanceUserBase,
  useCoreServiceStateManagementSelectorDataTableBase,
  useCoreServiceStateManagementSelectorServerTimeBase,
  useCoreServiceThirdPartyIntegratorAxiosBase,
  useCoreServiceThirdPartyIntegratorI18nextInit,
  useCoreServiceThirdPartyIntegratorReactContextCustomerAssetsBase,
  useCoreServiceThirdPartyIntegratorReduxStoreDataTable,
  useCoreServiceToastBase,
} from '@procuraid-frontend/core'

import {
  CoreServiceThirdPartyIntegratorI18nextResourceBase,
  CoreServiceThirdPartyIntegratorReactContextCustomerAssetsLanguage,
  CoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors,
  CoreServiceThirdPartyIntegratorReactContextCustomerAssetsUserAvatarFallback,
  CoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue,
  getCoreServiceNavigationItemPrivate,
  getCoreServiceThirdPartyIntegratorReduxReducerBase,
  getDemoServiceNavigationItemBase,
  getServiceCoreSharedThirdPartyIntegratorReduxPersistConfiguration,
  // useCoreServiceThirdPartyIntegratorI18nextInit,
} from '@/services'

export function getServiceMainThreadToContainer(container = defaultContainer) {
  // ARRAY_HANDLER - BASE
  container
    .bind(CORE_SERVICE_ARRAY_HANDLER_BASE)
    .toDynamicValue(() => useCoreServiceArrayHandlerBase())
    .inSingletonScope()

  // BOOLEAN_LIKE_VALUE_HANDLER - BASE
  container
    .bind(CORE_SERVICE_BOOLEAN_LIKE_VALUE_HANDLER_BASE)
    .toDynamicValue(() => getCoreServiceBooleanLikeValueHandlerBase())
    .inSingletonScope()

  // DATE_FORMATTER
  // DATE_FORMATTER - BASE
  container
    .bind(CORE_SERVICE_DATE_FORMATTER_BASE)
    .toDynamicValue(() => useCoreServiceDateFormatterBase())
    .inSingletonScope()
  // DATE_FORMATTER - INPUT_FORM
  container
    .bind(CORE_SERVICE_DATE_FORMATTER_INPUT_FORM)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceDateFormatterInputForm(
        c.get(CORE_SERVICE_DATE_FORMATTER_BASE),
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE)
      )
    )
    .inSingletonScope()
  // DATE_FORMATTER - SCHEDULING
  container
    .bind(CORE_SERVICE_DATE_FORMATTER_SCHEDULING)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceDateFormatterScheduling(
        c.get(CORE_SERVICE_DATE_FORMATTER_INPUT_FORM)
      )
    )
  // DATE_FORMATTER - SERVER_TIME
  container
    .bind(CORE_SERVICE_DATE_FORMATTER_SERVER_TIME)
    .toDynamicValue(() => useCoreServiceDateFormatterServerTime())
    .inSingletonScope()

  // JAVASCRIPT_GLOBALS
  // JAVASCRIPT_GLOBALS - DOCUMENT_OBJECT_MODEL
  // JAVASCRIPT_GLOBALS - DOCUMENT_OBJECT_MODEL - BASE (ALREADY DEFINED INSIDE MAIN THREAD SHARED SERVICES)

  // JAVASCRIPT_GLOBALS - LOCATION (ALREADY DEFINED INSIDE MAIN THREAD SHARED SERVICES)

  // JAVASCRIPT_GLOBALS - NAVIGATOR (ALREADY DEFINED INSIDE MAIN THREAD SHARED SERVICES)

  // JAVASCRIPT_GLOBALS - WINDOW (ALREADY DEFINED INSIDE MAIN THREAD SHARED SERVICES)

  // JAVASCRIPT_GLOBALS - DOCUMENT_OBJECT_MODEL - DATA_TABLE
  container
    .bind(CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_DATA_TABLE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceJavaScriptGlobalDocumentObjectModelDataTable(
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE)
      )
    )

  // JSON_PROCESSOR
  // JSON_PROCESSOR - BASE
  container
    .bind(CORE_SERVICE_JSON_PROCESSOR_BASE)
    .toDynamicValue(() => getCoreServiceJsonProcessorBase())
    .inSingletonScope()

  // NAVIGATION_ITEM
  // NAVIGATION_ITEM - BASE
  container
    .bind(CORE_SERVICE_NAVIGATION_ITEM_BASE)
    .toDynamicValue(() => getDemoServiceNavigationItemBase())

  // NAVIGATION_ITEM - PRIVATE
  container
    .bind(CORE_SERVICE_NAVIGATION_ITEM_PRIVATE)
    .toDynamicValue(() => getCoreServiceNavigationItemPrivate())
    .inSingletonScope()

  // PRIVILEGE
  // PRIVILEGE - BASE
  container.addSingleton(CoreServicePrivilegeBase, CORE_SERVICE_PRIVILEGE_BASE)

  // ROUTER
  // ROUTER - BASE
  container
    .bind(CORE_SERVICE_ROUTER_BASE)
    .toConstantValue(CoreServiceRouterBase)

  // STATE_MANAGEMENT
  // STATE_MANAGEMENT - SELECTORS
  // STATE_MANAGEMENT - SELECTORS - APP
  // STATE_MANAGEMENT - SELECTORS - APP - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE)
    .toDynamicValue(() => getCoreServiceStateManagementSelectorAppBase())
    .inSingletonScope()

  // STATE_MANAGEMENT - SELECTORS - USER
  // STATE_MANAGEMENT - SELECTORS - USER - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
    .toDynamicValue(() => getCoreServiceStateManagementSelectorUserBase())
    .inSingletonScope()

  // STATE_MANAGEMENT - SELECTORS - SERVER_TIME
  // STATE_MANAGEMENT - SELECTORS - SERVER_TIME - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_SERVER_TIME_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceStateManagementSelectorServerTimeBase(
        c.get(CORE_SERVICE_DATE_FORMATTER_INPUT_FORM)
      )
    )

  // STATE_MANAGEMENT - INSTANCES
  // STATE_MANAGEMENT - INSTANCES - APP
  // STATE_MANAGEMENT - INSTANCES - APP - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceStateManagementInstanceAppBase(
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE)
      )
    )
    .inSingletonScope()

  // STATE_MANAGEMENT - INSTANCES - SERVER_TIME
  // STATE_MANAGEMENT - INSTANCES - SERVER_TIME - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_SERVER_TIME_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceStateManagementInstanceServerTimeBase(
        c.get(CORE_SERVICE_DATE_FORMATTER_SERVER_TIME)
      )
    )
    .inSingletonScope()

  // STATE_MANAGEMENT - INSTANCES - USER
  // STATE_MANAGEMENT - INSTANCES - USER - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceStateManagementInstanceUserBase(
        c.get<ICoreServicePrivilegeBase>(CORE_SERVICE_PRIVILEGE_BASE),
        c.get<ICoreServiceNavigationItemBase>(
          CORE_SERVICE_NAVIGATION_ITEM_BASE
        ),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE)
      )
    )
    .inSingletonScope()

  // USER_BROWSER
  // USER_BROWSER - BASE (ALREADY DEFINED INSIDE MAIN THREAD SHARED SERVICES)

  // USER_BROWSER - FIREFOX (DEFINED INSIDE MAIN THREAD INITIALIZER SERVICES INSTEAD)

  // WEB_WORKER
  // WEB_WORKER - BASE (ALREADY DEFINED INSIDE SHARED SERVICES)

  // EXPORTER
  // EXPORTER - BASE
  container
    .bind(CORE_SERVICE_EXPORTER_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceExporterBase(
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
      )
    )

  // EXPORTER - PDF
  container
    .bind(CORE_SERVICE_EXPORTER_PDF)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceExporterPDF(
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE),
        c.get(CORE_SERVICE_EXPORTER_BASE)
      )
    )

  // EXPORTER - XLS
  container
    .bind(CORE_SERVICE_EXPORTER_XLS)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceExporterXLS(
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE),
        c.get<ICoreServiceExporterBase>(CORE_SERVICE_EXPORTER_BASE)
      )
    )

  // THIRD_PARTY_INTEGRATOR
  // THIRD_PARTY_INTEGRATOR - HISTORY
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_HISTORY_BASE)
    .toConstantValue(CoreServiceThirdPartyIntegratorHistoryBase)

  // THIRD_PARTY_INTEGRATOR - REACT
  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT
  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS
  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS - VALUE
  container
    .bind(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_VALUE
    )
    .toConstantValue(
      CoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue
    )

  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS - BASE
  container
    .bind(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_BASE
    )
    .toDynamicValue(({ container: c }) =>
      useCoreServiceThirdPartyIntegratorReactContextCustomerAssetsBase(
        c.get<ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue>(
          CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_VALUE
        )
      )
    )
    .inSingletonScope()

  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS - THEME_COLORS
  container
    .bind(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_THEME_COLORS
    )
    .toConstantValue(
      CoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors
    )

  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS - USER_AVATAR_FALLBACK
  container
    .bind(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_USER_AVATAR_FALLBACK
    )
    .toConstantValue(
      CoreServiceThirdPartyIntegratorReactContextCustomerAssetsUserAvatarFallback
    )

  // THIRD_PARTY_INTEGRATOR - REACT - CONTEXT - CUSTOMER_ASSETS - LANGUAGE
  container
    .bind(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_LANGUAGE
    )
    .toConstantValue(
      CoreServiceThirdPartyIntegratorReactContextCustomerAssetsLanguage
    )

  // THIRD_PARTY_INTEGRATOR - I18NEXT
  // THIRD_PARTY_INTEGRATOR - I18NEXT - RESOURCE
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_BASE)
    .toConstantValue(CoreServiceThirdPartyIntegratorI18nextResourceBase)

  // THIRD_PARTY_INTEGRATOR - I18NEXT - INIT
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_INIT)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceThirdPartyIntegratorI18nextInit(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_BASE),
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE),
        c.get(CORE_SERVICE_DATE_FORMATTER_INPUT_FORM),
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_NUMBRO_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE)
      )
    )
    .inSingletonScope()

  // THIRD_PARTY_INTEGRATOR - REDUX_FIRST_HISTORY
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_FIRST_HISTORY_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceThirdPartyIntegratorReduxFirstHistoryBase(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_HISTORY_BASE)
      )
    )
    .inSingletonScope()

  // THIRD_PARTY_INTEGRATOR - REDUX_PERSIST
  // THIRD_PARTY_INTEGRATOR - REDUX_PERSIST - CONFIGURATION
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_PERSIST_CONFIGURATION)
    .toDynamicValue(({ container: c }) =>
      getServiceCoreSharedThirdPartyIntegratorReduxPersistConfiguration(
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE)
      )
    )
    .inSingletonScope()

  // THIRD_PARTY_INTEGRATOR - REDUX
  // THIRD_PARTY_INTEGRATOR - REDUX - MIDDLEWARE
  // THIRD_PARTY_INTEGRATOR - REDUX - MIDDLEWARE - ASSIGN_STORE
  container.addSingleton(
    CoreServiceThirdPartyIntegratorReduxMiddlewareAssignStore,
    CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_MIDDLEWARE_ASSIGN_STORE
  )

  // THIRD_PARTY_INTEGRATOR - REDUX - REDUCER
  // THIRD_PARTY_INTEGRATOR - REDUX - REDUCER - BASE
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_REDUCER_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceThirdPartyIntegratorReduxReducerBase(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_SERVER_TIME_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE),
        c.get<ICoreServiceThirdPartyIntegratorReduxPersistConfiguration>(
          CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_PERSIST_CONFIGURATION
        )
      )
    )
    .inSingletonScope()

  // THIRD_PARTY_INTEGRATOR - REDUX - STORE
  // THIRD_PARTY_INTEGRATOR - REDUX - STORE - BASE
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceThirdPartyIntegratorReduxStoreBase(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_FIRST_HISTORY_BASE),
        c.get<ICoreServiceThirdPartyIntegratorReduxReducerBase>(
          CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_REDUCER_BASE
        ),
        c.get(
          CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_MIDDLEWARE_ASSIGN_STORE
        ),
        import.meta.env.VITE_APP_ENV
      )
    )
    .inSingletonScope()

  /**
   * @todo add store for data table
   */

  // LOCALIZATION
  // LOCALIZATION - BASE
  container
    .bind(CORE_SERVICE_LOCALIZATION_BASE)
    .toDynamicValue(() => useCoreServiceLocalizationBase())
    .inSingletonScope()

  // NUMBER
  // NUMBER - BASE
  container
    .bind(CORE_SERVICE_NUMBER_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceNumberBase(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_NUMBRO_BASE)
      )
    )
    .inSingletonScope()

  // NUMBER - SECTIONIZE
  container
    .bind(CORE_SERVICE_NUMBER_SECTIONIZE)
    .toDynamicValue(() => useCoreServiceNumberSectionize())
    .inSingletonScope()

  // ATTACHMENT
  // ATTACHMENT - FILE_EXTENSION_CATEGORIES
  container
    .bind(CORE_SERVICE_ATTACHMENT_FILE_EXTENSION_CATEGORIES)
    .toConstantValue(getCoreServiceAttachmentFileExtensionCategories())

  // ATTACHMENT - BASE
  container
    .bind(CORE_SERVICE_ATTACHMENT_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceAttachmentBase(
        c.get<ICoreServiceAppConfigurationBase>(
          CORE_SERVICE_APP_CONFIGURATION_BASE
        ),
        c.get(CORE_SERVICE_JSON_PROCESSOR_BASE),
        c.get(CORE_SERVICE_EXPORTER_BASE),
        c.get(CORE_SERVICE_ATTACHMENT_FILE_EXTENSION_CATEGORIES)
      )
    )
  // ATTACHMENT - FORM_DATA_HANDLER
  container
    .bind(CORE_SERVICE_ATTACHMENT_FORM_DATA_HANDLER)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceAttachmentFormDataHandler(
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW),
        c.get(CORE_SERVICE_DATE_FORMATTER_BASE)
      )
    )

  // DATA_TABLE
  // DATA_TABLE - FILTER - DATE
  container
    .bind(CORE_SERVICE_DATA_TABLE_FILTER_DATE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceDataTableFilterDate(c.get(CORE_SERVICE_LOCALIZATION_BASE))
    )

  // DATA_TABLE - SIZE_PER_PAGE
  container
    .bind(CORE_SERVICE_DATA_TABLE_SIZE_PER_PAGE)
    .toConstantValue(CoreServiceDataTableSizePerPage)

  // STATE_MANAGEMENT - SELECTORS - DATA_TABLE
  // STATE_MANAGEMENT - SELECTORS - DATA_TABLE - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_DATA_TABLE_BASE)
    .toDynamicValue(() => useCoreServiceStateManagementSelectorDataTableBase())

  // STATE_MANAGEMENT - INSTANCES - DATA_TABLE
  // STATE_MANAGEMENT - INSTANCES - DATA_TABLE - BASE
  container
    .bind(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_DATA_TABLE_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceStateManagementInstanceDataTableBase(
        c.get(CORE_SERVICE_DATA_TABLE_FILTER_DATE)
      )
    )

  // THIRD_PARTY_INTEGRATOR - REDUX - STORE - DATA_TABLE
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_DATA_TABLE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceThirdPartyIntegratorReduxStoreDataTable(
        c.get<ICoreServiceStateManagementInstanceDataTableBase>(
          CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_DATA_TABLE_BASE
        )
      )
    )

  // LOOKUP
  // LOOKUP - BASE
  container
    .bind(CORE_SERVICE_LOOKUP_BASE)
    .toDynamicValue(
      ({ container: c }) =>
        new CoreServiceLookupBase(c.get(CORE_SERVICE_LOCALIZATION_BASE))
    )
    .inSingletonScope()

  // TOAST
  // TOAST - BASE
  container
    .bind(CORE_SERVICE_TOAST_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceToastBase(c.get(CORE_SERVICE_LOCALIZATION_BASE))
    )

  // TOAST - UPDATE_AVAILABLE (DEFINED INSIDE MAIN THREAD INITIALIZER SERVICES)

  // EXCEPTION
  // EXCEPTION - NOT_AUTHORIZED
  container
    .bind(CORE_SERVICE_EXCEPTION_NOT_AUTHORIZED)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceExceptionNotAuthorized(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE),
        c.get(CORE_SERVICE_PRIVILEGE_BASE),
        c.get(CORE_SERVICE_LOOKUP_BASE)
      )
    )

  // EXCEPTION
  // EXCEPTION - NETWORK_ERROR
  container
    .bind(CORE_SERVICE_EXCEPTION_NETWORK_ERROR)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceExceptionNetworkError(
        c.get(CORE_SERVICE_EXCEPTION_NOT_AUTHORIZED),
        c.get(CORE_SERVICE_LOCALIZATION_BASE),
        c.get(CORE_SERVICE_TOAST_BASE)
      )
    )

  // SW_REGISTRATION (DEFINED INSIDE INITIALIZER INSTEAD)

  // THIRD_PARTY_INTEGRATOR - AXIOS - BASE
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_AXIOS_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceThirdPartyIntegratorAxiosBase(
        c.get<ICoreServiceAppConfigurationBase>(
          CORE_SERVICE_APP_CONFIGURATION_BASE
        ),
        c.get(CORE_SERVICE_EXCEPTION_NETWORK_ERROR)
      )
    )
    .inSingletonScope()

  // REMOTE
  // REMOTE - BASE
  container
    .bind(CORE_SERVICE_REMOTE_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreServiceRemoteBase(
        c.get<AxiosInstance>(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_AXIOS_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
      )
    )

  return container
}
