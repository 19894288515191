import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  CORE_ROUTER_BASE,
  CORE_ROUTER_PRIVATE_ADMIN_BASE,
  CORE_ROUTER_PRIVATE_VENDOR_BASE,
  CORE_ROUTER_PUBLIC_BASE,
  useDemoRouterComponent,
  useDemoRouterPrivateAdminBase,
  useDemoRouterPrivateVendorBase,
  useDemoRouterPublicDocument,
} from '@/routers'

export function getRoutersMainThreadIntoContainer(
  container = defaultContainer
) {
  container
    .bind(CORE_ROUTER_PRIVATE_ADMIN_BASE)
    .toDynamicValue(() => useDemoRouterPrivateAdminBase())

  container
    .bind(CORE_ROUTER_PRIVATE_VENDOR_BASE)
    .toDynamicValue(() => useDemoRouterPrivateVendorBase())

  container
    .bind(CORE_ROUTER_PUBLIC_BASE)
    .toDynamicValue(() => useDemoRouterPublicDocument())

  container
    .bind(CORE_ROUTER_BASE)
    .toDynamicValue(() => useDemoRouterComponent())
  return container
}
