import { container as defaultContainer } from 'inversify-hooks-esm'

import { CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE } from '@/resources'

import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_CREATE,
  useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementCreate,
} from '@/controllers/admin/organizational-structure/purchasing-organization/modal-management/create.hooks'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_UPDATE,
  useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate,
} from '@/controllers/admin/organizational-structure/purchasing-organization/modal-management/update.hooks'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_GET_RESOURCE_DATA_BY_ID,
  useOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById,
} from '@/controllers/admin/organizational-structure/purchasing-organization/get-resource-data/by-id.hooks'
/**
 * @description playground buat DI terkait purchasing organization. referensi: . referensi: {@link getViewAdminOrganizationalStructurePurchasingOrganizationListsContainer}
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */
export function getViewAdminOrganizationalStructurePurchasingOrganizationListsContainer(
  container = defaultContainer
) {
  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_CREATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementCreate()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_UPDATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_GET_RESOURCE_DATA_BY_ID
    )
    .toDynamicValue(({ container: c }) =>
      useOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById(
        c.get(
          CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE
        )
      )
    )
  return container
}
