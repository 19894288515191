import { useContainerGet } from 'inversify-hooks-esm'
import { FormikHelpers } from 'formik'

import {
  CORE_RESOURCE_SPREADSHEET_BASE,
  CORE_SERVICE_TOAST_BASE,
  DefaultSpreadsheetResponseBodyImport,
  ICoreControllerSharedPageFormManagementHandleSubmit,
  ICoreResourceSpreadsheetOnlyCommit,
  ICoreServiceToastBase,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import { NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED } from '@procuraid-frontend/localization'

import { DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput } from '../types'

export function useCoreControllerSharedSpreadsheetManagementFormManagementCommitHandleSubmit<
  SpreadsheetFileType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  InputType extends
    DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<SpreadsheetFileType> = DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<SpreadsheetFileType>,
>(): ICoreControllerSharedPageFormManagementHandleSubmit<InputType> {
  const spreadsheetResource = useContainerGet<
    ICoreResourceSpreadsheetOnlyCommit<SpreadsheetFileType>
  >(CORE_RESOURCE_SPREADSHEET_BASE)
  const toastService = useContainerGet<ICoreServiceToastBase>(
    CORE_SERVICE_TOAST_BASE
  )

  const translate = useCoreControllerSharedLocalizationTranslate()

  const handleSubmit = async (
    data: InputType,
    { setSubmitting }: FormikHelpers<InputType>
  ) => {
    setSubmitting(true)
    const requestBody = data.spreadsheetData!
    try {
      await spreadsheetResource.commit({
        id: requestBody.id,
        sprdsheet_type_id: requestBody.sprdsheet_type.ident_name,
      })
      toastService.showSuccessToast({
        subtitle: translate(NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED, {
          resource: 'Data',
        }),
      })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    handleSubmit,
  }
}
