import { container as defaultContainer } from 'inversify-hooks-esm'

import { CORE_RESOURCE_GENERAL_CONFIGURATION_BANK_BASE } from '@procuraid-frontend/core'
import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_UPDATE,
  useGeneralConfigurationControllerAdminBankGetResourceDataById,
  // useGeneralConfigurationControllerAdminBankModalManagementCreate,
  useGeneralConfigurationControllerAdminBankModalManagementUpdate,
} from '@procuraid-frontend/general-configuration'

import { useGeneralConfigurationControllerAdminBankModalManagementCreate } from '@/controllers/admin/general-configuration'

export function getViewAdminGeneralConfigurationBankListsContainer(
  container = defaultContainer
) {
  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_CREATE)
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminBankModalManagementCreate()
    )

  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_UPDATE)
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminBankModalManagementUpdate()
    )

  container
    .bind(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_GET_RESOURCE_DATA_BY_ID)
    .toDynamicValue(({ container: c }) =>
      useGeneralConfigurationControllerAdminBankGetResourceDataById(
        c.get(CORE_RESOURCE_GENERAL_CONFIGURATION_BANK_BASE)
      )
    )
  return container
}
