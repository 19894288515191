import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_RESOURCE_SPREADSHEET_BASE,
  DefaultSpreadsheetResponseBodyImport,
  DefaultSpreadsheetResponseBodyImportOriginal,
  ICoreResourceSpreadsheetOnlyUpdate,
  ICoreResourceSpreadsheetOnlyValidate,
} from '@procuraid-frontend/core'

import { useCoreControllerSharedSpreadsheetManagementTransformRequestBodyValidate } from '../transform-request-body'
import { useCoreControllerSharedSpreadsheetManagementTransformResponseBodyImport } from '../transform-response-body'

import {
  DefaultCoreControllerSharedSpreadsheetManagementUpdateResourceValidateInput,
  ICoreControllerSharedSpreadsheetManagementUpdateResourceRevalidate,
} from './types'

export function useCoreControllerSharedSpreadsheetManagementUpdateResourceRevalidate<
  InitialDataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  InputType extends
    DefaultCoreControllerSharedSpreadsheetManagementUpdateResourceValidateInput<InitialDataType> = DefaultCoreControllerSharedSpreadsheetManagementUpdateResourceValidateInput<InitialDataType>,
  DataType extends
    DefaultSpreadsheetResponseBodyImportOriginal = DefaultSpreadsheetResponseBodyImportOriginal,
>(): ICoreControllerSharedSpreadsheetManagementUpdateResourceRevalidate<
  InitialDataType,
  InputType
> {
  const spreadsheetResource = useContainerGet<
    ICoreResourceSpreadsheetOnlyValidate<DataType> &
      ICoreResourceSpreadsheetOnlyUpdate<DataType>
  >(CORE_RESOURCE_SPREADSHEET_BASE)

  const { transform: transformRequestBody } =
    useCoreControllerSharedSpreadsheetManagementTransformRequestBodyValidate<
      InitialDataType,
      DataType
    >()
  const { transform: transformResponseBody } =
    useCoreControllerSharedSpreadsheetManagementTransformResponseBodyImport<
      DataType,
      InitialDataType
    >()

  const updateResource = async ({
    data: initialData,
    additionalValidateParams = {},
  }: InputType) => {
    const data = transformRequestBody(initialData)
    const additionalPathname = {
      id: data.id,
      sprdsheet_type_id: data.sprdsheet_type.ident_name,
    }
    await spreadsheetResource.update(additionalPathname, data)
    const response = await spreadsheetResource.validate(additionalPathname, {
      params: additionalValidateParams,
    })
    return transformResponseBody(response.data.data)
  }

  return {
    updateResource,
  }
}
