import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_CONTROLLER_SHARED_SPREADSHEET_MANAGEMENT_GET_RESOURCE_DATA_FROM_FILE,
  ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFile,
  ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFilePartial,
} from '@/controllers'

export function useOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataFromSpreadsheetFile<
  SpreadsheetCtrl extends
    ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFile = ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFile,
  OutputType extends
    ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFilePartial = ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFilePartial,
>(): OutputType {
  const spreadsheetManagementCtrl = useContainerGet<SpreadsheetCtrl>(
    CORE_CONTROLLER_SHARED_SPREADSHEET_MANAGEMENT_GET_RESOURCE_DATA_FROM_FILE
  )

  const loading = spreadsheetManagementCtrl.loading

  const getData = async (
    options: Partial<Parameters<OutputType['getData']>[0]>
  ) =>
    await spreadsheetManagementCtrl.getData({
      ...options,
      sprdsheet_type_id: 'PURCHASING_GROUP',
    })

  return {
    getData,
    loading,
  } as OutputType
}
