import {
  DefaultNavigationTreeResponseBodyGet,
  DefaultNavIconState,
  ICoreServiceNavigationItemPrivate,
} from '@procuraid-frontend/core'

import iconMenuListHome from '@/assets/svg/icon-menu-list-home.svg'
import iconMenuListHomeActive from '@/assets/svg/icon-menu-list-home-active.svg'

import iconMenuListVendorManagement from '@/assets/svg/icon-menu-list-vendor-management.svg'
import iconMenuListVendorManagementActive from '@/assets/svg/icon-menu-list-vendor-management-active.svg'

import iconMenuListPurchaseRequisition from '@/assets/svg/icon-menu-list-purchase-requisition.svg'
import iconMenuListPurchaseRequisitionActive from '@/assets/svg/icon-menu-list-purchase-requisition-active.svg'

import iconMenuListAuction from '@/assets/svg/icon-menu-list-auction.svg'
import iconMenuListAuctionActive from '@/assets/svg/icon-menu-list-auction-active.svg'

import iconMenuListPurchaseOrder from '@/assets/svg/icon-menu-list-purchase-order.svg'
import iconMenuListPurchaseOrderActive from '@/assets/svg/icon-menu-list-purchase-order-active.svg'

import iconMenuListOther from '@/assets/svg/icon-menu-list-other.svg'
import iconMenuListOtherActive from '@/assets/svg/icon-menu-list-other-active.svg'

import iconProfile from '@/assets/svg/icon-profile.svg'
import iconProfileActive from '@/assets/svg/icon-profile-active.svg'

import iconMenuListBack from '@/assets/svg/icon-menu-list-back.svg'
export function getCoreServiceNavigationItemPrivate<
  NavigationItem extends
    DefaultNavigationTreeResponseBodyGet = DefaultNavigationTreeResponseBodyGet,
  IconState extends DefaultNavIconState = DefaultNavIconState,
>(): ICoreServiceNavigationItemPrivate<NavigationItem, IconState> {
  const limitFirstList = 7

  const navIcons = {
    home: {
      normal: iconMenuListHome,
      active: iconMenuListHomeActive,
    },
    vendor_management: {
      normal: iconMenuListVendorManagement,
      active: iconMenuListVendorManagementActive,
    },
    purchase_requisition: {
      normal: iconMenuListPurchaseRequisition,
      active: iconMenuListPurchaseRequisitionActive,
    },
    auctions: {
      normal: iconMenuListAuction,
      active: iconMenuListAuctionActive,
    },
    tender_proposal: {
      normal: iconMenuListOther,
      active: iconMenuListOtherActive,
    },
    purchase_orders: {
      normal: iconMenuListPurchaseOrder,
      active: iconMenuListPurchaseOrderActive,
    },
    contract: {
      normal: iconMenuListPurchaseRequisition,
      active: iconMenuListPurchaseRequisitionActive,
    },
    user_management: {
      normal: iconProfile,
      active: iconProfileActive,
    },
    catalog: {
      normal: iconMenuListPurchaseOrder,
      active: iconMenuListPurchaseOrderActive,
    },
    message_queue: {
      normal: iconMenuListOther,
      active: iconMenuListOtherActive,
    },
    product_request: {
      normal: iconMenuListOther,
      active: iconMenuListOtherActive,
    },
    transaction: {
      normal: iconMenuListPurchaseRequisition,
      active: iconMenuListPurchaseRequisitionActive,
    },
    cart: {
      normal: iconMenuListPurchaseOrder,
      active: iconMenuListPurchaseOrderActive,
    },
    announcement: {
      normal: iconMenuListOther,
      active: iconMenuListOtherActive,
    },
    back: {
      normal: iconMenuListBack,
      active: iconMenuListBack,
    },
    default: {
      normal: iconMenuListOther,
      active: iconMenuListOtherActive,
    },
  } as unknown as Record<string, IconState>

  const getWithChildrenClass = (childrenLength = 0) =>
    `with-${childrenLength}-children`

  return {
    limitFirstList,
    navIcons,
    getWithChildrenClass,
  }
}
