import { useFormikContext } from 'formik'
import clsx from 'clsx'

import {
  DefaultSpreadsheetResponseBodyImport,
  PropsWithInnerRef,
  addRefProps,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import { INFO_LOADING } from '@procuraid-frontend/localization'
import { CoreViewMoleculeLabelSpreadsheetPreviewErrors } from '@/views/core/02-molecules/labels'
import {
  useCoreControllerSharedSpreadsheetManagementCheckValidConditionHasError,
  useCoreControllerSharedSpreadsheetManagementGetCalculatedResourceErrorMessages,
} from '@/controllers'
import { CoreViewMoleculeLabelSpreadsheetPreviewEntry } from '@/views/core/02-molecules/labels/SpreadsheetPreviewEntry'
import { DefaultViewItemEditableType } from '@/views/core/02-molecules/lists/editable'

export interface CoreViewOrganismFormSpreadsheetPreviewBaseProps<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  InputTransformerOutput extends
    DefaultViewItemEditableType = DefaultViewItemEditableType,
> extends PropsWithInnerRef {
  /**
   * @default spreadsheetData
   */
  attributeForSpreadsheetData?: string

  /**
   * @default false
   */
  loading?: boolean

  textBeforeLoad?: string

  itemTransformer(input: {
    row: DataType['data']['rows'][number]
    index: number
  }): InputTransformerOutput[][]
}

const CoreViewOrganismFormSpreadsheetPreviewBase = <
  SpreadsheetDataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  InputTransformerOutput extends
    DefaultViewItemEditableType = DefaultViewItemEditableType,
  PropType extends CoreViewOrganismFormSpreadsheetPreviewBaseProps<
    SpreadsheetDataType,
    InputTransformerOutput
  > = CoreViewOrganismFormSpreadsheetPreviewBaseProps<
    SpreadsheetDataType,
    InputTransformerOutput
  >,
>({
  innerRef,
  attributeForSpreadsheetData = 'spreadsheetData',
  loading = false,
  textBeforeLoad = 'Data akan muncul di sini',
  itemTransformer,
}: PropType) => {
  const { values } = useFormikContext<{
    [key: string]: SpreadsheetDataType | null
  }>()

  const { checkValidCondition: checkSpreadsheetDataHasError } =
    useCoreControllerSharedSpreadsheetManagementCheckValidConditionHasError<SpreadsheetDataType>()

  const { getCalculatedResource: getErrorMessages } =
    useCoreControllerSharedSpreadsheetManagementGetCalculatedResourceErrorMessages<SpreadsheetDataType>()

  const translate = useCoreControllerSharedLocalizationTranslate()

  const spreadsheetData = values?.[attributeForSpreadsheetData] ?? null
  const spreadsheetErrorData = spreadsheetData?.data?.errors ?? null
  const hasError = checkSpreadsheetDataHasError({
    data: spreadsheetErrorData,
    returnTrueWhenEmpty: false,
  })
  const errorMessagesData = hasError
    ? getErrorMessages(spreadsheetErrorData)
    : null

  return (
    <div
      ref={innerRef}
      className={clsx(
        'd-flex',
        spreadsheetData !== null
          ? 'flex-column align-items-start justify-content-start'
          : 'align-items-center justify-content-center'
      )}
      style={{ minHeight: '20dvh' }}
    >
      {spreadsheetData === null ? (
        <strong>{loading ? translate(INFO_LOADING) : textBeforeLoad}</strong>
      ) : (
        <>
          {spreadsheetData.data.rows.map((row, index) => {
            const errorData = errorMessagesData?.[`${index}`]
              ? { [index]: errorMessagesData[`${index}`] }
              : null
            const transformedData = itemTransformer({ row, index })
            const key = `spreadsheet-data-${index}`
            return (
              <>
                <CoreViewMoleculeLabelSpreadsheetPreviewErrors
                  key={`${key}-errors`}
                  data={errorData}
                />
                <CoreViewMoleculeLabelSpreadsheetPreviewEntry
                  key={`${key}-entry`}
                  data={transformedData}
                  editMode={errorData !== null}
                />
              </>
            )
          })}
        </>
      )}
    </div>
  )
}

export const CoreViewOrganismFormSpreadsheetPreview = addRefProps(
  CoreViewOrganismFormSpreadsheetPreviewBase
)
