import {
  DefaultSpreadsheetResponseBodyImport,
  ICoreControllerSharedPageCheckValidConditionBase,
} from '@procuraid-frontend/core'

export interface ICoreControllerSharedSpreadsheetManagementCheckValidConditionHasErrorInputType<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
> {
  /**
   * @default null
   */
  data?: DataType['data']['errors'] | null

  /**
   * @default true
   */
  returnTrueWhenEmpty?: boolean
}

export function useCoreControllerSharedSpreadsheetManagementCheckValidConditionHasError<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  InputType extends
    ICoreControllerSharedSpreadsheetManagementCheckValidConditionHasErrorInputType<DataType> = ICoreControllerSharedSpreadsheetManagementCheckValidConditionHasErrorInputType<DataType>,
>(): ICoreControllerSharedPageCheckValidConditionBase<InputType> {
  const checkValidCondition = (input: InputType) => {
    const data = input?.data ?? null
    const returnTrueWhenEmpty = input?.returnTrueWhenEmpty ?? true

    if (data !== null) {
      return data.length > 0
    }
    return returnTrueWhenEmpty
  }

  return {
    checkValidCondition,
  }
}
