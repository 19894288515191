import { DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit } from '@/resources'
import {
  ICoreControllerSharedGetResourceOnlyGetData,
  ICoreResourceOrganizationalStructureOnlyEdit,
} from '@procuraid-frontend/core'

export type IOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
> = ICoreControllerSharedGetResourceOnlyGetData<
  Promise<DataType | null>,
  string
>

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_GET_RESOURCE_DATA_BY_ID =
  'OrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById'

export function useOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
>(
  masterDataPurchasingOrganizationResource: ICoreResourceOrganizationalStructureOnlyEdit<{
    data: DataType
  }>
): IOrganizationalStructureControllerAdminPurchasingOrganizationGetResourceDataById<DataType> {
  const getData = async (id: string) => {
    try {
      const response = await masterDataPurchasingOrganizationResource.edit(id)
      const data = response.data?.data ?? null
      return data
    } catch {
      return null
    }
  }

  return {
    getData,
  }
}
