import styled from 'styled-components'
import { Row, Col } from 'react-bootstrap'

import {
  PropsWithInnerRef,
  addRefProps,
  cardMixin,
} from '@procuraid-frontend/core'
import {
  CoreViewMoleculeListEditable,
  DefaultViewItemEditableType,
} from '../lists/editable'

export interface CoreViewMoleculeLabelSpreadsheetPreviewEntryBaseProps<
  DataType extends DefaultViewItemEditableType = DefaultViewItemEditableType,
> extends PropsWithInnerRef {
  /**
   * @default []
   */
  data?: DataType[][]

  /**
   * @default false
   */
  editMode?: boolean
}

const Card = styled.div`
  ${cardMixin()}
`

const CoreViewMoleculeLabelSpreadsheetPreviewEntryBase = <
  DataType extends DefaultViewItemEditableType = DefaultViewItemEditableType,
  PropType extends
    CoreViewMoleculeLabelSpreadsheetPreviewEntryBaseProps<DataType> = CoreViewMoleculeLabelSpreadsheetPreviewEntryBaseProps<DataType>,
>({
  innerRef,
  data = [],
  editMode = false,
}: PropType) => {
  return (
    <Card ref={innerRef} className="w-100">
      {data.map((items, index) => (
        <Row
          key={`spreadsheet-list-editable-${index}`}
          className="flex-column"
          style={{ flex: 1 }}
        >
          {items.map((item, index2) => (
            <Col key={`spreadsheet-list-editable-${index}-${index2}`}>
              <CoreViewMoleculeListEditable data={item} editMode={editMode} />
            </Col>
          ))}
        </Row>
      ))}
    </Card>
  )
}

export const CoreViewMoleculeLabelSpreadsheetPreviewEntry = addRefProps(
  CoreViewMoleculeLabelSpreadsheetPreviewEntryBase
)
