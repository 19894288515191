/**
 * @description playground buat view terkait purchasing group
 * referensi:
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */

import {
  IOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById,
  IOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_GET_RESOURCE_DATA_BY_ID,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_UPDATE,
  useOrganizationalStructureControllerAdminPurchasingGroupDataTableManagementGetComponentPropsBase,
} from '@/controllers'
import { DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit } from '@/resources'
import {
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'

export interface OrganizationalStructureViewPageAdminPurchasingGroupListsBaseProps
  extends PropsWithInnerRef {}

const OrganizationalStructureViewPageAdminPurchasingGroupListsBase = <
  PropType extends
    OrganizationalStructureViewPageAdminPurchasingGroupListsBaseProps = OrganizationalStructureViewPageAdminPurchasingGroupListsBaseProps,
  PurchasingGroupByIdType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getPurchasingGroupByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById<PurchasingGroupByIdType>
  >(
    ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_GET_RESOURCE_DATA_BY_ID
  )
  const modalUpdatePurchasingGroupByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate<PurchasingGroupByIdType>
  >(
    ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_UPDATE
  )
  const modalCreateSinglePurchasingGroupCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE
    )
  const modalCreatePurchasingGroupListsFromSpreadsheetFileCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )
  const { getComponentProps, refreshTableData } =
    useOrganizationalStructureControllerAdminPurchasingGroupDataTableManagementGetComponentPropsBase<PurchasingGroupByIdType>(
      getPurchasingGroupByIdCtrl,
      modalUpdatePurchasingGroupByIdCtrl,
      modalCreateSinglePurchasingGroupCtrl,
      modalCreatePurchasingGroupListsFromSpreadsheetFileCtrl
    )
  const handleHide = () => refreshTableData?.()
  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSinglePurchasingGroupCtrl.ModalComponent
        onHide={handleHide}
      />
      <modalUpdatePurchasingGroupByIdCtrl.ModalComponent onHide={handleHide} />
      <modalCreatePurchasingGroupListsFromSpreadsheetFileCtrl.ModalComponent
        onHide={handleHide}
      />
    </>
  )
}

export const OrganizationalStructureViewPageAdminPurchasingGroupLists =
  addRefProps(OrganizationalStructureViewPageAdminPurchasingGroupListsBase)
