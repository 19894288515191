import { DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit } from '@/resources'
import { OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdate } from '@/views'

import {
  ICoreControllerSharedPageModalManagementWithData,
  useCoreControllerSharedModalManagementWithData,
} from '@procuraid-frontend/core'

export type IOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
> = ICoreControllerSharedPageModalManagementWithData<DataType>

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_UPDATE =
  'OrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate'

export function useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
>(): IOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementUpdate<DataType> {
  return useCoreControllerSharedModalManagementWithData<DataType>({
    component:
      OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdate,
  })
}
