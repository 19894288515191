import { DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit } from '@/resources'
import {
  ICoreControllerSharedGetResourceOnlyGetData,
  ICoreResourceOrganizationalStructureOnlyEdit,
} from '@procuraid-frontend/core'

export type IOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
> = ICoreControllerSharedGetResourceOnlyGetData<
  Promise<DataType | null>,
  string
>

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_GET_RESOURCE_DATA_BY_ID =
  'OrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById'

export function useOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
>(
  masterDataPurchasingGroupResource: ICoreResourceOrganizationalStructureOnlyEdit<{
    data: DataType
  }>
): IOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById<DataType> {
  const getData = async (id: string) => {
    try {
      const response = await masterDataPurchasingGroupResource.edit(id)
      const data = response.data?.data ?? null
      return data
    } catch {
      return null
    }
  }

  return {
    getData,
  }
}
