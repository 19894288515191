import { useCoreControllerSharedModalManagementBase } from '@procuraid-frontend/core'

import { ViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFile } from '@/views'

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE =
  'OrganizationalStructureControllerAdminPlantModalManagementCreateFromSpreadsheetFile'

export function useOrganizationalStructureControllerAdminPlantModalManagementCreateFromSpreadsheetFile() {
  return useCoreControllerSharedModalManagementBase({
    component:
      ViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFile,
  })
}
