import { OrganizationalStructureViewOrganismModalPurchasingGroupCreate } from '@/views'

import {
  ICoreControllerSharedPageModalManagementBase,
  useCoreControllerSharedModalManagementBase,
} from '@procuraid-frontend/core'

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE =
  'OrganizationalStructureControllerAdminPurchasingGroupModalManagementCreate'

export function useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreate(): ICoreControllerSharedPageModalManagementBase {
  return useCoreControllerSharedModalManagementBase({
    component:
      OrganizationalStructureViewOrganismModalPurchasingGroupCreate,
  })
}
