import { useContainerGet } from 'inversify-hooks-esm'

import {
  DefaultGeneralConfigurationBankResponseBodyEdit,
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'
import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_UPDATE,
  IGeneralConfigurationControllerAdminBankGetResourceDataById,
  IGeneralConfigurationControllerAdminBankModalManagementUpdate,
  useGeneralConfigurationControllerAdminBankDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/general-configuration'

export interface GeneralConfigurationViewPageAdminBankListsBaseProps
  extends PropsWithInnerRef {}

const GeneralConfigurationViewPageAdminBankListsBase = <
  PropType extends
    GeneralConfigurationViewPageAdminBankListsBaseProps = GeneralConfigurationViewPageAdminBankListsBaseProps,
  BankByIdType extends
    DefaultGeneralConfigurationBankResponseBodyEdit = DefaultGeneralConfigurationBankResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getBankByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminBankGetResourceDataById<BankByIdType>
  >(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_GET_RESOURCE_DATA_BY_ID)
  const modalUpdateBankByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminBankModalManagementUpdate<BankByIdType>
  >(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_UPDATE)
  const modalCreateSingleBankCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_CREATE
    )
  const { getComponentProps, refreshTableData } =
    useGeneralConfigurationControllerAdminBankDataTableManagementGetComponentPropsBase<BankByIdType>(
      getBankByIdCtrl,
      modalUpdateBankByIdCtrl,
      modalCreateSingleBankCtrl
    )
  const handleHide = () => refreshTableData?.()
  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSingleBankCtrl.ModalComponent onHide={handleHide} />
      <modalUpdateBankByIdCtrl.ModalComponent onHide={handleHide} />
    </>
  )
}

export const GeneralConfigurationViewPageAdminBankLists = addRefProps(
  GeneralConfigurationViewPageAdminBankListsBase
)
