import { ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsLanguage } from '@procuraid-frontend/core'

import iconLanguageEnCircledPNG1x from '@/assets/images/icon-language-en-circled.png'
import iconLanguageEnCircledPNG2x from '@/assets/images/icon-language-en-circled@2x.png'
import iconLanguageEnCircledPNG3x from '@/assets/images/icon-language-en-circled@3x.png'
import iconLanguageEnCircledWEBP1x from '@/assets/images/icon-language-en-circled.webp'
import iconLanguageEnCircledWEBP2x from '@/assets/images/icon-language-en-circled@2x.webp'
import iconLanguageEnCircledWEBP3x from '@/assets/images/icon-language-en-circled@3x.webp'

import iconLanguageIdCircledPNG1x from '@/assets/images/icon-language-id-circled.png'
import iconLanguageIdCircledPNG2x from '@/assets/images/icon-language-id-circled@2x.png'
import iconLanguageIdCircledPNG3x from '@/assets/images/icon-language-id-circled@3x.png'
import iconLanguageIdCircledWEBP1x from '@/assets/images/icon-language-id-circled.webp'
import iconLanguageIdCircledWEBP2x from '@/assets/images/icon-language-id-circled@2x.webp'
import iconLanguageIdCircledWEBP3x from '@/assets/images/icon-language-id-circled@3x.webp'

export const CoreServiceThirdPartyIntegratorReactContextCustomerAssetsLanguage: ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsLanguage =
  {
    id: {
      webp: {
        '1x': iconLanguageIdCircledWEBP1x,
        '2x': iconLanguageIdCircledWEBP2x,
        '3x': iconLanguageIdCircledWEBP3x,
      },
      png: {
        '1x': iconLanguageIdCircledPNG1x,
        '2x': iconLanguageIdCircledPNG2x,
        '3x': iconLanguageIdCircledPNG3x,
      },
    },
    en: {
      webp: {
        '1x': iconLanguageEnCircledWEBP1x,
        '2x': iconLanguageEnCircledWEBP2x,
        '3x': iconLanguageEnCircledWEBP3x,
      },
      png: {
        '1x': iconLanguageEnCircledPNG1x,
        '2x': iconLanguageEnCircledPNG2x,
        '3x': iconLanguageEnCircledPNG3x,
      },
    },
  }
