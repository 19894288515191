import { useCallback } from 'react'
import { object, string } from 'yup'

import { ICoreControllerSharedPageFormManagementValidatorBase } from '@procuraid-frontend/core'

export function useControllerAdminOrganizationStructureScopeOfSuppliesFormManagementValidator(): ICoreControllerSharedPageFormManagementValidatorBase {
  const validator = useCallback(() => {
    return object({
      scope_of_supply_type: string().required().defined(),
      code: string().required().defined(),
      name: string().required().defined(),
    })
      .required()
      .defined()
  }, [])

  return {
    validator,
  }
}
