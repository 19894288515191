import { OrganizationalStructureViewOrganismModalPurchasingOrganizationCreate } from '@/views'

import {
  ICoreControllerSharedPageModalManagementBase,
  useCoreControllerSharedModalManagementBase,
} from '@procuraid-frontend/core'

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_ORGANIZATION_MODAL_MANAGEMENT_CREATE =
  'OrganizationalStructureControllerAdminPurchasingOrganizationModalManagementCreate'

export function useOrganizationalStructureControllerAdminPurchasingOrganizationModalManagementCreate(): ICoreControllerSharedPageModalManagementBase {
  return useCoreControllerSharedModalManagementBase({
    component:
      OrganizationalStructureViewOrganismModalPurchasingOrganizationCreate,
  })
}
