import styled from 'styled-components'

import { Formik, Form, FormikHelpers } from 'formik'
import {
  ANTDatePicker,
  ButtonFormSubmit,
  ButtonOutline,
  InputFormControl,
  LabelForm,
  Modal,
  ModalBasePropsWithData,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import {
  useOrganizationalStructureControllerAdminPurchasingOrganizationFormManagementCreateOrUpdateHandleSubmit,
  useOrganizationalStructureControllerAdminPurchasingOrganizationFormManagementCreateOrUpdateInitialValue,
} from '@/controllers'

import {
  ACTION_CANCEL,
  ACTION_EDIT_RESOURCE,
  ACTION_SUBMIT,
  LABEL_CODE,
  LABEL_DESCRIPTION,
  LABEL_FROM_DATE,
  LABEL_NAME,
  LABEL_PURCH_ORG,
  LABEL_THRU_DATE,
} from '@procuraid-frontend/localization'
import {
  DefaultOrganizationalStructurePurchasingOrganizationRequestBodyCreate,
  DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
} from '@/resources'

export interface OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdateBaseProps<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
> extends ModalBasePropsWithData<DataType> {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 24vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        justify-content: flex-end;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdateBase =
  <
    DataType extends
      DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyEdit,
    PropType extends
      OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdateBaseProps<DataType> = OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdateBaseProps<DataType>,
    PayloadType extends
      DefaultOrganizationalStructurePurchasingOrganizationRequestBodyCreate = DefaultOrganizationalStructurePurchasingOrganizationRequestBodyCreate,
  >({
    innerRef,
    onHide,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    data = null,
    ...otherProps
  }: PropType) => {
    const { handleSubmit: handleUpdatePurchOrg } =
      useOrganizationalStructureControllerAdminPurchasingOrganizationFormManagementCreateOrUpdateHandleSubmit<PayloadType>()

    const { initialValue: getInitialValue } =
      useOrganizationalStructureControllerAdminPurchasingOrganizationFormManagementCreateOrUpdateInitialValue<
        PayloadType,
        DataType
      >()

    const translate = useCoreControllerSharedLocalizationTranslate()

    const handleSubmit = async (
      payload: PayloadType,
      helpers: FormikHelpers<PayloadType>
    ) => {
      try {
        await handleUpdatePurchOrg(payload, helpers)
      } finally {
        onHide?.()
      }
    }

    return (
      <Formik
        enableReinitialize
        initialValues={getInitialValue({ data, mode: 'UPDATE' })}
        onSubmit={handleSubmit}
      >
        <Form>
          <ModalComponent
            ref={innerRef}
            centered
            onHide={onHide}
            {...otherProps}
          >
            <ModalHeader closeButton={false} onHide={onHide}>
              <ModalTitle>
                {translate(ACTION_EDIT_RESOURCE, {
                  resource: translate(LABEL_PURCH_ORG),
                })}
              </ModalTitle>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
                <LabelForm
                  label={translate(LABEL_NAME)}
                  htmlFor="party.full_name"
                  className="mb-0"
                />
                <InputFormControl
                  id="party.full_name"
                  name="party.full_name"
                  disabled
                />
                <LabelForm
                  label={translate(LABEL_CODE)}
                  htmlFor="code"
                  className="mb-0"
                />
                <InputFormControl id="code" name="code" />
                <LabelForm
                  label={translate(LABEL_DESCRIPTION)}
                  htmlFor="desc"
                  className="mb-0"
                />
                <InputFormControl id="desc" name="desc" />
                {/** @todo add description when backend is ready */}
                {/* <LabelForm label={translate(LABEL_DESCRIPTION)} htmlFor="description" required={false} />
              <InputFormControl id="description" name="description" /> */}
                <LabelForm
                  label={translate(LABEL_FROM_DATE)}
                  htmlFor="from_date"
                  className="mb-0"
                />
                <ANTDatePicker
                  id="from_date"
                  name="from_date"
                  showTime={false}
                  formatValue="PPP"
                  showErrorValidation={true}
                />
                <LabelForm
                  label={translate(LABEL_THRU_DATE)}
                  htmlFor="thru_date"
                  className="mb-0"
                  required={false}
                />
                <ANTDatePicker
                  id="thru_date"
                  name="thru_date"
                  showTime={false}
                  formatValue="PPP"
                />
                {/** @todo add purchasing organization when backend is ready */}
              </div>
            </ModalBody>
            <ModalFooter>
              <ButtonOutline
                onClick={onHide}
                className="btn-actions"
                title={translate(ACTION_CANCEL)}
              />
              <ButtonFormSubmit compact size="sm">
                {translate(ACTION_SUBMIT)}
              </ButtonFormSubmit>
            </ModalFooter>
          </ModalComponent>
        </Form>
      </Formik>
    )
  }

export const OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdate =
  addRefProps(
    OrganizationalStructureViewOrganismModalPurchasingOrganizationUpdateBase
  )
