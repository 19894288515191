/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultDropdownOptionsType } from '@procuraid-frontend/core'
import { useCallback, useState } from 'react'

export function useControllerAdminOrganizationStructureBankDropdownManagementDefaultValueCreateOrUpdate<
  DropdownOptionsType extends
    DefaultDropdownOptionsType = DefaultDropdownOptionsType,
>() {
  const [defaultValue, setDefaultValue] = useState<
    Record<
      'country' | 'district',
      ((items: any) => DropdownOptionsType[]) | null
    >
  >({
    country: (items) => {
      console.log('country items: ', items)
      return items
    },
    district: (items) => {
      console.log('district items: ', items)
      return items
    },
  })

  const getDefaultValue = useCallback(() => {}, [])

  return {
    getDefaultValue,
    defaultValue,
  }
}
