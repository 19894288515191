import type { FormikHelpers } from 'formik'
import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_SERVICE_TOAST_BASE,
  ICoreControllerSharedPageFormManagementHandleSubmit,
  ICoreResourceOrganizationalStructureBase,
  ICoreServiceToastBase,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import {
  LABEL_REGION_TYPE,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED,
} from '@procuraid-frontend/localization'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_REGION_BASE,
  DefaultOrganizationalStructureRegionRequestBodyCreate,
} from '@/resources'

export function useOrganizationalStructureControllerAdminRegionFormManagementCreateOrUpdateHandleSubmit<
  PayloadType extends
    DefaultOrganizationalStructureRegionRequestBodyCreate = DefaultOrganizationalStructureRegionRequestBodyCreate,
>(): ICoreControllerSharedPageFormManagementHandleSubmit<PayloadType> {
  const regionResource =
    useContainerGet<ICoreResourceOrganizationalStructureBase>(
      CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_REGION_BASE
    )
  const toastService = useContainerGet<ICoreServiceToastBase>(
    CORE_SERVICE_TOAST_BASE
  )

  const translate = useCoreControllerSharedLocalizationTranslate()

  const handleSubmit = async (
    data: PayloadType,
    { setSubmitting }: FormikHelpers<PayloadType>
  ) => {
    setSubmitting(true)
    try {
      if (data?.id) {
        await regionResource.update(data.id, { data: data })
        toastService.showSuccessToast({
          subtitle: translate(NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED, {
            resource: translate(LABEL_REGION_TYPE),
          }),
        })
      } else {
        await regionResource.save({ data: data })
        toastService.showSuccessToast({
          subtitle: translate(NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED, {
            resource: translate(LABEL_REGION_TYPE),
          }),
        })
      }
    } finally {
      setSubmitting(false)
    }
  }

  return {
    handleSubmit,
  }
}
