import { StrictMode } from 'react'
import { ContainerProvider } from 'inversify-hooks-esm'

import { ComponentProviderChildLevel1 } from './ChildLevel1'

import { componentContainer } from '@/di/main-thread/component'

export const ComponentProvider = () => (
  <StrictMode>
    <ContainerProvider value={componentContainer}>
      <ComponentProviderChildLevel1 />
    </ContainerProvider>
  </StrictMode>
)
