import { container as defaultContainer } from 'inversify-hooks-esm'
import {
  CORE_SERVICE_APP_CONFIGURATION_BASE,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_LOCATION,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_NAVIGATOR,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW,
  CORE_SERVICE_LOCALIZATION_INITIALIZER,
  CORE_SERVICE_SW_REGISTRATION_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_INITIALIZER,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_INITIALIZER,
  CORE_SERVICE_TOAST_UPDATE_AVAILABLE,
  CORE_SERVICE_USER_BROWSER_BASE,
  CORE_SERVICE_USER_BROWSER_FIREFOX,
  CoreServiceThirdPartyIntegratorI18nextResourceInitializer,
  getCoreServiceLocalizationInitializer,
  getCoreServiceSWRegistrationBase,
  getCoreServiceThirdPartyIntegratorI18nextInitializer,
  getCoreServiceToastUpdateAvailable,
  getCoreServiceUserBrowserFirefox,
} from '@procuraid-frontend/core'

export function getMainThreadInitializerServicesContainer(
  container = defaultContainer
) {
  // THIRD_PARTY_INTEGRATOR - I18NEXT
  // THIRD_PARTY_INTEGRATOR - I18NEXT - RESOURCE
  // THIRD_PARTY_INTEGRATOR - I18NEXT - RESOURCE - INITIALIZER
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_INITIALIZER)
    .toConstantValue(CoreServiceThirdPartyIntegratorI18nextResourceInitializer)

  // THIRD_PARTY_INTEGRATOR - I18NEXT - INITIALIZER
  container
    .bind(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_INITIALIZER)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceThirdPartyIntegratorI18nextInitializer(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_RESOURCE_INITIALIZER),
        c.get(CORE_SERVICE_APP_CONFIGURATION_BASE)
      )
    )
    .inSingletonScope()

  // LOCALIZATION
  // LOCALIZATION - INITIALIZER
  container
    .bind(CORE_SERVICE_LOCALIZATION_INITIALIZER)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceLocalizationInitializer(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_I18NEXT_INITIALIZER)
      )
    )
    .inSingletonScope()

  // TOAST - UPDATE_AVAILABLE
  container
    .bind(CORE_SERVICE_TOAST_UPDATE_AVAILABLE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceToastUpdateAvailable(
        c.get(CORE_SERVICE_LOCALIZATION_INITIALIZER)
      )
    )

  // USER_BROWSER - FIREFOX
  container
    .bind(CORE_SERVICE_USER_BROWSER_FIREFOX)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceUserBrowserFirefox(c.get(CORE_SERVICE_USER_BROWSER_BASE))
    )
    .inSingletonScope()

  // SW_REGISTRATION
  container
    .bind(CORE_SERVICE_SW_REGISTRATION_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreServiceSWRegistrationBase(
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_NAVIGATOR),
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW),
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_LOCATION),
        c.get(CORE_SERVICE_TOAST_UPDATE_AVAILABLE),
        c.get(CORE_SERVICE_USER_BROWSER_FIREFOX),
        import.meta.env.VITE_APP_ENV
      )
    )
  return container
}
