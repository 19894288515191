import { useFormikContext } from 'formik'
import {
  ForwardRefExoticComponent,
  RefAttributes,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'

import {
  DefaultSpreadsheetResponseBodyImport,
  PropsWithInnerRef,
  addRefProps,
} from '@procuraid-frontend/core'

import {
  CoreViewMoleculeInputFileSpreadsheet,
  CoreViewMoleculeInputFileSpreadsheetBaseProps,
} from './Spreadsheet'

export interface CoreViewMoleculeInputFileSpreadsheetWatcherBaseProps<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  RefType extends
    CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef = CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef,
> extends Omit<CoreViewMoleculeInputFileSpreadsheetBaseProps, 'innerRef'>,
    PropsWithInnerRef<HTMLDivElement & RefType> {
  /**
   * @default spreadsheetData
   */
  attributeForSpreadsheetData?: string

  /**
   * @default []
   */
  fallbackAttachmentDataValue?: File[]

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAttachmentChanged(files: File[]): Promise<DataType>

  /**
   * @default null
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onRevalidate?: null | ((data: DataType) => Promise<DataType>)
}

export interface CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef {
  revalidate(): void
  loading: boolean
}

const CoreViewMoleculeInputFileSpreadsheetWatcherBase = <
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  RefType extends
    CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef = CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef,
  PropType extends
    CoreViewMoleculeInputFileSpreadsheetWatcherBaseProps<DataType> = CoreViewMoleculeInputFileSpreadsheetWatcherBaseProps<DataType>,
>({
  innerRef,
  attributeForSpreadsheetData = 'spreadsheetData',
  fallbackAttachmentDataValue = [],
  onAttachmentChanged,
  name,
  onRevalidate = null,
  attributeNameForAttachmentData = 'attachmentData',
  ...otherProps
}: PropType) => {
  const [
    triggerUpdateSpreadsheetDataValue,
    setTriggerUpdateSpreadsheetDataValue,
  ] = useState(false)
  const [isRevalidating, setRevalidating] = useState(false)
  const { values, setFieldValue } = useFormikContext<Record<string, unknown>>()

  const attachmentDataValue = (values?.[name] ??
    fallbackAttachmentDataValue) as File[]
  const spreadsheetDataValue =
    (values?.[attributeForSpreadsheetData] as DataType) ?? null
  const loading = triggerUpdateSpreadsheetDataValue || isRevalidating

  const handleUpdateSpreadsheetDataValue = useCallback(async () => {
    try {
      const spreadsheetData = await onAttachmentChanged(attachmentDataValue)
      setFieldValue(attributeForSpreadsheetData, spreadsheetData)
    } finally {
      setTriggerUpdateSpreadsheetDataValue(false)
    }
  }, [
    attachmentDataValue,
    attributeForSpreadsheetData,
    onAttachmentChanged,
    setFieldValue,
  ])
  const handleRevalidating = useCallback(async () => {
    try {
      if (onRevalidate !== null) {
        const spreadsheetData = await onRevalidate(spreadsheetDataValue)
        setFieldValue(attributeForSpreadsheetData, spreadsheetData)
      }
    } finally {
      setRevalidating(false)
    }
  }, [
    attributeForSpreadsheetData,
    onRevalidate,
    setFieldValue,
    spreadsheetDataValue,
  ])
  const handleDropAccepted = (files: File[]) => {
    setFieldValue(name, files)
  }
  const revalidate = () => {
    setRevalidating(true)
  }

  useEffect(() => {
    if (attachmentDataValue.length > 0) {
      setTriggerUpdateSpreadsheetDataValue(true)
    }
  }, [attachmentDataValue])

  useEffect(() => {
    if (triggerUpdateSpreadsheetDataValue) {
      handleUpdateSpreadsheetDataValue()
    }
  }, [handleUpdateSpreadsheetDataValue, triggerUpdateSpreadsheetDataValue])

  useEffect(() => {
    if (isRevalidating) {
      handleRevalidating()
    }
  }, [handleRevalidating, isRevalidating])

  useImperativeHandle<HTMLDivElement, HTMLDivElement & RefType>(
    innerRef,
    () =>
      ({
        revalidate,
        loading,
      }) as HTMLDivElement & RefType,
    [loading]
  )

  return (
    <CoreViewMoleculeInputFileSpreadsheet
      ref={innerRef}
      {...otherProps}
      name={name}
      attributeNameForAttachmentData={attributeNameForAttachmentData}
      onDropAccepted={handleDropAccepted}
    />
  )
}

export const CoreViewMoleculeInputFileSpreadsheetWatcher = addRefProps(
  CoreViewMoleculeInputFileSpreadsheetWatcherBase
) as ForwardRefExoticComponent<
  CoreViewMoleculeInputFileSpreadsheetWatcherBaseProps &
    RefAttributes<
      HTMLDivElement & CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef
    >
>
