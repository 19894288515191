import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewAdminGeneralConfigurationBankContainer } from './bank'
import { getViewAdminGeneralConfigurationRegionContainer } from './region'
import { getViewAdminGeneralConfigurationScopeOfSupplyContainer } from './scope-of-supply'

export function getViewAdminGeneralConfigurationContainer(
  container = defaultContainer
) {
  return compose(
    getViewAdminGeneralConfigurationBankContainer,
    getViewAdminGeneralConfigurationRegionContainer,
    getViewAdminGeneralConfigurationScopeOfSupplyContainer
  )(container)
}
