import { groupBy } from 'lodash-es'

import {
  DefaultSpreadsheetResponseBodyImport,
  ICoreControllerSharedPageGetCalculatedResourceBase,
} from '@procuraid-frontend/core'

export type ICoreControllerSharedSpreadsheetManagementGetCalculatedResourceErrorMessages<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
> = ICoreControllerSharedPageGetCalculatedResourceBase<
  DataType['data']['errors'] | null,
  Record<string, DataType['data']['errors']> | null
>

export function useCoreControllerSharedSpreadsheetManagementGetCalculatedResourceErrorMessages<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
>(): ICoreControllerSharedSpreadsheetManagementGetCalculatedResourceErrorMessages<DataType> {
  const getCalculatedResource = (input: DataType['data']['errors'] | null) => {
    if (input !== null) {
      return groupBy(input, 'row')
    }
    return null
  }

  return {
    getCalculatedResource,
  }
}
