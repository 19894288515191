import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewAdminOrganizationalStructurePlantContainer } from './plant'
import { getViewAdminOrganizationalStructurePurchasingOrganizationContainer } from './purchasing-organization'
import { getViewAdminOrganizationalStructurePurchasingGroupContainer } from './purchasing-group'

export function getViewAdminOrganizationalStructureContainer(
  container = defaultContainer
) {
  return compose(
    getViewAdminOrganizationalStructurePlantContainer,
    getViewAdminOrganizationalStructurePurchasingOrganizationContainer,
    getViewAdminOrganizationalStructurePurchasingGroupContainer
  )(container)
}
