/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'
import { useCallback, useEffect } from 'react'

import {
  ButtonFormSubmit,
  ButtonOutline,
  DefaultGeneralConfigurationScopeOfSupplyRequestBodyCreate,
  // InputAutoCompleteAsyncSingle,
  InputAutoCompleteSingle,
  InputFormControl,
  LabelForm,
  Modal,
  ModalBaseProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'

import {
  useGeneralConfigurationControllerAdminScopeOfsupplyDropdownManagementOptionsCreateOrUpdate,
  useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementCreateOrUpdateHandleSubmit,
  useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementCreateOrUpdateInitialValue,
  useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementValidator,
  useGeneralConfigurationControllerAdminScopeOfSupplySearchResourceCreate,
} from '@procuraid-frontend/general-configuration'

import {
  ACTION_ADD_RESOURCE,
  ACTION_CANCEL,
  ACTION_SUBMIT,
  LABEL_CODE,
  LABEL_DESCRIPTION,
  LABEL_NAME,
  LABEL_PARENT,
  LABEL_SCOPE_OF_SUPPLY,
  LABEL_SCOPE_OF_SUPPLY_TYPE,
} from '@procuraid-frontend/localization'
import { InputAutoCompleteAsyncSingle } from '@/views/core/02-molecules/input/AsyncSingle'

export interface GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreateBaseProps
  extends ModalBaseProps {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 24vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        justify-content: flex-end;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreateBase = <
  PropType extends
    GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreateBaseProps = GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreateBaseProps,
  PayloadType extends
    DefaultGeneralConfigurationScopeOfSupplyRequestBodyCreate = DefaultGeneralConfigurationScopeOfSupplyRequestBodyCreate,
>({
  innerRef,
  onHide,
  ...otherProps
}: PropType) => {
  const translate = useCoreControllerSharedLocalizationTranslate()
  // const [backupValuesScopeOfSupplyType, setBackupValuesScopeOfSupplyType] = useState<string>('')

  // const [scopeOfSupplyType, setScopeOfSupplyType] = useState<string>('undefined')

  const {
    options: dropdownOptions,
    loadings: dropdownOptionsLoadings,
    loadOptions: loadDropdownOptions,
  } = useGeneralConfigurationControllerAdminScopeOfsupplyDropdownManagementOptionsCreateOrUpdate()

  const { searchResource } =
    useGeneralConfigurationControllerAdminScopeOfSupplySearchResourceCreate()

  const { handleSubmit: handleCreateScopeOfSupply } =
    useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementCreateOrUpdateHandleSubmit<PayloadType>()

  const { initialValue: getInitialValue } =
    useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementCreateOrUpdateInitialValue<PayloadType>()

  const { validator: getValidator } =
    useGeneralConfigurationControllerAdminScopeOfSupplyFormManagementValidator()

  const handleSubmit = async (
    payload: PayloadType,
    helpers: FormikHelpers<PayloadType>
  ) => {
    try {
      await handleCreateScopeOfSupply(payload, helpers)
    } finally {
      onHide?.()
    }
  }

  const loadOptions = useCallback(async () => {
    await loadDropdownOptions(null)
    // await Promise.all([loadDropdownOptions(scopeOfSupplyType), getDefaultDropdownValues()])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // const prevScopeOfSupplyType = (scopeOfSupplyType: string): string => {
  //   const scopeOfSupplyTypes = lookupScopeOfSupplyType || []
  //   const currentScopeOfSupplyTypeIndex = scopeOfSupplyTypes.indexOf(scopeOfSupplyType)
  //   return String(scopeOfSupplyTypes[currentScopeOfSupplyTypeIndex - 1])
  // }

  useEffect(() => {
    loadOptions()
    // setScopeOfSupplyType('undefined')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // useEffect(() => {
  //   if (backupValuesScopeOfSupplyType) {
  //     const prevValue: string = prevScopeOfSupplyType(backupValuesScopeOfSupplyType)
  //     setScopeOfSupplyType(prevValue)
  //     loadDropdownOptions(scopeOfSupplyType)
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [backupValuesScopeOfSupplyType, scopeOfSupplyType])

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValue()}
      validationSchema={getValidator()}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }: FormikProps<PayloadType>) => {
        // setBackupValuesScopeOfSupplyType(values.scope_of_supply_type)

        return (
          <Form>
            <ModalComponent
              ref={innerRef}
              centered
              onHide={onHide}
              {...otherProps}
            >
              <ModalHeader closeButton={false} onHide={onHide}>
                <ModalTitle>
                  {translate(ACTION_ADD_RESOURCE, {
                    resource: translate(LABEL_SCOPE_OF_SUPPLY),
                  })}
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
                  <LabelForm
                    label={translate(LABEL_SCOPE_OF_SUPPLY_TYPE)}
                    htmlFor="scope_of_supply_type"
                    className="mb-0"
                    required={true}
                  />
                  <InputAutoCompleteSingle
                    name="scope_of_supply_type"
                    options={dropdownOptions.scopeOfSupplyType as any[]}
                    placeholder={translate(LABEL_SCOPE_OF_SUPPLY_TYPE)}
                    onChangeValue={async (scopeOfSupplyType) => {
                      setFieldValue('parent', null)
                      await loadDropdownOptions(scopeOfSupplyType)
                    }}
                  />
                  <LabelForm
                    label={translate(LABEL_PARENT)}
                    htmlFor="parent"
                    className="mb-0"
                    required={false}
                  />
                  <InputAutoCompleteAsyncSingle
                    name="parent"
                    resource={searchResource.parent}
                    resourceAdditionalParams={{
                      scope_of_supply_type: values.scope_of_supply_type,
                    }}
                    isLoading={dropdownOptionsLoadings.parent}
                    isDisabled={dropdownOptionsLoadings.parent}
                    defaultOptions={dropdownOptions.parent}
                    transformOptions={(value) => {
                      console.log('item value: ', value)
                      return {
                        value,
                        label: `${value.code} - ${value.name}`,
                      }
                    }}
                    onChangeValue={(value) => {
                      console.log('changed value on parent', value)
                      setFieldValue('code', value?.code ?? null, false)
                    }}
                    placeholder="Pilih Induk"
                  />
                  <LabelForm
                    label={translate(LABEL_CODE)}
                    htmlFor="code"
                    className="mb-0"
                    required={true}
                  />
                  <InputFormControl id="code" name="code" />
                  <LabelForm
                    label={translate(LABEL_NAME)}
                    htmlFor="name"
                    className="mb-0"
                    required={true}
                  />
                  <InputFormControl id="name" name="name" />
                  <LabelForm
                    label={translate(LABEL_DESCRIPTION)}
                    htmlFor="desc"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="desc" name="desc" />
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonOutline
                  onClick={onHide}
                  className="btn-actions"
                  title={translate(ACTION_CANCEL)}
                />
                <ButtonFormSubmit compact size="sm">
                  {translate(ACTION_SUBMIT)}
                </ButtonFormSubmit>
              </ModalFooter>
            </ModalComponent>
          </Form>
        )
      }}
    </Formik>
  )
}

export const GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreate =
  addRefProps(GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreateBase)
