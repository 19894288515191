/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useCallback, useState, useMemo } from 'react'
import {
  GroupBase,
  ActionMeta,
  StylesConfig,
  CSSObjectWithLabel,
} from 'react-select'
import styled from 'styled-components'
import { useFormikContext } from 'formik'

import AsyncSelect, { AsyncProps as AsyncSelectProps } from 'react-select/async'

import {
  DefaultDropdownOptionsType,
  PropsWithInnerRef,
  addRefProps,
  baseSelectComponentStyles,
  danger,
  primary,
  useFieldWithMetaCheckError,
} from '@procuraid-frontend/core'

const AsyncSelectComponent = styled(AsyncSelect)`
  ${baseSelectComponentStyles() as any}
`

export interface InputAutoCompleteAsyncSingleBaseProps<
  OptionType = any,
  IsMulti extends boolean = boolean,
  GroupType extends GroupBase<OptionType> = GroupBase<OptionType>,
> extends PropsWithInnerRef,
    AsyncSelectProps<OptionType, IsMulti, GroupType> {
  name: string
  resource?: (data: any) => Promise<any>
  resourceAdditionalParams?: any
  /**
   * @deprecated this props will be splitted into {@link transformFieldValue} and {@link transformOptions}
   * to fix ambiguity
   */
  optionsFilter?: (data: any) => DefaultDropdownOptionsType
  withFormik?: boolean
  defaultValueFn?: (items: any) => DefaultDropdownOptionsType | null
  onChangeValue?: (value: any) => void | Promise<void>
  transformFieldValue?: ((value: any) => any) | null
  transformOptions?: ((data: any) => DefaultDropdownOptionsType) | null
}

const InputAutoCompleteAsyncSingleBase = <
  PropType extends
    InputAutoCompleteAsyncSingleBaseProps = InputAutoCompleteAsyncSingleBaseProps,
>({
  resource,
  resourceAdditionalParams = {},
  optionsFilter,
  cacheOptions = true,
  classNamePrefix = 'react-select',
  className = 'react-select-container',
  withFormik = true,
  name,
  defaultValueFn,
  defaultValue,
  defaultOptions = true,
  onChangeValue,
  isMulti = false,
  transformOptions: initialTransformOptions = null,
  ...otherProps
}: PropType) => {
  const [isLoading, _setLoading] = useState(false)
  const [isLoadingDefaultValueFn, _setLoadingDefaultValueFn] = useState(
    () => defaultValueFn !== undefined
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_inputQuery, _setInputQuery] = useState('')
  const [usedDefaultValue, setDefaultValue] = useState(() => defaultValue)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_field, { checkError }] = useFieldWithMetaCheckError({ input: name })
  const { setFieldValue } = useFormikContext<any>()
  const usedStyles = useMemo(
    (): StylesConfig =>
      ({
        control: (styles: CSSObjectWithLabel) =>
          ({
            ...styles,
            borderColor: checkError ? danger : styles.borderColor,
            '&:hover': {
              borderColor: checkError
                ? danger
                : (styles?.['&:hover'] as any)?.borderColor ?? primary,
            },
          }) as CSSObjectWithLabel,
      }) satisfies StylesConfig,
    [checkError]
  )

  const transformOptions = initialTransformOptions ?? optionsFilter

  const getDropdownItems = useCallback(
    async (inputValue?: string) => {
      const response = await resource?.({
        params: {
          ...(inputValue && inputValue?.length > 0 ? { q: inputValue } : {}),
          ...resourceAdditionalParams,
        },
      })
      const items = response?.data?.data?.items ?? []
      return typeof transformOptions === 'function'
        ? items?.map(transformOptions) ?? items
        : items
    },
    [resource, resourceAdditionalParams, transformOptions]
  )

  const _handleLoadOptions = useCallback(
    async (inputValue: string) => {
      _setLoading(true)
      try {
        if (resource) {
          return await getDropdownItems(inputValue)
        }
      } finally {
        _setLoading(false)
      }
    },
    [getDropdownItems, resource]
  )
  const _handleInputChange = useCallback((newValue: string) => {
    _setInputQuery(newValue)
  }, [])
  const _handleChange = useCallback(
    (
      value: DefaultDropdownOptionsType | DefaultDropdownOptionsType[],
      {
        action,
      }: ActionMeta<DefaultDropdownOptionsType | DefaultDropdownOptionsType[]>
    ) => {
      let newValue
      if (isMulti) {
        newValue =
          Array.isArray(value) && value.length > 1
            ? value.map((val) => val.value)
            : []
      } else {
        newValue = (value as DefaultDropdownOptionsType).value
        setFieldValue(name, action === 'clear' ? null : newValue)
      }
      onChangeValue?.(newValue)
    },
    [isMulti, name, onChangeValue, setFieldValue]
  )

  const loadDefaultValue = useCallback(async () => {
    try {
      if (isLoadingDefaultValueFn) {
        const responseItems = await getDropdownItems()
        const usedDefaultValue =
          defaultValueFn && typeof defaultValueFn === 'function'
            ? defaultValueFn(responseItems)
            : responseItems
        setDefaultValue(usedDefaultValue)
      }
    } finally {
      _setLoadingDefaultValueFn(false)
    }
  }, [defaultValueFn, getDropdownItems, isLoadingDefaultValueFn])

  useEffect(() => {
    loadDefaultValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!withFormik) {
    return (
      <AsyncSelectComponent
        name={name}
        cacheOptions={cacheOptions}
        classNamePrefix={classNamePrefix}
        className={className}
        defaultValue={usedDefaultValue}
        defaultOptions={defaultOptions}
        isMulti={isMulti}
        {...otherProps}
      />
    )
  }

  return (
    <AsyncSelectComponent
      name={name}
      styles={usedStyles}
      cacheOptions={cacheOptions}
      isLoading={isLoading}
      loadOptions={_handleLoadOptions as any}
      onInputChange={_handleInputChange}
      onChange={_handleChange as any}
      classNamePrefix={classNamePrefix}
      className={className}
      defaultValue={usedDefaultValue}
      defaultOptions={defaultOptions}
      isMulti={isMulti}
      {...otherProps}
    />
  )
}

export const InputAutoCompleteAsyncSingle = addRefProps(
  InputAutoCompleteAsyncSingleBase
)
