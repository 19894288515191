import { useContainerGet } from 'inversify-hooks-esm'

import {
  DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit,
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'

import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_UPDATE,
  IGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById,
  IGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate,
  // useGeneralConfigurationControllerAdminScopeOfSupplyDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/general-configuration'
import { useGeneralConfigurationControllerAdminScopeOfSupplyDataTableManagementGetComponentPropsBase } from '@/controllers/admin/general-configuration'

export interface GeneralConfigurationViewPageAdminScopeOfSupplyListsBaseProps
  extends PropsWithInnerRef {}

const GeneralConfigurationViewPageAdminScopeOfSupplyListsBase = <
  PropType extends
    GeneralConfigurationViewPageAdminScopeOfSupplyListsBaseProps = GeneralConfigurationViewPageAdminScopeOfSupplyListsBaseProps,
  ScopeOfSupplyByIdType extends
    DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit = DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getScopeOfSupplyByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById<ScopeOfSupplyByIdType>
  >(
    GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_GET_RESOURCE_DATA_BY_ID
  )
  const modalUpdateScopeOfSupplyByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate<ScopeOfSupplyByIdType>
  >(
    GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_UPDATE
  )
  const modalCreateSingleScopeOfSupplyCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_CREATE
    )
  const { getComponentProps, refreshTableData } =
    useGeneralConfigurationControllerAdminScopeOfSupplyDataTableManagementGetComponentPropsBase<ScopeOfSupplyByIdType>(
      getScopeOfSupplyByIdCtrl,
      modalUpdateScopeOfSupplyByIdCtrl,
      modalCreateSingleScopeOfSupplyCtrl
    )
  const handleHide = () => refreshTableData?.()
  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSingleScopeOfSupplyCtrl.ModalComponent onHide={handleHide} />
      <modalUpdateScopeOfSupplyByIdCtrl.ModalComponent onHide={handleHide} />
    </>
  )
}

export const GeneralConfigurationViewPageAdminScopeOfSupplyLists = addRefProps(
  GeneralConfigurationViewPageAdminScopeOfSupplyListsBase
)
