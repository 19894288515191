import { container as defaultContainer } from 'inversify-hooks-esm'
import {
  CORE_CONTROLLER_SHARED_APP_INITIALIZER_BASE,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE,
  CORE_SERVICE_SW_REGISTRATION_BASE,
} from '@procuraid-frontend/core'

import { getCoreControllerSharedAppInitializerBase } from '@/controllers'

export function getMainThreadInitializerControllerContainer(
  container = defaultContainer
) {
  container
    .bind(CORE_CONTROLLER_SHARED_APP_INITIALIZER_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreControllerSharedAppInitializerBase(
        c.get(CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE),
        c.get(CORE_SERVICE_SW_REGISTRATION_BASE)
      )
    )

  return container
}
