import {
  DefaultSpreadsheetResponseBodyImport,
  DefaultSpreadsheetResponseBodyImportOriginal,
  ICoreControllerSharedPageTransformToViewBase,
} from '@procuraid-frontend/core'

export function useCoreControllerSharedSpreadsheetManagementTransformRequestBodyValidate<
  InputType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  OutputType extends
    DefaultSpreadsheetResponseBodyImportOriginal = DefaultSpreadsheetResponseBodyImportOriginal,
>(): ICoreControllerSharedPageTransformToViewBase<InputType, OutputType> {
  const transform = (input: InputType) => {
    input.data.rows = input.data.rows.map((row) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const rowData = (row as any).data as any[]
      const cell_hsh = rowData.reduce(
        (acc, item) => ({
          ...acc,
          [item.name]: item.value,
        }),
        {}
      )
      const cells = rowData.map(({ value }) => ({ value }))
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      delete (row as any).data
      return {
        ...row,
        cell_hsh,
        cells,
      }
    })
    return input as unknown as OutputType
  }

  return {
    transform,
  }
}
