import { container as defaultContainer } from 'inversify-hooks-esm'
import {
  CORE_RESOURCE_GENERAL_CONFIGURATION_BANK_BASE,
  CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE,
  CORE_RESOURCE_GENERAL_CONFIGURATION_SCOPE_OF_SUPPLY_BASE,
  CORE_RESOURCE_LOOKUP_BASE,
  CORE_RESOURCE_NAVIGATION_TREE_BASE,
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PLANT_BASE,
  CORE_RESOURCE_PING_BASE,
  CORE_RESOURCE_PRIVILEGE_BASE,
  CORE_RESOURCE_SPREADSHEET_BASE,
  CORE_SERVICE_REMOTE_BASE,
  useCoreResourceGeneralConfigurationBankBase,
  useCoreResourceGeneralConfigurationRegionBase,
  useCoreResourceGeneralConfigurationScopeOfSupplyBase,
  useCoreResourceLookupBase,
  useCoreResourceNavigationTreeBase,
  useCoreResourceOrganizationalStructurePlantBase,
  useCoreResourcePingBase,
  useCoreResourcePrivilegeBase,
  useCoreResourceSpreadsheetBase,
} from '@procuraid-frontend/core'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE,
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE,
  useCoreResourceOrganizationalStructurePurchasingGroupBase,
  useCoreResourceOrganizationalStructurePurchasingOrganizationBase,
} from '@/resources'

export function getResourcesMainThreadIntoContainer(
  container = defaultContainer
) {
  container
    .bind(CORE_RESOURCE_LOOKUP_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceLookupBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  container
    .bind(CORE_RESOURCE_NAVIGATION_TREE_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceNavigationTreeBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  container
    .bind(CORE_RESOURCE_PING_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourcePingBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )
    .inSingletonScope()

  container
    .bind(CORE_RESOURCE_PRIVILEGE_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourcePrivilegeBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  container
    .bind(CORE_RESOURCE_SPREADSHEET_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceSpreadsheetBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  // ORGANIZATIONAL_STRUCTURE
  container
    .bind(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PLANT_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceOrganizationalStructurePlantBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  container
    .bind(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceOrganizationalStructurePurchasingOrganizationBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  container
    .bind(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceOrganizationalStructurePurchasingGroupBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  // GENERAL_CONFIGURATION
  container
    .bind(CORE_RESOURCE_GENERAL_CONFIGURATION_SCOPE_OF_SUPPLY_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceGeneralConfigurationScopeOfSupplyBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  container
    .bind(CORE_RESOURCE_GENERAL_CONFIGURATION_BANK_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceGeneralConfigurationBankBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  container
    .bind(CORE_RESOURCE_GENERAL_CONFIGURATION_REGION_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceGeneralConfigurationRegionBase(
        c.get(CORE_SERVICE_REMOTE_BASE)
      )
    )

  return container
}
