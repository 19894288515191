import { DefaultViewItemEditableType } from '@/views/core/02-molecules/lists/editable'
import {
  DefaultSpreadsheetResponseBodyImportOnlyDataRows,
  ICoreControllerSharedPageGetCalculatedResourceBase,
} from '@procuraid-frontend/core'

export interface DefaultCoreControllerAdminOrganizationalStructurePurchasingGroupGetCalculatedResourceSpreadsheetViewDataInput<
  RowType extends
    DefaultSpreadsheetResponseBodyImportOnlyDataRows = DefaultSpreadsheetResponseBodyImportOnlyDataRows,
> {
  row: RowType
  index: number
}

export function useCoreControllerAdminOrganizationalStructurePurchasingGroupGetCalculatedResourceSpreadsheetViewData<
  RowType extends
    DefaultSpreadsheetResponseBodyImportOnlyDataRows = DefaultSpreadsheetResponseBodyImportOnlyDataRows,
  InputType extends
    DefaultCoreControllerAdminOrganizationalStructurePurchasingGroupGetCalculatedResourceSpreadsheetViewDataInput<RowType> = DefaultCoreControllerAdminOrganizationalStructurePurchasingGroupGetCalculatedResourceSpreadsheetViewDataInput<RowType>,
  OutputType extends DefaultViewItemEditableType = DefaultViewItemEditableType,
>(): ICoreControllerSharedPageGetCalculatedResourceBase<
  InputType,
  OutputType[][]
> {
  const getCalculatedResource = ({ row, index: parentIndex }: InputType) => {
    const items = row.data.map<OutputType>(
      (item, index) =>
        ({
          editable: true,
          title: item.name,
          property: `spreadsheetData.data.rows[${parentIndex}].data[${index}].value`,
          value: item.value ?? null,
        }) as OutputType
    )
    return [items.slice(0, 2), items.slice(3)] as OutputType[][]
  }

  return {
    getCalculatedResource,
  }
}
