import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  CORE_CONTROLLER_SHARED_PAGE_FORGOT_PASSWORD_FORM_MANAGEMENT_HANDLE_SUBMIT_BASE,
  CORE_CONTROLLER_SHARED_PAGE_FORGOT_PASSWORD_FORM_MANAGEMENT_VALIDATOR_BASE,
  CORE_CONTROLLER_SHARED_PAGE_LOGIN_FORM_MANAGEMENT_HANDLE_SUBMIT_BASE,
  CORE_CONTROLLER_SHARED_PAGE_LOGIN_FORM_MANAGEMENT_VALIDATOR_BASE,
  CORE_CONTROLLER_SHARED_PAGE_LOGIN_RESOURCE_NAVIGATION_BASE,
  CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_UPDATE_RESOURCE_SET_ROLE,
  CORE_RESOURCE_FORGOT_PASSWORD_BASE,
  CORE_RESOURCE_LOGIN_BASE,
  CORE_SERVICE_REMOTE_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE,
  CORE_SERVICE_TOAST_BASE,
  CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_BASE,
  CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_FORGOT_PASSWORD,
  CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_LOGIN,
  CORE_VIEW_ORGANISM_SIDEBAR_LOGIN_CONTENT,
  CoreViewOrganismFormAuthentication,
  CoreViewOrganismFormAuthenticationForgotPassword,
  CoreViewOrganismFormAuthenticationLogin,
  CoreViewOrganismSidebarLoginContent,
  ICoreResourceForgotPasswordBase,
  ICoreResourceLoginBase,
  ICoreServiceStateManagementInstanceUserBase,
  useCoreControllerSharedPageForgotPasswordFormManagementHandleSubmitBase,
  useCoreControllerSharedPageForgotPasswordFormManagementValidatorBase,
  useCoreControllerSharedPageLoginFormManagementHandleSubmitBase,
  useCoreControllerSharedPageLoginFormManagementValidatorBase,
  useCoreControllerSharedPageLoginResourceNavigationBase,
  useCoreResourceForgotPasswordBase,
  useCoreResourceLoginBase,
} from '@procuraid-frontend/core'

export function getViewPublicAuthenticationContainer(
  container = defaultContainer
) {
  // [START] AUTHENTICATION - LOGIN
  container
    .bind(CORE_RESOURCE_LOGIN_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceLoginBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_LOGIN_FORM_MANAGEMENT_HANDLE_SUBMIT_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageLoginFormManagementHandleSubmitBase(
        c.get<ICoreServiceStateManagementInstanceUserBase>(
          CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE
        ),
        c.get<ICoreResourceLoginBase>(CORE_RESOURCE_LOGIN_BASE),
        c.get(CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_UPDATE_RESOURCE_SET_ROLE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_LOGIN_FORM_MANAGEMENT_VALIDATOR_BASE)
    .toDynamicValue(() =>
      useCoreControllerSharedPageLoginFormManagementValidatorBase()
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_LOGIN_RESOURCE_NAVIGATION_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageLoginResourceNavigationBase(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE)
      )
    )

  container
    .bind(CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_LOGIN)
    .toConstantValue(CoreViewOrganismFormAuthenticationLogin)
  // [END] AUTHENTICATION - LOGIN

  // [START] AUTHENTICATION - FORGOT_PASSWORD
  container
    .bind(CORE_RESOURCE_FORGOT_PASSWORD_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreResourceForgotPasswordBase(c.get(CORE_SERVICE_REMOTE_BASE))
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_FORGOT_PASSWORD_FORM_MANAGEMENT_HANDLE_SUBMIT_BASE
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageForgotPasswordFormManagementHandleSubmitBase(
        c.get(CORE_SERVICE_TOAST_BASE),
        c.get<ICoreResourceForgotPasswordBase>(
          CORE_RESOURCE_FORGOT_PASSWORD_BASE
        )
      )
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_FORGOT_PASSWORD_FORM_MANAGEMENT_VALIDATOR_BASE
    )
    .toDynamicValue(() =>
      useCoreControllerSharedPageForgotPasswordFormManagementValidatorBase()
    )

  container
    .bind(CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_FORGOT_PASSWORD)
    .toConstantValue(CoreViewOrganismFormAuthenticationForgotPassword)
  // [END] AUTHENTICATION - FORGOT_PASSWORD

  container
    .bind(CORE_VIEW_ORGANISM_FORM_AUTHENTICATION_BASE)
    .toConstantValue(CoreViewOrganismFormAuthentication)

  container
    .bind(CORE_VIEW_ORGANISM_SIDEBAR_LOGIN_CONTENT)
    .toConstantValue(CoreViewOrganismSidebarLoginContent)

  return container
}
