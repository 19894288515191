/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

import {
  ButtonFormSubmit,
  ButtonOutline,
  CORE_SERVICE_LOOKUP_BASE,
  ICoreServiceLookupBase,
  InputAutoCompleteAsyncSingle,
  InputAutoCompleteSingle,
  InputFormControl,
  LabelForm,
  Modal,
  ModalBaseProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedTransformToDropdownOptionsBase,
} from '@procuraid-frontend/core'
import { Form, Formik, FormikHelpers, FormikProps } from 'formik'

import {
  useControllerAdminOrganizationStructureScopeOfSuppliesDropdownManagementDefaultValueCreateOrUpdate,
  useControllerAdminOrganizationStructureScopeOfSuppliesFormManagementValidator,
  useControllerAdminOrganizationalStructureScopeOfsuppliesDropdownManagementOptionsCreateOrUpdate,
  useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateHandleSubmit,
  useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValue,
} from '@/controllers'

import { DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate } from '@/resources'
import {
  ACTION_ADD_RESOURCE,
  ACTION_CANCEL,
  ACTION_SUBMIT,
  LABEL_CODE,
  LABEL_DESCRIPTION,
  LABEL_NAME,
  LABEL_PARENT,
  LABEL_SCOPE_OF_SUPPLY,
  LABEL_SCOPE_OF_SUPPLY_TYPE,
} from '@procuraid-frontend/localization'

import { useContainerGet } from 'inversify-hooks-esm'
import { useCallback, useEffect, useState } from 'react'

export interface OrganizationalStructureViewOrganismModalScopeOfSuppliesCreateBaseProps
  extends ModalBaseProps {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 24vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        justify-content: flex-end;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const OrganizationalStructureViewOrganismModalScopeOfSuppliesCreateBase = <
  PropType extends
    OrganizationalStructureViewOrganismModalScopeOfSuppliesCreateBaseProps = OrganizationalStructureViewOrganismModalScopeOfSuppliesCreateBaseProps,
  PayloadType extends
    DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate = DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
>({
  innerRef,
  onHide,
  ...otherProps
}: PropType) => {
  const translate = useCoreControllerSharedLocalizationTranslate()
  const [backupValuesScopeOfSupplyType, setBackupValuesScopeOfSupplyType] =
    useState<string>('')

  const [scopeOfSupplyType, setScopeOfSupplyType] =
    useState<string>('undefined')

  const { transformDataToDropdownOptions } =
    useCoreControllerSharedTransformToDropdownOptionsBase()

  const lookupBaseService = useContainerGet<ICoreServiceLookupBase>(
    CORE_SERVICE_LOOKUP_BASE
  )

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const lookupScopeOfSupplyType =
    lookupBaseService.getValue<string[]>('scope_of_supply_types') ?? []

  const scopeOfSupplyTypeOptions = transformDataToDropdownOptions(
    lookupScopeOfSupplyType,
    (name) => translate(name)
  )

  const {
    options: dropdownOptions,
    loadings: dropdownOptionsLoadings,
    loadOptions: loadDropdownOptions,
    scopeOfSuppliesResourceSearch,
  } = useControllerAdminOrganizationalStructureScopeOfsuppliesDropdownManagementOptionsCreateOrUpdate()

  const {
    defaultValue: defaultDropdownValues,
    getDefaultValue: getDefaultDropdownValues,
  } =
    useControllerAdminOrganizationStructureScopeOfSuppliesDropdownManagementDefaultValueCreateOrUpdate()

  const { handleSubmit: handleCreateScopeOfSupply } =
    useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateHandleSubmit<PayloadType>()

  const { initialValue: getInitialValue } =
    useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValue<PayloadType>()

  const handleSubmit = async (
    payload: PayloadType,
    helpers: FormikHelpers<PayloadType>
  ) => {
    try {
      await handleCreateScopeOfSupply(payload, helpers)
    } finally {
      onHide?.()
    }
  }

  const loadOptions = useCallback(async () => {
    await Promise.all([
      loadDropdownOptions(scopeOfSupplyType),
      getDefaultDropdownValues(),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const prevScopeOfSupplyType = (scopeOfSupplyType: string): string => {
    const scopeOfSupplyTypes = lookupScopeOfSupplyType || []
    const currentScopeOfSupplyTypeIndex =
      scopeOfSupplyTypes.indexOf(scopeOfSupplyType)
    return String(scopeOfSupplyTypes[currentScopeOfSupplyTypeIndex - 1])
  }

  const { validator: getValidator } =
    useControllerAdminOrganizationStructureScopeOfSuppliesFormManagementValidator()

  useEffect(() => {
    loadOptions()
    setScopeOfSupplyType('undefined')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (backupValuesScopeOfSupplyType) {
      const prevValue: string = prevScopeOfSupplyType(
        backupValuesScopeOfSupplyType
      )
      setScopeOfSupplyType(prevValue)
      loadDropdownOptions(scopeOfSupplyType)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [backupValuesScopeOfSupplyType, scopeOfSupplyType])

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValue()}
      validationSchema={getValidator()}
      onSubmit={handleSubmit}
    >
      {({ setFieldValue, values }: FormikProps<any>) => {
        setBackupValuesScopeOfSupplyType(values.scope_of_supply_type)

        return (
          <Form>
            <ModalComponent
              ref={innerRef}
              centered
              onHide={onHide}
              {...otherProps}
            >
              <ModalHeader closeButton={false} onHide={onHide}>
                <ModalTitle>
                  {translate(ACTION_ADD_RESOURCE, {
                    resource: translate(LABEL_SCOPE_OF_SUPPLY),
                  })}
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
                  <LabelForm
                    label={translate(LABEL_SCOPE_OF_SUPPLY_TYPE)}
                    htmlFor="scope_of_supply_type"
                    className="mb-0"
                    required={true}
                  />
                  <InputAutoCompleteSingle
                    name="scope_of_supply_type"
                    options={scopeOfSupplyTypeOptions}
                    placeholder={translate(LABEL_SCOPE_OF_SUPPLY_TYPE)}
                    onChangeValue={(value) => {
                      loadDropdownOptions(value)
                    }}
                  />
                  <LabelForm
                    label={translate(LABEL_PARENT)}
                    htmlFor="parent"
                    className="mb-0"
                    required={false}
                  />
                  <InputAutoCompleteAsyncSingle
                    name="parent"
                    resource={scopeOfSuppliesResourceSearch.search}
                    resourceAdditionalParams={{
                      scope_of_supply_type: scopeOfSupplyType,
                    }}
                    isLoading={dropdownOptionsLoadings}
                    isDisabled={dropdownOptionsLoadings}
                    defaultOptions={dropdownOptions}
                    defaultValueFn={defaultDropdownValues as any}
                    optionsFilter={(value) => ({
                      value,
                      label: `${value['code']} - ${value['name']}`,
                    })}
                    onChangeValue={(value) => {
                      setFieldValue('code', value?.code ?? null, false)
                    }}
                    placeholder="Pilih Induk"
                  />
                  <LabelForm
                    label={translate(LABEL_CODE)}
                    htmlFor="code"
                    className="mb-0"
                    required={true}
                  />
                  <InputFormControl id="code" name="code" />
                  <LabelForm
                    label={translate(LABEL_NAME)}
                    htmlFor="name"
                    className="mb-0"
                    required={true}
                  />
                  <InputFormControl id="name" name="name" />
                  <LabelForm
                    label={translate(LABEL_DESCRIPTION)}
                    htmlFor="desc"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="desc" name="desc" />
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonOutline
                  onClick={onHide}
                  className="btn-actions"
                  title={translate(ACTION_CANCEL)}
                />
                <ButtonFormSubmit compact size="sm">
                  {translate(ACTION_SUBMIT)}
                </ButtonFormSubmit>
              </ModalFooter>
            </ModalComponent>
          </Form>
        )
      }}
    </Formik>
  )
}

export const OrganizationalStructureViewOrganismModalScopeOfSuppliesCreate =
  addRefProps(OrganizationalStructureViewOrganismModalScopeOfSuppliesCreateBase)
