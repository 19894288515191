import type { FormikHelpers } from 'formik'
import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_SERVICE_TOAST_BASE,
  ICoreControllerSharedPageFormManagementHandleSubmit,
  ICoreResourceOrganizationalStructureBase,
  ICoreServiceToastBase,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import {
  LABEL_SCOPE_OF_SUPPLY,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED,
} from '@procuraid-frontend/localization'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_SCOPE_OF_SUPPLIES_BASE,
  DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
} from '@/resources'

export function useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateHandleSubmit<
  PayloadType extends
    DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate = DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
>(): ICoreControllerSharedPageFormManagementHandleSubmit<PayloadType> {
  const scopeOfSuppliesResource =
    useContainerGet<ICoreResourceOrganizationalStructureBase>(
      CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_SCOPE_OF_SUPPLIES_BASE
    )
  const toastService = useContainerGet<ICoreServiceToastBase>(
    CORE_SERVICE_TOAST_BASE
  )

  const translate = useCoreControllerSharedLocalizationTranslate()

  const handleSubmit = async (
    data: PayloadType,
    { setSubmitting }: FormikHelpers<PayloadType>
  ) => {
    setSubmitting(true)
    const isUpdate = data?.id && typeof data.id === 'string'
    try {
      isUpdate
        ? await scopeOfSuppliesResource.update(data.id as string, {
            data: data,
          })
        : await scopeOfSuppliesResource.save({ data: data })
      toastService.showSuccessToast({
        subtitle: translate(
          isUpdate
            ? NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED
            : NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED,
          {
            resource: translate(LABEL_SCOPE_OF_SUPPLY),
          }
        ),
      })
    } finally {
      setSubmitting(false)
    }
  }

  return {
    handleSubmit,
  }
}
