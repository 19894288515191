import { Formik, Form, FormikHelpers } from 'formik'
import { RefObject, useRef } from 'react'
import styled from 'styled-components'

import {
  Button,
  ButtonFormSubmit,
  ButtonOutline,
  DefaultSpreadsheetResponseBodyImport,
  Modal,
  ModalBaseProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  PropsWithInnerRef,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'
import {
  ACTION_CANCEL,
  ACTION_DOWNLOAD_TEMPLATE,
  ACTION_SUBMIT,
  ACTION_UPLOAD,
} from '@procuraid-frontend/localization'
import {
  DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput,
  useCoreControllerAdminOrganizationalStructurePlantGetCalculatedResourceSpreadsheetViewData,
  useCoreControllerSharedSpreadsheetManagementCheckValidConditionHasError,
  useCoreControllerSharedSpreadsheetManagementFormManagementCommitHandleSubmit,
  useCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValue,
  useCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidator,
  useCoreControllerSharedSpreadsheetManagementUpdateResourceRevalidate,
  useOrganizationalStructureControllerAdminPlantGetResourceDataFromSpreadsheetFile,
  useOrganizationalStructureControllerAdminPlantResourceNavigationDownloadSpreadsheetFileReference,
} from '@/controllers'
import {
  CoreViewAtomButtonReactiveForm,
  CoreViewMoleculeInputFileSpreadsheetWatcher,
  CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef,
  CoreViewOrganismFormSpreadsheetPreview,
} from '@/views'

export interface IViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFileBaseProps
  extends ModalBaseProps,
    PropsWithInnerRef {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 50vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const ViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFileBase =
  <
    PropType extends
      IViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFileBaseProps = IViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFileBaseProps,
    DataType extends
      DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
    FormPayloadType extends
      DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<DataType> = DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<DataType>,
    RefType extends
      CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef = CoreViewMoleculeInputFileSpreadsheetWatcherBaseRef,
  >({
    innerRef,
    onHide,
    ...otherProps
  }: PropType) => {
    const attachmentRef = useRef<HTMLDivElement & RefType>() as RefObject<
      HTMLDivElement & RefType
    >

    const { checkValidCondition: checkSpreadsheetDataHasError } =
      useCoreControllerSharedSpreadsheetManagementCheckValidConditionHasError()

    const { getCalculatedResource: getSpreadsheetViewData } =
      useCoreControllerAdminOrganizationalStructurePlantGetCalculatedResourceSpreadsheetViewData()
    const { getData: getPlantDataFromSpreadsheetFile } =
      useOrganizationalStructureControllerAdminPlantGetResourceDataFromSpreadsheetFile()

    const { handleSubmit: commitSpreadsheetData } =
      useCoreControllerSharedSpreadsheetManagementFormManagementCommitHandleSubmit<
        DataType,
        FormPayloadType
      >()

    const { initialValue: getInitialValue } =
      useCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValue<
        DataType,
        FormPayloadType
      >()

    const { next: downloadFile } =
      useOrganizationalStructureControllerAdminPlantResourceNavigationDownloadSpreadsheetFileReference()

    const { updateResource: revalidateSpreadsheetData } =
      useCoreControllerSharedSpreadsheetManagementUpdateResourceRevalidate<DataType>()

    const { validator: getValidator } =
      useCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidator()

    const translate = useCoreControllerSharedLocalizationTranslate()

    const loading = attachmentRef.current?.loading ?? false

    const handleAttachmentChanged = async (files: File[]) =>
      await getPlantDataFromSpreadsheetFile({ files })
    const handleCommitSpreadsheetData = async (
      data: FormPayloadType,
      helpers: FormikHelpers<FormPayloadType>
    ) => {
      try {
        await commitSpreadsheetData(data, helpers)
      } finally {
        onHide?.()
      }
    }
    const handleRevalidateSpreadsheetData = () =>
      attachmentRef.current?.revalidate()
    const handleSpreadsheetComponentRevalidate = async (data: DataType) =>
      await revalidateSpreadsheetData({ data })

    return (
      <Formik
        enableReinitialize
        initialValues={getInitialValue()}
        validationSchema={getValidator()}
        onSubmit={handleCommitSpreadsheetData}
      >
        <Form>
          <ModalComponent
            ref={innerRef}
            centered
            onHide={onHide}
            {...otherProps}
          >
            <ModalHeader closeButton={false} onHide={onHide}>
              <ModalTitle>{translate(ACTION_UPLOAD)}</ModalTitle>
            </ModalHeader>
            <ModalBody>
              <CoreViewMoleculeInputFileSpreadsheetWatcher
                ref={attachmentRef}
                name="attachmentData"
                withButton={false}
                onAttachmentChanged={handleAttachmentChanged}
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                onRevalidate={handleSpreadsheetComponentRevalidate as any}
              />
              <CoreViewOrganismFormSpreadsheetPreview
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                itemTransformer={getSpreadsheetViewData as any}
                loading={loading}
              />
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between">
              <Button
                variant="secondary"
                outline
                compact
                onClick={downloadFile}
              >
                {translate(ACTION_DOWNLOAD_TEMPLATE)}
              </Button>
              <div className="d-flex" style={{ columnGap: '0.5rem' }}>
                <ButtonOutline
                  onClick={onHide}
                  className="btn-actions"
                  title={translate(ACTION_CANCEL)}
                />
                <CoreViewAtomButtonReactiveForm
                  outline
                  compact
                  onClick={handleRevalidateSpreadsheetData}
                  checkDisabled={(value) =>
                    !checkSpreadsheetDataHasError({
                      data:
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        (value as any)?.spreadsheetData?.data?.errors ?? null,
                      returnTrueWhenEmpty: false,
                    })
                  }
                >{`Validasi Ulang`}</CoreViewAtomButtonReactiveForm>
                <ButtonFormSubmit compact>
                  {translate(ACTION_SUBMIT)}
                </ButtonFormSubmit>
              </div>
            </ModalFooter>
          </ModalComponent>
        </Form>
      </Formik>
    )
  }

export const ViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFile =
  addRefProps(
    ViewOrganizationalStructureAdminOrganismModalPlantCreateFromSpreadsheetFileBase
  )
