/* eslint-disable react-refresh/only-export-components */
import loadable from '@loadable/component'

import { Route } from 'react-router-dom'

import { ICoreRouterComponent } from '@/routers/types'

const CoreViewPageHome = loadable(async () => {
  const { CoreViewPageHome: C } = await import('@procuraid-frontend/core')
  return C
})

const CoreViewPageWithTabs = loadable(async () => {
  const { CoreViewPageWithTabs: C } = await import('@procuraid-frontend/core')
  return C
})

const OrganizationalStructureViewPageAdminPlantLists = loadable(async () => {
  const { OrganizationalStructureViewPageAdminPlantLists: C } = await import(
    '@/views'
  )
  return C
})

const OrganizationalStructureViewPageAdminPurchasingGroupLists = loadable(
  async () => {
    const { OrganizationalStructureViewPageAdminPurchasingGroupLists: C } =
      await import('@/views')
    return C
  }
)

const GeneralConfigurationViewPageAdminScopeOfSupplyLists = loadable(
  async () => {
    const { GeneralConfigurationViewPageAdminScopeOfSupplyLists: C } =
      await import('@procuraid-frontend/general-configuration')
    return C
  }
)

const GeneralConfigurationViewPageAdminBankLists = loadable(async () => {
  const { GeneralConfigurationViewPageAdminBankLists: C } = await import(
    '@procuraid-frontend/general-configuration'
  )
  return C
})

const OrganizationalStructureViewPageAdminPurchasingOrganizationLists =
  loadable(async () => {
    const {
      OrganizationalStructureViewPageAdminPurchasingOrganizationLists: C,
    } = await import('@/views')
    return C
  })

export const CORE_ROUTER_PRIVATE_ADMIN_BASE = 'CoreRouterPrivateAdminBase'

export function useDemoRouterPrivateAdminBase(): ICoreRouterComponent {
  const getRouter = () => {
    return (
      <Route path="admin" element={<CoreViewPageHome />}>
        {/** <-- [START] ADMIN HOME PATHS --> */}
        <Route path="home" element={<h2>Home</h2>} />
        {/** <-- [END] ADMIN HOME PATHS --> */}

        {/** <-- [START] ADMIN VENDOR_MANAGEMENT PATHS --> */}
        <Route path="vendor_management" element={<CoreViewPageWithTabs />}>
          <Route path="vendors" element={<h1>Vendors</h1>} />
          <Route path="vendors/:vendor_id" element={<h1>Vendors with ID</h1>} />
        </Route>
        {/** <-- [END] ADMIN VENDOR_MANAGEMENT PATHS --> */}

        {/** <-- [START] ADMIN PURCHASE_REQUISITION PATHS --> */}
        <Route path="purchase_requisition" element={<CoreViewPageWithTabs />}>
          <Route path="purchase_requisitions" element={<h1>PR Headers</h1>} />
          <Route
            path="purchase_requisitions/:purch_reqn_id"
            element={<h1>PR Header with ID</h1>}
          />
        </Route>
        {/** <-- [END] ADMIN PURCHASE_REQUISITION PATHS --> */}

        {/**
         * @todo split master data into organizational structure and general configuration
         */}
        {/** <-- [START] ADMIN ORGANIZATIONAL_STRUCTURE PATHS --> */}
        <Route path="master" element={<CoreViewPageWithTabs />}>
          <Route
            path="plants"
            element={<OrganizationalStructureViewPageAdminPlantLists />}
          />
          <Route
            path="purch_orgs"
            element={
              <OrganizationalStructureViewPageAdminPurchasingOrganizationLists />
            }
          />
          <Route
            path="purch_groups"
            element={
              <OrganizationalStructureViewPageAdminPurchasingGroupLists />
            }
          />
          <Route
            path="scope_of_supplies"
            element={<GeneralConfigurationViewPageAdminScopeOfSupplyLists />}
          />
          <Route
            path="banks"
            element={<GeneralConfigurationViewPageAdminBankLists />}
          />
        </Route>
        {/** <-- [END] ADMIN ORGANIZATIONAL_STRUCTURE PATHS --> */}
      </Route>
    )
  }

  return {
    getRouter,
  }
}
