/* eslint-disable @typescript-eslint/no-explicit-any */
import { CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_SCOPE_OF_SUPPLIES_BASE } from '@/resources'
import {
  DefaultDropdownOptionsType,
  ICoreResourceOrganizationalStructureOnlySearch,
  useCoreControllerSharedTransformDataToDropdownOptionsFromResource,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'
import { useCallback, useState } from 'react'

export function useControllerAdminOrganizationalStructureScopeOfsuppliesDropdownManagementOptionsCreateOrUpdate<
  DropdownOptionsType extends
    DefaultDropdownOptionsType = DefaultDropdownOptionsType,
>() {
  const [loadings, setLoadings] = useState<boolean>(true)
  const [options, setOptions] = useState<DropdownOptionsType[]>([])

  const { transformResourceDataToDropdownOptions } =
    useCoreControllerSharedTransformDataToDropdownOptionsFromResource()

  const scopeOfSuppliesResourceSearch = useContainerGet<
    ICoreResourceOrganizationalStructureOnlySearch<{ data: { items: any[] } }>
  >(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_SCOPE_OF_SUPPLIES_BASE)

  const createOptions = useCallback(
    async (scope_of_supply_type: string | undefined) => {
      try {
        const scope_of_supply = (await transformResourceDataToDropdownOptions(
          () =>
            scopeOfSuppliesResourceSearch.search({
              params: {
                scope_of_supply_type,
              },
            }),
          (item) => `${item?.['code']} - ${item?.['name']}`
        )) as DropdownOptionsType[]
        return {
          scope_of_supply,
        }
      } finally {
        setLoadings(false)
      }
    },
    [scopeOfSuppliesResourceSearch, transformResourceDataToDropdownOptions]
  )

  const loadOptions = useCallback(
    async (scope_of_supply_type: string | undefined) => {
      const options = await createOptions(scope_of_supply_type)
      setOptions(options.scope_of_supply)
    },
    [createOptions]
  )

  return {
    options,
    createOptions,
    loadings,
    loadOptions,
    scopeOfSuppliesResourceSearch,
  }
}
