import { i18n as i18nType } from 'i18next'
import { useContainerGet } from 'inversify-hooks-esm'

import {
  I18nProvider,
  useCoreControllerSharedLanguageI18n,
  useCoreControllerSharedPageServerTimeManagerSync,
} from '@procuraid-frontend/core'

import { I18nextProvider } from 'react-i18next'

import { CORE_ROUTER_BASE, ICoreRouterComponent } from '@/routers'

export const ComponentProviderChildLevel2 = () => {
  const appRouter = useContainerGet<ICoreRouterComponent>(CORE_ROUTER_BASE)

  const { i18n } = useCoreControllerSharedLanguageI18n<i18nType>()

  useCoreControllerSharedPageServerTimeManagerSync()

  return (
    i18n && (
      <I18nextProvider i18n={i18n}>
        <I18nProvider value={i18n}>{appRouter.getRouter()}</I18nProvider>
      </I18nextProvider>
    )
  )
}
