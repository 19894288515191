import { DefaultSpreadsheetResponseBodyImport } from '@procuraid-frontend/core'

import {
  DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOptions,
  DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput,
  ICoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValue,
} from '../types'

export function useCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValue<
  SpreadsheetFileType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  OutputType extends
    DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<SpreadsheetFileType> = DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOutput<SpreadsheetFileType>,
  OptionsType extends
    DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOptions = DefaultCoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValueOptions,
>(): ICoreControllerSharedSpreadsheetManagementFormManagementCommitInitialValue<
  SpreadsheetFileType,
  OutputType,
  OptionsType
> {
  const initialValue = (options?: OptionsType): OutputType => {
    const attachmentData =
      options?.attributeForAttachmentData ?? 'attachmentData'
    const spreadsheetData =
      options?.attributeForSpreadsheetData ?? 'spreadsheetData'

    return {
      [`${attachmentData}`]: [],
      [`${spreadsheetData}`]: null,
    } as unknown as OutputType
  }

  return {
    initialValue,
  }
}
