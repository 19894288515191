import { useRef } from 'react'
import styled from 'styled-components'

import {
  ButtonGhostWithSVGIcon,
  ButtonRow,
  ICoreControllerSharedPageModalManagementBase,
  IconPlus,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedPageDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/core'

import {
  ACTION_EDIT,
  ACTION_NEW_ENTRY,
  FORMATTED_DATE_COMPLETE,
  LABEL_ACTION,
  LABEL_BANK_TYPE,
  LABEL_CODE,
  LABEL_COUNTRY,
  LABEL_DISTRICT,
  LABEL_FROM_DATE,
  LABEL_NAME,
  LABEL_OTHER_COUNTRY,
  LABEL_OTHER_DISTRICT,
  LABEL_POSTAL_ADDRESS,
  LABEL_SWIFT_CODE,
  LABEL_THRU_DATE,
} from '@procuraid-frontend/localization'

import {
  IOrganizationalStructureControllerAdminBankGetResourceDataById,
  IOrganizationalStructureControllerAdminBankModalManagementUpdate,
  useOrganizationalStructureControllerAdminBankManageItemsFromTableGet,
} from '@/controllers'

import iconEdit from '@/assets/svg/icon-action-edit.svg'
import {
  DefaultOrganizationalStructureBankResponseBodyEdit,
  DefaultOrganizationalStructureBankResponseBodyGet,
} from '@/resources'

// eslint-disable-next-line react-refresh/only-export-components
const ButtonRowDataTable = styled(ButtonRow)`
  min-width: 100% !important;
`

export function useOrganizationalStructureControllerAdminBankDataTableManagementGetComponentPropsBase<
  DataByIdType extends
    DefaultOrganizationalStructureBankResponseBodyEdit = DefaultOrganizationalStructureBankResponseBodyEdit,
  DataType extends
    DefaultOrganizationalStructureBankResponseBodyGet = DefaultOrganizationalStructureBankResponseBodyGet,
>(
  getBankByIdCtrl: IOrganizationalStructureControllerAdminBankGetResourceDataById<DataByIdType>,
  modalUpdateBankByIdCtrl: IOrganizationalStructureControllerAdminBankModalManagementUpdate<DataByIdType>,
  modalCreateSingleBankCtrl: ICoreControllerSharedPageModalManagementBase
) {
  const translate = useCoreControllerSharedLocalizationTranslate()

  const { onTableUpdated: actionOnUpdate, refreshTableData } =
    useOrganizationalStructureControllerAdminBankManageItemsFromTableGet<DataType>()

  const initialResource = useRef({
    data: null,
    params: {
      column_defs: [],
      filter_params: {
        state_match: '',
      },
      page: 1,
      per_page: 10,
      sort_info: {
        fields: ['created_at'],
        directions: ['desc'],
      },
    },
  })

  const openCreateSingleBank = () =>
    modalCreateSingleBankCtrl.toggleShowModalComponent(true)

  const openModalUpdateBankById = (scopeOfSupplyId: string) => async () => {
    const scopeOfSupplyByIdData = await getBankByIdCtrl.getData(scopeOfSupplyId)
    modalUpdateBankByIdCtrl.toggleShowModalComponent(true)
    modalUpdateBankByIdCtrl.setDataIntoModalComponent(scopeOfSupplyByIdData)
  }

  const utilityButtons = () => (
    <div className="d-flex" style={{ gap: '0.5rem' }}>
      <ButtonGhostWithSVGIcon
        svg={<IconPlus />}
        title={translate(ACTION_NEW_ENTRY)}
        onClick={openCreateSingleBank}
      />
    </div>
  )

  return useCoreControllerSharedPageDataTableManagementGetComponentPropsBase<DataType>(
    {
      initialResource: initialResource.current,
      columns: [
        {
          dataField: 'full_name',
          text: translate(LABEL_NAME),
        },
        { dataField: 'code', text: translate(LABEL_CODE) },
        {
          dataField: 'from_date',
          text: translate(LABEL_FROM_DATE),
          formatter: (value) =>
            value ? translate(FORMATTED_DATE_COMPLETE, { value }) : '-',
        },
        {
          dataField: 'thru_date',
          text: translate(LABEL_THRU_DATE),
          formatter: (value) =>
            value ? translate(FORMATTED_DATE_COMPLETE, { value }) : '-',
        },
        { dataField: 'swift_code', text: translate(LABEL_SWIFT_CODE) },
        { dataField: 'country_name', text: translate(LABEL_COUNTRY) },
        { dataField: 'other_country', text: translate(LABEL_OTHER_COUNTRY) },
        { dataField: 'bank_type_name', text: translate(LABEL_BANK_TYPE) },
        { dataField: 'district_name', text: translate(LABEL_DISTRICT) },
        { dataField: 'other_district', text: translate(LABEL_OTHER_DISTRICT) },
        { dataField: 'postal_address', text: translate(LABEL_POSTAL_ADDRESS) },
        /**
         * @todo add created by
         */
        {
          dataField: 'id',
          classes: 'medium-width sticky p-0 border-left',
          headerClasses: 'medium-width sticky',
          text: translate(LABEL_ACTION),
          searchable: false,
          formatter: (scopeOfSupplyId) => (
            <ButtonRowDataTable
              onClick={openModalUpdateBankById(scopeOfSupplyId)}
            >
              <img
                src={iconEdit}
                alt="Icon Edit Scope Of Supplies"
                style={{ marginRight: '1rem' }}
              />
              <span style={{ textDecoration: 'underline' }}>
                {translate(ACTION_EDIT)}
              </span>
            </ButtonRowDataTable>
          ),
        },
      ],
      actionOnUpdate,
      utilityButtons,
    },
    { refreshTableData }
  )
}
