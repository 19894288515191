import {
  DefaultOrganizationalStructureBankRequestBodyCreate,
  DefaultOrganizationalStructureBankResponseBodyEdit,
} from '@/resources'
import { ICoreControllerSharedPageFormManagementInitialValueBase } from '@procuraid-frontend/core'

export interface DefaultOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValueOptions<
  DataType extends
    DefaultOrganizationalStructureBankResponseBodyEdit = DefaultOrganizationalStructureBankResponseBodyEdit,
> {
  /**
   * @default CREATE
   */
  mode?: 'CREATE' | 'UPDATE'
  /**
   * @default null
   */
  data?: DataType | null
}

export function useOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValue<
  OutputType extends
    DefaultOrganizationalStructureBankRequestBodyCreate = DefaultOrganizationalStructureBankRequestBodyCreate,
  DataType extends
    DefaultOrganizationalStructureBankResponseBodyEdit = DefaultOrganizationalStructureBankResponseBodyEdit,
  OptionsType extends
    DefaultOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValueOptions<DataType> = DefaultOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValueOptions<DataType>,
>(): ICoreControllerSharedPageFormManagementInitialValueBase<
  OutputType,
  OptionsType
> {
  const initialValue = (options?: OptionsType) => {
    const mode = options?.mode ?? 'CREATE'
    const data = options?.data ?? null

    if (mode === 'CREATE' && data === null) {
      return {
        resource: 'bank',
        id: null,
        full_name: null,
        code: null,
        from_date: null,
        thru_date: null,
        swift_code: null,
        country: {
          id: null,
          region_type: null,
          parent_id: null,
          code: null,
          name: null,
        },
        other_country: null,
        district: {
          id: null,
          region_type: null,
          parent_id: null,
          code: null,
          name: null,
        },
        other_district: null,
        postal_address: null,
        bank_type: {
          id: null,
          name: null,
        },
      } as OutputType
    }
    return {
      ...data,
    } as unknown as OutputType
  }
  return {
    initialValue,
  }
}
