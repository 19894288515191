import { container as defaultContainer } from 'inversify-hooks-esm'
import {
  CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_LOCATION,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_NAVIGATOR,
  CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW,
  CORE_SERVICE_USER_BROWSER_BASE,
  CoreServiceJavaScriptGlobalDocumentObjectModelBase,
  CoreServiceJavaScriptGlobalLocation,
  CoreServiceJavaScriptGlobalNavigator,
  CoreServiceJavaScriptGlobalWindow,
  getCoreServiceUserBrowserBase,
} from '@procuraid-frontend/core'

export function getMainThreadSharedServices(container = defaultContainer) {
  // JAVASCRIPT_GLOBALS
  // JAVASCRIPT_GLOBALS - DOCUMENT_OBJECT_MODEL
  // JAVASCRIPT_GLOBALS - DOCUMENT_OBJECT_MODEL - BASE
  container
    .bind(CORE_SERVICE_JAVASCRIPT_GLOBAL_DOCUMENT_OBJECT_MODEL_BASE)
    .toConstantValue(CoreServiceJavaScriptGlobalDocumentObjectModelBase)

  // JAVASCRIPT_GLOBALS - LOCATION
  container
    .bind(CORE_SERVICE_JAVASCRIPT_GLOBAL_LOCATION)
    .toConstantValue(CoreServiceJavaScriptGlobalLocation)

  // JAVASCRIPT_GLOBALS - NAVIGATOR
  container
    .bind(CORE_SERVICE_JAVASCRIPT_GLOBAL_NAVIGATOR)
    .toConstantValue(CoreServiceJavaScriptGlobalNavigator)

  // JAVASCRIPT_GLOBALS - WINDOW
  container
    .bind(CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW)
    .toConstantValue(CoreServiceJavaScriptGlobalWindow)

  // USER_BROWSER
  // USER_BROWSER - BASE
  container
    .bind(CORE_SERVICE_USER_BROWSER_BASE)
    .toDynamicValue(() => getCoreServiceUserBrowserBase())
    .inSingletonScope()

  return container
}
