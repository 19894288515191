import { useContainerGet } from 'inversify-hooks-esm'
import {
  DefaultSimpleResponse,
  ICoreResourceOrganizationalStructureOnlyGet,
  useCoreControllerSharedManageItemsFromTableBase,
} from '@procuraid-frontend/core'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE,
  DefaultOrganizationalStructurePurchasingGroupResponseBodyGet,
} from '@/resources'

export function useOrganizationalStructureControllerAdminPurchasingGroupManageItemsFromTableGet<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyGet = DefaultOrganizationalStructurePurchasingGroupResponseBodyGet,
  ResponseType extends
    DefaultSimpleResponse<DataType> = DefaultSimpleResponse<DataType>,
>() {
  const purchOrgsResource = useContainerGet<
    ICoreResourceOrganizationalStructureOnlyGet<ResponseType>
  >(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE)

  return useCoreControllerSharedManageItemsFromTableBase<
    DataType,
    ResponseType
  >({
    handleTableUpdated: async (params) =>
      await purchOrgsResource.get({ params }),
  })
}
