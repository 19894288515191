import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewAdminGeneralConfigurationScopeOfSupplyListsContainer } from './lists'

export function getViewAdminGeneralConfigurationScopeOfSupplyContainer(
  container = defaultContainer
) {
  return compose(getViewAdminGeneralConfigurationScopeOfSupplyListsContainer)(
    container
  )
}
