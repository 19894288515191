import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_GET_RESOURCE_DATA_BY_ID,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_UPDATE,
  useOrganizationalStructureControllerAdminPlantGetResourceDataById,
  useOrganizationalStructureControllerAdminPlantModalManagementCreate,
  useOrganizationalStructureControllerAdminPlantModalManagementUpdate,
} from '@procuraid-frontend/organizational-structure'
import { CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PLANT_BASE } from '@procuraid-frontend/core'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  useOrganizationalStructureControllerAdminPlantModalManagementCreateFromSpreadsheetFile,
} from '@/controllers/admin/organizational-structure/plant/modal-management'

export function getViewAdminOrganizationalStructurePlantListsContainer(
  container = defaultContainer
) {
  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPlantModalManagementCreate()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_UPDATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPlantModalManagementUpdate()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPlantModalManagementCreateFromSpreadsheetFile()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_GET_RESOURCE_DATA_BY_ID
    )
    .toDynamicValue(({ container: c }) =>
      useOrganizationalStructureControllerAdminPlantGetResourceDataById(
        c.get(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PLANT_BASE)
      )
    )

  return container
}
