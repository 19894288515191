import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_SERVICE_EXPORTER_XLS,
  ICoreControllerSharedPageGetCalculatedResourceBase,
  ICoreServiceExporterXLS,
} from '@procuraid-frontend/core'

export function useOrganizationalStructureControllerAdminPurchasingGroupGetCalculatedResourceSpreadsheetURLReference(): ICoreControllerSharedPageGetCalculatedResourceBase<
  unknown,
  string
> {
  const exporterXLSService = useContainerGet<ICoreServiceExporterXLS>(
    CORE_SERVICE_EXPORTER_XLS
  )

  const getCalculatedResource = () =>
    exporterXLSService.exportToXlsByPath('templates/PurchasingGroup.xlsx')

  return {
    getCalculatedResource,
  }
}
