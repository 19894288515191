import {
  ICoreServiceRemoteBase,
  useCoreResourceOrganizationalStructureBase,
} from '@procuraid-frontend/core'

export const CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_SCOPE_OF_SUPPLIES_BASE =
  'CoreResourceOrganizationalStructureScopeOfSuppliesBase'

/**
 * @description playground buat resource terkait purchasing group.
 * referensi:
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */
export function useCoreResourceOrganizationalStructureScopeOfSuppliesBase(
  remoteService: ICoreServiceRemoteBase
) {
  return useCoreResourceOrganizationalStructureBase({
    pathname: '/prcmt/scope_of_supplies',
    remoteService,
  })
}
