/* eslint-disable @typescript-eslint/no-explicit-any */
import { useContainerGet } from 'inversify-hooks-esm'

import {
  DefaultOrganizationalStructurePlantResponseBodyEdit,
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'
import {
  IOrganizationalStructureControllerAdminPlantGetResourceDataById,
  IOrganizationalStructureControllerAdminPlantModalManagementUpdate,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_GET_RESOURCE_DATA_BY_ID,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE,
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_UPDATE,
} from '@procuraid-frontend/organizational-structure'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  useOrganizationalStructureControllerAdminPlantDataTableManagementGetComponentPropsBase,
} from '@/controllers'

export interface OrganizationalStructureViewPageAdminPlantListsBaseProps
  extends PropsWithInnerRef {}

const OrganizationalStructureViewPageAdminPlantListsBase = <
  PropType extends
    OrganizationalStructureViewPageAdminPlantListsBaseProps = OrganizationalStructureViewPageAdminPlantListsBaseProps,
  PlantByIdType extends
    DefaultOrganizationalStructurePlantResponseBodyEdit = DefaultOrganizationalStructurePlantResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getPlantByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPlantGetResourceDataById<PlantByIdType>
  >(ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_GET_RESOURCE_DATA_BY_ID)
  const modalUpdatePlantByIdCtrl = useContainerGet<
    IOrganizationalStructureControllerAdminPlantModalManagementUpdate<PlantByIdType>
  >(ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_UPDATE)
  const modalCreateSinglePlantCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE
    )
  const modalCreatePlantListsFromSpreadsheetFileCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PLANT_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )

  const { getComponentProps, refreshTableData } =
    useOrganizationalStructureControllerAdminPlantDataTableManagementGetComponentPropsBase<PlantByIdType>(
      getPlantByIdCtrl,
      modalUpdatePlantByIdCtrl,
      modalCreateSinglePlantCtrl,
      modalCreatePlantListsFromSpreadsheetFileCtrl
    )

  const handleHide = () => refreshTableData?.()

  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSinglePlantCtrl.ModalComponent onHide={handleHide} />
      <modalUpdatePlantByIdCtrl.ModalComponent onHide={handleHide} />
      <modalCreatePlantListsFromSpreadsheetFileCtrl.ModalComponent
        onHide={handleHide}
      />
    </>
  )
}

export const OrganizationalStructureViewPageAdminPlantLists = addRefProps(
  OrganizationalStructureViewPageAdminPlantListsBase
)
