/* eslint-disable react-refresh/only-export-components */
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'

import { ICoreRouterComponent } from '@/routers'

const CoreViewPageHome = loadable(async () => {
  const { CoreViewPageHome: C } = await import('@procuraid-frontend/core')
  return C
})

const CoreViewPageWithTabs = loadable(async () => {
  const { CoreViewPageWithTabs: C } = await import('@procuraid-frontend/core')
  return C
})

export const CORE_ROUTER_PRIVATE_VENDOR_BASE = 'CoreRouterPrivateVendorBase'

export function useDemoRouterPrivateVendorBase(): ICoreRouterComponent {
  const getRouter = () => {
    return (
      <Route path="vendor" element={<CoreViewPageHome />}>
        <Route path="home" element={<h2>Home</h2>} />
        <Route path="vendor_management" element={<CoreViewPageWithTabs />}>
          <Route path="vendors" element={<h1>Vendors</h1>} />
          <Route path="vendors/:vendor_id" element={<h1>Vendors with ID</h1>} />
        </Route>
        <Route path="purchase_requisition" element={<CoreViewPageWithTabs />}>
          <Route path="purchase_requisitions" element={<h1>PR Headers</h1>} />
          <Route
            path="purchase_requisitions/:purch_reqn_id"
            element={<h1>PR Header with ID</h1>}
          />
        </Route>
      </Route>
    )
  }

  return {
    getRouter,
  }
}
