/* eslint-disable @typescript-eslint/no-explicit-any */
import styled from 'styled-components'

import {
  ANTDatePicker,
  ButtonFormSubmit,
  ButtonOutline,
  CORE_SERVICE_LOOKUP_BASE,
  ICoreServiceLookupBase,
  InputAutoCompleteAsyncSingle,
  InputAutoCompleteSingle,
  InputFormControl,
  LabelForm,
  Modal,
  ModalBaseProps,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedTransformToDropdownOptionsBase,
} from '@procuraid-frontend/core'
import { Form, Formik, FormikHelpers } from 'formik'

import { DefaultOrganizationalStructureBankRequestBodyCreate } from '@/resources'
import {
  ACTION_ADD_RESOURCE,
  ACTION_CANCEL,
  ACTION_SUBMIT,
  LABEL_BANK_TYPE,
  LABEL_CODE,
  LABEL_COUNTRY,
  LABEL_DISTRICT,
  LABEL_FROM_DATE,
  LABEL_NAME,
  LABEL_OTHER_COUNTRY,
  LABEL_OTHER_DISTRICT,
  LABEL_POSTAL_ADDRESS,
  LABEL_SCOPE_OF_SUPPLY,
  LABEL_SWIFT_CODE,
  LABEL_THRU_DATE,
} from '@procuraid-frontend/localization'

import { useCallback, useEffect } from 'react'
import {
  useControllerAdminOrganizationStructureBankDropdownManagementDefaultValueCreateOrUpdate,
  useControllerAdminOrganizationStructureBankFormManagementValidator,
  useControllerAdminOrganizationalStructureBankDropdownManagementOptionsCreateOrUpdate,
  useOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateHandleSubmit,
  useOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValue,
} from '@/controllers'
import { useContainerGet } from 'inversify-hooks-esm'

export interface OrganizationalStructureViewOrganismModalBankCreateBaseProps
  extends ModalBaseProps {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 24vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        justify-content: flex-end;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const OrganizationalStructureViewOrganismModalBankCreateBase = <
  PropType extends
    OrganizationalStructureViewOrganismModalBankCreateBaseProps = OrganizationalStructureViewOrganismModalBankCreateBaseProps,
  PayloadType extends
    DefaultOrganizationalStructureBankRequestBodyCreate = DefaultOrganizationalStructureBankRequestBodyCreate,
>({
  innerRef,
  onHide,
  ...otherProps
}: PropType) => {
  const translate = useCoreControllerSharedLocalizationTranslate()

  const { transformDataToDropdownOptions } =
    useCoreControllerSharedTransformToDropdownOptionsBase()

  const lookupBaseService = useContainerGet<ICoreServiceLookupBase>(
    CORE_SERVICE_LOOKUP_BASE
  )

  const lookupBankType =
    lookupBaseService.getValue<string[]>('bank_types') ?? []

  const bankTypeOptions = transformDataToDropdownOptions(
    lookupBankType,
    (name) => translate(name)
  )

  const {
    options: dropdownOptions,
    loadings: dropdownOptionsLoadings,
    loadOptions: loadDropdownOptions,
    regionResourceSearch: regionResourceSearch,
  } = useControllerAdminOrganizationalStructureBankDropdownManagementOptionsCreateOrUpdate()

  const {
    defaultValue: defaultDropdownValues,
    getDefaultValue: getDefaultDropdownValues,
  } =
    useControllerAdminOrganizationStructureBankDropdownManagementDefaultValueCreateOrUpdate()

  const { handleSubmit: handleCreateBank } =
    useOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateHandleSubmit<PayloadType>()

  const { initialValue: getInitialValue } =
    useOrganizationalStructureControllerAdminBankFormManagementCreateOrUpdateInitialValue<PayloadType>()

  const handleSubmit = async (
    payload: PayloadType,
    helpers: FormikHelpers<PayloadType>
  ) => {
    try {
      await handleCreateBank(payload, helpers)
    } finally {
      onHide?.()
    }
  }

  const { validator: getValidator } =
    useControllerAdminOrganizationStructureBankFormManagementValidator()

  const loadOptions = useCallback(async () => {
    await Promise.all([loadDropdownOptions(), getDefaultDropdownValues()])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    loadOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  console.log('bankTypeOptions: ', bankTypeOptions, defaultDropdownValues)

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValue()}
      validationSchema={getValidator()}
      onSubmit={handleSubmit}
    >
      {() => {
        return (
          <Form>
            <ModalComponent
              ref={innerRef}
              centered
              onHide={onHide}
              {...otherProps}
            >
              <ModalHeader closeButton={false} onHide={onHide}>
                <ModalTitle>
                  {translate(ACTION_ADD_RESOURCE, {
                    resource: translate(LABEL_SCOPE_OF_SUPPLY),
                  })}
                </ModalTitle>
              </ModalHeader>
              <ModalBody>
                <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
                  <LabelForm
                    label={translate(LABEL_NAME)}
                    htmlFor="full_name"
                    className="mb-0"
                    required={true}
                  />
                  <InputFormControl id="full_name" name="full_name" />
                  <LabelForm
                    label={translate(LABEL_CODE)}
                    htmlFor="code"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="code" name="code" />
                  <LabelForm
                    label={translate(LABEL_FROM_DATE)}
                    htmlFor="from_date"
                    className="mb-0"
                    required={true}
                  />
                  <ANTDatePicker
                    id="from_date"
                    name="from_date"
                    showTime={false}
                    formatValue="PPP"
                  />
                  <LabelForm
                    label={translate(LABEL_THRU_DATE)}
                    htmlFor="thru_date"
                    className="mb-0"
                    required={false}
                  />
                  <ANTDatePicker
                    id="thru_date"
                    name="thru_date"
                    showTime={false}
                    formatValue="PPP"
                  />
                  <LabelForm
                    label={translate(LABEL_SWIFT_CODE)}
                    htmlFor="swift_code"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="swift_code" name="swift_code" />
                  <LabelForm
                    label={translate(LABEL_COUNTRY)}
                    htmlFor="country"
                    className="mb-0"
                    required={false}
                  />
                  <InputAutoCompleteAsyncSingle
                    name="country"
                    resource={regionResourceSearch.search}
                    resourceAdditionalParams={{
                      region_type: 'COUNTRY',
                    }}
                    isLoading={dropdownOptionsLoadings.country}
                    isDisabled={dropdownOptionsLoadings.country}
                    defaultOptions={dropdownOptions.country}
                    optionsFilter={(value) => {
                      console.log('options filter: ', value)
                      return {
                        value,
                        // label: `${value?.['code']} - ${value?.['name']}`,
                        label: value?.code
                          ? `${value?.['code']} - ${value?.['name']}`
                          : 'Pilih Country',
                      }
                    }}
                    placeholder="Pilih Country"
                  />
                  <LabelForm
                    label={translate(LABEL_OTHER_COUNTRY)}
                    htmlFor="other_country"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="other_country" name="other_country" />
                  <LabelForm
                    label={translate(LABEL_BANK_TYPE)}
                    htmlFor="bank_type"
                    className="mb-0"
                    required={false}
                  />
                  <InputAutoCompleteSingle
                    name="bank_type"
                    options={bankTypeOptions}
                    placeholder={translate(LABEL_BANK_TYPE)}
                  />
                  <LabelForm
                    label={translate(LABEL_DISTRICT)}
                    htmlFor="district"
                    className="mb-0"
                    required={false}
                  />
                  <InputAutoCompleteAsyncSingle
                    name="district"
                    resource={regionResourceSearch.search}
                    resourceAdditionalParams={{
                      region_type: 'DISTRICT',
                    }}
                    isLoading={dropdownOptionsLoadings.district}
                    isDisabled={dropdownOptionsLoadings.district}
                    defaultOptions={dropdownOptions.district}
                    optionsFilter={(value) => ({
                      value,
                      label: `${value['code']} - ${value['name']}`,
                    })}
                    placeholder="Pilih District"
                  />
                  <LabelForm
                    label={translate(LABEL_OTHER_DISTRICT)}
                    htmlFor="other_district"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="other_district" name="other_district" />
                  <LabelForm
                    label={translate(LABEL_POSTAL_ADDRESS)}
                    htmlFor="postal_address"
                    className="mb-0"
                    required={false}
                  />
                  <InputFormControl id="postal_address" name="postal_address" />
                </div>
              </ModalBody>
              <ModalFooter>
                <ButtonOutline
                  onClick={onHide}
                  className="btn-actions"
                  title={translate(ACTION_CANCEL)}
                />
                <ButtonFormSubmit compact size="sm">
                  {translate(ACTION_SUBMIT)}
                </ButtonFormSubmit>
              </ModalFooter>
            </ModalComponent>
          </Form>
        )
      }}
    </Formik>
  )
}

export const OrganizationalStructureViewOrganismModalBankCreate = addRefProps(
  OrganizationalStructureViewOrganismModalBankCreateBase
)
