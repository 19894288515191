import { ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsUserAvatarFallback } from '@procuraid-frontend/core'

import iconUserAvatarPNG1x from '@/assets/images/icon-user-avatar.png'
import iconUserAvatarPNG2x from '@/assets/images/icon-user-avatar@2x.png'
import iconUserAvatarPNG3x from '@/assets/images/icon-user-avatar@3x.png'
import iconUserAvatarWEBP1x from '@/assets/images/icon-user-avatar.webp'
import iconUserAvatarWEBP2x from '@/assets/images/icon-user-avatar@2x.webp'
import iconUserAvatarWEBP3x from '@/assets/images/icon-user-avatar@3x.webp'

export const CoreServiceThirdPartyIntegratorReactContextCustomerAssetsUserAvatarFallback: ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsUserAvatarFallback =
  {
    webp: {
      '1x': iconUserAvatarWEBP1x,
      '2x': iconUserAvatarWEBP2x,
      '3x': iconUserAvatarWEBP3x,
    },
    png: {
      '1x': iconUserAvatarPNG1x,
      '2x': iconUserAvatarPNG2x,
      '3x': iconUserAvatarPNG3x,
    },
  }
