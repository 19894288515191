import { object, array } from 'yup'

import {
  DefaultSpreadsheetResponseBodyImport,
  ICoreControllerSharedPageFormManagementValidatorBase,
} from '@procuraid-frontend/core'

export interface DefaultCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidatorOptions {
  /**
   * @default spreadsheetData
   */
  attributeForSpreadsheetData?: string
}

export function useCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidator<
  OptionsType extends
    DefaultCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidatorOptions = DefaultCoreControllerSharedSpreadsheetManagementFormManagementRevalidateValidatorOptions,
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
>(): ICoreControllerSharedPageFormManagementValidatorBase<OptionsType> {
  const validator = (options?: OptionsType) => {
    const attributeForSpreadsheetData =
      options?.attributeForSpreadsheetData ?? 'spreadsheetData'

    return object({
      [attributeForSpreadsheetData]: object<DataType['data']['errors']>({
        data: object({
          errors: array().length(0).required().defined(),
          rows: array().of(object()).min(1).required().defined(),
        })
          .required()
          .defined(),
      })
        .required()
        .defined(),
    })
      .required()
      .defined()
  }
  return {
    validator,
  }
}
