import { ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue } from '@procuraid-frontend/core'

import imgLogo from '@/assets/images/procura-logo.png'
import backgroundCoverAvif1x from '@/assets/images/bg-login.avif'
import backgroundCoverAvif2x from '@/assets/images/bg-login@2x.avif'
import backgroundCoverAvif3x from '@/assets/images/bg-login@3x.avif'
import backgroundCoverWebp1x from '@/assets/images/bg-login.webp'
import backgroundCoverWebp2x from '@/assets/images/bg-login@2x.webp'
import backgroundCoverWebp3x from '@/assets/images/bg-login@3x.webp'
import backgroundCoverPng1x from '@/assets/images/bg-login.png'
import backgroundCoverPng2x from '@/assets/images/bg-login@2x.png'
import backgroundCoverPng3x from '@/assets/images/bg-login@3x.png'

export const CoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue: ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsValue =
  {
    backgroundCover: {
      png: {
        '1x': backgroundCoverPng1x,
        '2x': backgroundCoverPng2x,
        '3x': backgroundCoverPng3x,
      },
      webp: {
        '1x': backgroundCoverWebp1x,
        '2x': backgroundCoverWebp2x,
        '3x': backgroundCoverWebp3x,
      },
      avif: {
        '1x': backgroundCoverAvif1x,
        '2x': backgroundCoverAvif2x,
        '3x': backgroundCoverAvif3x,
      },
    },
    logo: {
      src: imgLogo,
      alt: 'logo customer',
    },
  }
