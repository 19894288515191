import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'
import { getViewAdminOrganizationalStructurePlantListsContainer } from './lists'

export function getViewAdminOrganizationalStructurePlantContainer(
  container = defaultContainer
) {
  return compose(getViewAdminOrganizationalStructurePlantListsContainer)(
    container
  )
}
