import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewAdminOrganizationalStructureContainer } from './organizational-structure'
import { getViewAdminGeneralConfigurationContainer } from './general-configuration'

export function getViewAdminContainer(container = defaultContainer) {
  return compose(
    getViewAdminGeneralConfigurationContainer,
    getViewAdminOrganizationalStructureContainer
  )(container)
}
