import { useContainerGet } from 'inversify-hooks-esm'

import {
  DefaultGeneralConfigurationRegionResponseBodyEdit,
  ICoreControllerSharedPageModalManagementBase,
  PropsWithInnerRef,
  ViewCoreSharedTemplateWithDataTable,
  addRefProps,
} from '@procuraid-frontend/core'
import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_UPDATE,
  IGeneralConfigurationControllerAdminRegionGetResourceDataById,
  IGeneralConfigurationControllerAdminRegionModalManagementUpdate,
  useGeneralConfigurationControllerAdminRegionDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/general-configuration'

export interface GeneralConfigurationViewPageAdminRegionListsBaseProps
  extends PropsWithInnerRef {}

const GeneralConfigurationViewPageAdminRegionListsBase = <
  PropType extends
    GeneralConfigurationViewPageAdminRegionListsBaseProps = GeneralConfigurationViewPageAdminRegionListsBaseProps,
  RegionByIdType extends
    DefaultGeneralConfigurationRegionResponseBodyEdit = DefaultGeneralConfigurationRegionResponseBodyEdit,
>({
  innerRef,
}: PropType) => {
  const getRegionByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminRegionGetResourceDataById<RegionByIdType>
  >(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_GET_RESOURCE_DATA_BY_ID)
  const modalUpdateRegionByIdCtrl = useContainerGet<
    IGeneralConfigurationControllerAdminRegionModalManagementUpdate<RegionByIdType>
  >(GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_UPDATE)
  const modalCreateSingleRegionCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE
    )
  const modalCreateRegionListsFromSpreadsheetFileCtrl =
    useContainerGet<ICoreControllerSharedPageModalManagementBase>(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_REGION_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )
  const { getComponentProps, refreshTableData } =
    useGeneralConfigurationControllerAdminRegionDataTableManagementGetComponentPropsBase<RegionByIdType>(
      getRegionByIdCtrl,
      modalUpdateRegionByIdCtrl,
      modalCreateSingleRegionCtrl,
      modalCreateRegionListsFromSpreadsheetFileCtrl
    )
  const handleHide = () => refreshTableData?.()
  return (
    <>
      <ViewCoreSharedTemplateWithDataTable
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        {...(getComponentProps({ innerRef }) as any)}
      />
      <modalCreateSingleRegionCtrl.ModalComponent onHide={handleHide} />
      <modalUpdateRegionByIdCtrl.ModalComponent onHide={handleHide} />
      <modalCreateRegionListsFromSpreadsheetFileCtrl.ModalComponent
        onHide={handleHide}
      />
    </>
  )
}

export const GeneralConfigurationViewPageAdminRegionLists = addRefProps(
  GeneralConfigurationViewPageAdminRegionListsBase
)
