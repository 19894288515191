import { Reducer, ReducersMapObject, combineReducers } from '@reduxjs/toolkit'
import { persistReducer } from 'redux-persist'

import {
  ICoreServiceStateManagementInstanceAppBase,
  ICoreServiceStateManagementInstanceServerTimeBase,
  ICoreServiceStateManagementInstanceUserBase,
  ICoreServiceThirdPartyIntegratorReduxPersistConfiguration,
  ICoreServiceThirdPartyIntegratorReduxReducerBase,
} from '@procuraid-frontend/core'

/**
 * @description
 * @author Achmad Kurnianto
 * @date 8th February 2023
 * @export
 * @template AppState
 * @template OtherReducerType
 * @param {ICoreServiceThirdPartyIntegratorReduxPersistConfiguration<AppState>} reduxPersistConfig
 * @returns {*}  {ICoreServiceThirdPartyIntegratorReduxReducerBase<AppState, OtherReducerType>}
 */
export function getCoreServiceThirdPartyIntegratorReduxReducerBase<
  AppState = unknown,
  OtherReducerType extends
    ReducersMapObject<AppState> = ReducersMapObject<AppState>,
>(
  appInstance: ICoreServiceStateManagementInstanceAppBase,
  serverTimeInstance: ICoreServiceStateManagementInstanceServerTimeBase,
  userInstance: ICoreServiceStateManagementInstanceUserBase,
  reduxPersistConfig: ICoreServiceThirdPartyIntegratorReduxPersistConfiguration<AppState>
): ICoreServiceThirdPartyIntegratorReduxReducerBase<
  AppState,
  OtherReducerType
> {
  const setReducer = (otherReducer = {} as OtherReducerType) =>
    persistReducer(
      reduxPersistConfig.getConfiguration(),
      combineReducers({
        user: userInstance.reducer(),
        app: appInstance.reducer(),
        serverTime: serverTimeInstance.reducer(),
        ...otherReducer,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      }) as any
    ) as Reducer<AppState>

  return {
    setReducer,
  }
}
