/* eslint-disable react-refresh/only-export-components */
import loadable from '@loadable/component'
import { useContainerGet } from 'inversify-hooks-esm'

import { Route, Routes } from 'react-router-dom'

import { CORE_ROUTER_PUBLIC_BASE } from './public'
import {
  CORE_ROUTER_PRIVATE_ADMIN_BASE,
  CORE_ROUTER_PRIVATE_VENDOR_BASE,
} from './private'

import { ICoreRouterComponent } from './types'

const CoreViewPageRootPrivate = loadable(async () => {
  const { CoreViewPageRootPrivate: C } = await import(
    '@procuraid-frontend/core'
  )
  return C
})

export const CORE_ROUTER_BASE = 'CoreRouterBase'

export const DemoRouterBase = () => {
  const privateAdminRoutes = useContainerGet<ICoreRouterComponent>(
    CORE_ROUTER_PRIVATE_ADMIN_BASE
  )
  const privateVendorRoutes = useContainerGet<ICoreRouterComponent>(
    CORE_ROUTER_PRIVATE_VENDOR_BASE
  )

  const publicRoutes = useContainerGet<ICoreRouterComponent>(
    CORE_ROUTER_PUBLIC_BASE
  )

  return (
    <Routes>
      <Route path="/" element={<CoreViewPageRootPrivate />}>
        {/** <-- [START] ADMIN PATHS --> */}
        {privateAdminRoutes.getRouter()}
        {/** <-- [END] ADMIN PATHS --> */}

        {/** <-- [START] VENDOR PATHS --> */}
        {privateVendorRoutes.getRouter()}
        {/** <-- [END] VENDOR PATHS --> */}
      </Route>
      {/** <-- [START] PUBLIC PATHS --> */}
      {publicRoutes.getRouter()}
      {/** <-- [END] PUBLIC PATHS --> */}
    </Routes>
  )
}

export function useDemoRouterComponent(): ICoreRouterComponent {
  const getRouter = () => <DemoRouterBase />

  return {
    getRouter,
  }
}
