import { InputHTMLAttributes } from 'react'
import styled from 'styled-components'
import clsx from 'clsx'

import {
  InputFormControl,
  PropsWithInnerRef,
  addRefProps,
  greyMed,
} from '@procuraid-frontend/core'

export interface DefaultViewItemType<ValueType = string> {
  /**
   * @description Nama atribut/properti dalam bahasa Indonesia/Inggris
   * @author Achmad Kurnianto
   * @date 15/01/2024
   * @type {string}
   * @memberof DefaultViewItemType
   */
  title: string

  /**
   * @description Nilai atribut/properti dari backend
   * @author Achmad Kurnianto
   * @date 15/01/2024
   * @type {ValueType}
   * @memberof DefaultViewItemType
   */
  value: ValueType
}

export interface DefaultViewItemEditableType<ValueType = string>
  extends DefaultViewItemType<ValueType> {
  /**
   * @description Nilai prefix dari value ybs (jika ada)
   * @default null
   *
   * @type {string}
   * @memberof DefaultViewItemEditableType
   * @author Achmad Kurnianto
   * @since 7th of May 2021
   */
  prefix?: string | null

  /**
   * @description Nilai suffix dari value ybs (jika ada)
   * @default null
   *
   * @type {string}
   * @memberof DefaultViewItemEditableType
   * @author Achmad Kurnianto
   * @since 15th of January 2024
   */
  suffix?: string | null

  /**
   * @description Apakah properti/atribut ini dapat diedit. Jika ya, nanti berubah tampilan
   * menjadi form
   * @default false
   *
   * @type {boolean}
   * @memberof DefaultViewItemEditableType
   * @author Achmad Kurnianto
   * @since 15th of January 2024
   */
  editable?: boolean

  /**
   * nama atribut/properti dari backend
   *
   * @type {string}
   * @memberof DefaultViewItemEditableType
   * @author Achmad Kurnianto
   * @since 15th of January 2024
   */
  property: string

  /**
   * @description tipe input yang ditampilkan jika atribut/properti ini `editable`
   * @default "text"
   *
   * @type {InputHTMLAttributes<HTMLInputElement>['type']}
   * @memberof DefaultViewItemEditableType
   * @author Achmad Kurnianto
   * @since 6th of May 2021
   */
  inputType: InputHTMLAttributes<HTMLInputElement>['type']
}

export interface CoreViewMoleculeListEditableBaseProps<
  DataType extends DefaultViewItemEditableType = DefaultViewItemEditableType,
> extends PropsWithInnerRef {
  data: DataType

  /**
   * @default false
   */
  editMode?: boolean
}

const Container = styled.div`
  &.row {
    width: 100%;
  }
  &.item-detail {
    margin-bottom: 1rem;
    align-items: center;
  }
  & > .item-title {
    font-size: 12px;
    color: #424242;
  }
  & > .item-value {
    display: flex;
    justify-content: flex-start;
    font-size: 12px;
    color: ${(props) => props.theme?.colors?.greyMed ?? greyMed};

    .prefix-area,
    .suffix-area {
      width: 5rem;
      align-self: center;
      text-align: center;
    }
  }
  & > .value-area {
    margin-right: 0.625rem;
  }
`

const CoreViewMoleculeListEditableBase = <
  DataType extends DefaultViewItemEditableType = DefaultViewItemEditableType,
  PropType extends
    CoreViewMoleculeListEditableBaseProps<DataType> = CoreViewMoleculeListEditableBaseProps<DataType>,
>({
  innerRef,
  data,
  editMode = false,
}: PropType) => {
  const {
    editable = false,
    prefix = null,
    suffix = null,
    title,
    inputType = 'text',
    property,
    value,
  } = data
  const isModeWrite = editMode && editable

  return (
    <Container ref={innerRef} className="row item-detail">
      <div className="col-md-6 item-title">{title}</div>
      <div className="col-md-6 item-value">
        {isModeWrite && prefix !== null && (
          <div className={clsx({ 'prefix-area': isModeWrite })}>{prefix}</div>
        )}
        <div className="value-area">
          {isModeWrite && (
            <InputFormControl
              name={property}
              placeholder={value}
              type={inputType}
            />
          )}
          {!isModeWrite && value}
        </div>
        {isModeWrite && suffix !== null && (
          <div className={clsx({ 'suffix-area': isModeWrite })}>{suffix}</div>
        )}
      </div>
    </Container>
  )
}

export const CoreViewMoleculeListEditable = addRefProps(
  CoreViewMoleculeListEditableBase
)
