import {
  ICoreControllerSharedPageResourceNavigationBase,
  useCoreControllerSharedResourceNavigationDownloadFile,
} from '@procuraid-frontend/core'

import { useOrganizationalStructureControllerAdminPlantGetCalculatedResourceSpreadsheetURLReference } from '@/controllers'

export function useOrganizationalStructureControllerAdminPlantResourceNavigationDownloadSpreadsheetFileReference(): ICoreControllerSharedPageResourceNavigationBase {
  const { getCalculatedResource: getURL } =
    useOrganizationalStructureControllerAdminPlantGetCalculatedResourceSpreadsheetURLReference()
  const { downloadFile } =
    useCoreControllerSharedResourceNavigationDownloadFile()

  const next = () => {
    const url = getURL()
    downloadFile({ url, openNewTab: true })
  }

  return {
    next,
  }
}
