import { useCallback } from 'react'
import { date, object, string } from 'yup'

import {
  ICoreControllerSharedPageFormManagementValidatorBase,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'
import { LABEL_FROM_DATE } from '@procuraid-frontend/localization'

export function useControllerAdminOrganizationStructureBankFormManagementValidator(): ICoreControllerSharedPageFormManagementValidatorBase {
  const translate = useCoreControllerSharedLocalizationTranslate()
  const validator = useCallback(() => {
    return object({
      full_name: string().required().defined(),
      from_date: date().label(translate(LABEL_FROM_DATE)).required().defined(),
    })
      .required()
      .defined()
  }, [translate])

  return {
    validator,
  }
}
