import { PersistConfig } from 'redux-persist'
import createIdbStorage from '@piotr-cz/redux-persist-idb-storage'

import {
  ICoreServiceAppConfigurationBase,
  ICoreServiceThirdPartyIntegratorReduxPersistConfiguration,
} from '@procuraid-frontend/core'

export function getServiceCoreSharedThirdPartyIntegratorReduxPersistConfiguration<
  AppState = unknown,
>(
  appConfig: ICoreServiceAppConfigurationBase
): ICoreServiceThirdPartyIntegratorReduxPersistConfiguration<AppState> {
  const getConfiguration = (
    customConfig?: Partial<PersistConfig<AppState>>
  ) => {
    return {
      key: appConfig.appName,
      storage: createIdbStorage(),
      blacklist: ['router', 'timeServer', 'purchaseRequisition', 'procurement'],
      serialize: true,
      debug: import.meta.env.VITE_APP_ENV === 'development',
      ...(customConfig ?? {}),
    }
  }

  return {
    getConfiguration,
  }
}
