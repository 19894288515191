import { createRoot } from 'react-dom/client'

import {
  ICoreControllerSharedAppInitializerBase,
  ICoreServiceJavaScriptGlobalDocumentObjectModelBase,
  ICoreServiceSWRegistrationBase,
} from '@procuraid-frontend/core'

import { ComponentProvider } from '@/component-providers/Base'

export function getCoreControllerSharedAppInitializerBase(
  document: ICoreServiceJavaScriptGlobalDocumentObjectModelBase,
  swRegistrationService: ICoreServiceSWRegistrationBase
): ICoreControllerSharedAppInitializerBase {
  const init = () => {
    const root = createRoot(document.getElementById('root') as HTMLElement)
    root.render(<ComponentProvider />)
    swRegistrationService.register()
  }
  return {
    init,
  }
}
