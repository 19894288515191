import { container as defaultContainer } from 'inversify-hooks-esm'

import { CORE_RESOURCE_GENERAL_CONFIGURATION_SCOPE_OF_SUPPLY_BASE } from '@procuraid-frontend/core'

import {
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_GET_RESOURCE_DATA_BY_ID,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_CREATE,
  GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_UPDATE,
  useGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById,
  // useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementCreate,
  useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate,
} from '@procuraid-frontend/general-configuration'

import { useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementCreate } from '@/controllers/admin/general-configuration'

export function getViewAdminGeneralConfigurationScopeOfSupplyListsContainer(
  container = defaultContainer
) {
  container
    .bind(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_CREATE
    )
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementCreate()
    )

  container
    .bind(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_UPDATE
    )
    .toDynamicValue(() =>
      useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate()
    )

  container
    .bind(
      GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_GET_RESOURCE_DATA_BY_ID
    )
    .toDynamicValue(({ container: c }) =>
      useGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById(
        c.get(CORE_RESOURCE_GENERAL_CONFIGURATION_SCOPE_OF_SUPPLY_BASE)
      )
    )
  return container
}
