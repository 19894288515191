import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'
import { getViewAdminOrganizationalStructurePurchasingOrganizationListsContainer } from './lists'

export function getViewAdminOrganizationalStructurePurchasingOrganizationContainer(
  container = defaultContainer
) {
  return compose(
    getViewAdminOrganizationalStructurePurchasingOrganizationListsContainer
  )(container)
}
