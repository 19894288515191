import { useCoreControllerSharedModalManagementBase } from '@procuraid-frontend/core'

import { ViewOrganizationalStructureAdminOrganismModalPurchasingGroupCreateFromSpreadsheetFile } from '@/views'

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE =
  'OrganizationalStructureControllerAdminPurchasingGroupModalManagementCreateFromSpreadsheetFile'

export function useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreateFromSpreadsheetFile() {
  return useCoreControllerSharedModalManagementBase({
    component:
      ViewOrganizationalStructureAdminOrganismModalPurchasingGroupCreateFromSpreadsheetFile,
  })
}
