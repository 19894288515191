import {
  ICoreServiceRemoteBase,
  useCoreResourceOrganizationalStructureBase,
} from '@procuraid-frontend/core'

export const CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_REGION_BASE =
  'CoreResourceOrganizationalStructureRegionBase'

/**
 * @description playground buat resource terkait Region.
 * referensi:
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */
export function useCoreResourceOrganizationalStructureRegionBase(
  remoteService: ICoreServiceRemoteBase
) {
  return useCoreResourceOrganizationalStructureBase({
    pathname: '/regions',
    remoteService,
  })
}
