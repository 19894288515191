/* eslint-disable react-refresh/only-export-components */
import { Route } from 'react-router-dom'
import loadable from '@loadable/component'

import { ICoreRouterComponent } from '../types'

const CoreViewPageLogin = loadable(async () => {
  const { CoreViewPageLogin: C } = await import('@procuraid-frontend/core')
  return C
})
const CoreViewPageError5xx = loadable(async () => {
  const { CoreViewPageError5xx: C } = await import('@procuraid-frontend/core')
  return C
})
const CoreViewPageErrorNotFound = loadable(async () => {
  const { CoreViewPageErrorNotFound: C } = await import(
    '@procuraid-frontend/core'
  )
  return C
})

export const CORE_ROUTER_PUBLIC_BASE = 'CoreRouterPublicBase'

export function useDemoRouterPublicDocument(): ICoreRouterComponent {
  return {
    getRouter() {
      return (
        <>
          <Route path="login" element={<CoreViewPageLogin />}></Route>
          <Route path="500" element={<CoreViewPageError5xx />}></Route>
          <Route path="*" element={<CoreViewPageErrorNotFound />}></Route>
        </>
      )
    },
  }
}
