import { useRef } from 'react'
import { useContainerGet } from 'inversify-hooks-esm'

import {
  ButtonGhostWithSVGIcon,
  ButtonRow,
  CORE_SERVICE_DATE_FORMATTER_INPUT_FORM,
  DefaultOrganizationalStructurePlantResponseBodyEdit,
  DefaultOrganizationalStructurePlantResponseBodyGet,
  ICoreControllerSharedPageModalManagementBase,
  ICoreServiceDateFormatterInputForm,
  IconPlus,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedPageDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/core'

import {
  ACTION_EDIT,
  ACTION_NEW_ENTRY,
  ACTION_UPLOAD,
  FORMATTED_DATE_COMPLETE,
  FORMATTED_DATE_DATE_ONLY,
  LABEL_ACTION,
  LABEL_CODE,
  LABEL_CREATED_AT,
  LABEL_FROM_DATE,
  LABEL_NAME,
  LABEL_THRU_DATE,
} from '@procuraid-frontend/localization'

import {
  IOrganizationalStructureControllerAdminPlantGetResourceDataById,
  IOrganizationalStructureControllerAdminPlantModalManagementUpdate,
  useOrganizationalStructureControllerAdminPlantManageItemsFromTableGet,
} from '@procuraid-frontend/organizational-structure'

import iconEdit from '@/assets/svg/icon-action-edit.svg'

export function useOrganizationalStructureControllerAdminPlantDataTableManagementGetComponentPropsBase<
  DataByIdType extends
    DefaultOrganizationalStructurePlantResponseBodyEdit = DefaultOrganizationalStructurePlantResponseBodyEdit,
  DataType extends
    DefaultOrganizationalStructurePlantResponseBodyGet = DefaultOrganizationalStructurePlantResponseBodyGet,
>(
  getPlantByIdCtrl: IOrganizationalStructureControllerAdminPlantGetResourceDataById<DataByIdType>,
  modalUpdatePlantByIdCtrl: IOrganizationalStructureControllerAdminPlantModalManagementUpdate<DataByIdType>,
  modalCreateSinglePlantCtrl: ICoreControllerSharedPageModalManagementBase,
  modalCreatePlantListsFromSpreadsheetFileCtrl: ICoreControllerSharedPageModalManagementBase
) {
  const dateFormatterInputFormService =
    useContainerGet<ICoreServiceDateFormatterInputForm>(
      CORE_SERVICE_DATE_FORMATTER_INPUT_FORM
    )

  const translate = useCoreControllerSharedLocalizationTranslate()

  const { onTableUpdated: actionOnUpdate, refreshTableData } =
    useOrganizationalStructureControllerAdminPlantManageItemsFromTableGet<DataType>()

  const initialResource = useRef({
    data: null,
    params: {
      column_defs: [],
      filter_params: {
        state_match: '',
      },
      page: 1,
      per_page: 10,
      sort_info: {
        fields: ['created_at'],
        directions: ['desc'],
      },
    },
  })

  const openCreateSinglePlant = () =>
    modalCreateSinglePlantCtrl.toggleShowModalComponent(true)

  const openCreatePlantListsFromSpreadsheetFile = () =>
    modalCreatePlantListsFromSpreadsheetFileCtrl.toggleShowModalComponent(true)

  const openModalUpdatePlantById = (plantId: string) => async () => {
    const plantByIdData = await getPlantByIdCtrl.getData(plantId)
    modalUpdatePlantByIdCtrl.toggleShowModalComponent(true)
    modalUpdatePlantByIdCtrl.setDataIntoModalComponent(plantByIdData)
  }

  const utilityButtons = () => (
    <div className="d-flex" style={{ gap: '0.5rem' }}>
      <ButtonGhostWithSVGIcon
        svg={<IconPlus />}
        title={translate(ACTION_UPLOAD)}
        onClick={openCreatePlantListsFromSpreadsheetFile}
      />
      <ButtonGhostWithSVGIcon
        svg={<IconPlus />}
        title={translate(ACTION_NEW_ENTRY)}
        onClick={openCreateSinglePlant}
      />
    </div>
  )

  return useCoreControllerSharedPageDataTableManagementGetComponentPropsBase<DataType>(
    {
      initialResource: initialResource.current,
      columns: [
        { dataField: 'facility_name', text: translate(LABEL_NAME), sort: true },
        { dataField: 'code', text: translate(LABEL_CODE), sort: true },
        {
          dataField: 'from_date',
          text: translate(LABEL_FROM_DATE),
          formatter: (value) =>
            typeof value === 'string'
              ? translate(FORMATTED_DATE_DATE_ONLY, {
                  value:
                    dateFormatterInputFormService.convertCustomValueToDateISO(
                      value
                    ),
                })
              : '-',
          sort: true,
        },
        {
          dataField: 'thru_date',
          text: translate(LABEL_THRU_DATE),
          formatter: (value) =>
            typeof value === 'string'
              ? translate(FORMATTED_DATE_DATE_ONLY, {
                  value:
                    dateFormatterInputFormService.convertCustomValueToDateISO(
                      value
                    ),
                })
              : '-',
          sort: true,
        },
        /**
         * @todo add purchasing org name and code
         */
        {
          dataField: 'created_at',
          text: translate(LABEL_CREATED_AT),
          formatter: (value) =>
            value ? translate(FORMATTED_DATE_COMPLETE, { value }) : '-',
          sort: true,
        },
        /**
         * @todo add created by
         */
        {
          dataField: 'id',
          text: translate(LABEL_ACTION),
          searchable: false,
          formatter: (plantId) => (
            <div className="d-flex" style={{ gap: '1rem' }}>
              <ButtonRow onClick={openModalUpdatePlantById(plantId)}>
                <img
                  src={iconEdit}
                  alt="Icon Edit Plant"
                  style={{ marginRight: '1rem' }}
                />
                <span style={{ textDecoration: 'underline' }}>
                  {translate(ACTION_EDIT)}
                </span>
              </ButtonRow>
            </div>
          ),
        },
      ],
      actionOnUpdate,
      utilityButtons,
    },
    { refreshTableData }
  )
}
