import { useContainerGet } from 'inversify-hooks-esm'
import {
  DefaultSimpleResponse,
  ICoreResourceOrganizationalStructureOnlyGet,
  useCoreControllerSharedManageItemsFromTableBase,
} from '@procuraid-frontend/core'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE,
  DefaultOrganizationalStructurePurchasingOrganizationResponseBodyGet,
} from '@/resources'

export function useOrganizationalStructureControllerAdminPurchasingOrganizationManageItemsFromTableGet<
  DataType extends
    DefaultOrganizationalStructurePurchasingOrganizationResponseBodyGet = DefaultOrganizationalStructurePurchasingOrganizationResponseBodyGet,
  ResponseType extends
    DefaultSimpleResponse<DataType> = DefaultSimpleResponse<DataType>,
>() {
  const purchOrgsResource = useContainerGet<
    ICoreResourceOrganizationalStructureOnlyGet<ResponseType>
  >(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_ORGANIZATION_BASE)

  return useCoreControllerSharedManageItemsFromTableBase<
    DataType,
    ResponseType
  >({
    handleTableUpdated: async (params) =>
      await purchOrgsResource.get({ params }),
  })
}
