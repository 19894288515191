/* eslint-disable @typescript-eslint/no-explicit-any */
import { DefaultDropdownOptionsType } from '@procuraid-frontend/core'
import { useCallback, useState } from 'react'

export function useControllerAdminOrganizationStructureScopeOfSuppliesDropdownManagementDefaultValueCreateOrUpdate<
  DropdownOptionsType extends
    DefaultDropdownOptionsType = DefaultDropdownOptionsType,
>() {
  const [defaultValue, setDefaultValue] = useState<DropdownOptionsType[]>([])

  const getDefaultValue = useCallback(() => {
    const scope_of_supplies = (items: DropdownOptionsType[]) => items
    setDefaultValue(scope_of_supplies)
  }, [])

  return {
    getDefaultValue,
    defaultValue,
  }
}
