import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'
import { getViewAdminOrganizationalStructurePurchasingGroupListsContainer } from './lists'

export function getViewAdminOrganizationalStructurePurchasingGroupContainer(
  container = defaultContainer
) {
  return compose(
    getViewAdminOrganizationalStructurePurchasingGroupListsContainer
  )(container)
}
