import { container as defaultContainer } from 'inversify-hooks-esm'
import {
  CORE_CONTROLLER_SHARED_PAGE_HOME_GET_CALCULATED_RESOURCE_APP_MAIN_CLASS_NAME,
  CORE_CONTROLLER_SHARED_PAGE_NAVIGATION_TREE_GET_RESOURCE_DATA_PRIVATE,
  CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_CALCULATED_RESOURCE_BASE,
  CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_CALCULATED_RESOURCE_TRANSLATION_LABEL,
  CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_DELETE_RESOURCE_LOGOUT,
  CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_GET_CALCULATED_RESOURCE_MENU_LISTS,
  CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_GET_CALCULATED_RESOURCE_SELECTED_MENU,
  CORE_RESOURCE_NAVIGATION_TREE_BASE,
  CORE_SERVICE_NAVIGATION_ITEM_PRIVATE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_SERVER_TIME_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE,
  CORE_SERVICE_TOAST_BASE,
  CORE_VIEW_ATOM_TEXT_SERVER_TIME_SIDEBAR_MAIN,
  CORE_VIEW_ORGANISM_SIDEBAR_MAIN_FOOTER_BASE,
  CoreViewAtomTextServerTimeSidebarMain,
  CoreViewOrganismSidebarMainFooter,
  ICoreServiceNavigationItemPrivate,
  ICoreServiceStateManagementInstanceUserBase,
  ICoreServiceStateManagementSelectorUserOnlyGetNavigation,
  useCoreControllerSharedPageHomeGetCalculatedResourceAppMainClassName,
  useCoreControllerSharedPageNavigationTreeGetResourceDataPrivate,
  useCoreControllerSharedPageServerTimeManagerGetCalculatedResourceBase,
  useCoreControllerSharedPageServerTimeManagerGetCalculatedResourceTranslationLabel,
  useCoreControllerSharedPageSidebarMainDeleteResourceLogout,
  useCoreControllerSharedPageSidebarMainGetCalculatedResourceMenuLists,
  useCoreControllerSharedPageSidebarMainGetCalculatedResourceSelectedMenu,
} from '@procuraid-frontend/core'

export function getViewSharedHomeContainer(container = defaultContainer) {
  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_HOME_GET_CALCULATED_RESOURCE_APP_MAIN_CLASS_NAME
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageHomeGetCalculatedResourceAppMainClassName(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
      )
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_CALCULATED_RESOURCE_BASE
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageServerTimeManagerGetCalculatedResourceBase(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_SERVER_TIME_BASE)
      )
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_CALCULATED_RESOURCE_TRANSLATION_LABEL
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageServerTimeManagerGetCalculatedResourceTranslationLabel(
        c.get(
          CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_CALCULATED_RESOURCE_BASE
        )
      )
    )

  container
    .bind(CORE_VIEW_ATOM_TEXT_SERVER_TIME_SIDEBAR_MAIN)
    .toConstantValue(CoreViewAtomTextServerTimeSidebarMain)

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_GET_CALCULATED_RESOURCE_MENU_LISTS
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageSidebarMainGetCalculatedResourceMenuLists(
        c.get<ICoreServiceNavigationItemPrivate>(
          CORE_SERVICE_NAVIGATION_ITEM_PRIVATE
        ),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE)
      )
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_GET_CALCULATED_RESOURCE_SELECTED_MENU
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageSidebarMainGetCalculatedResourceSelectedMenu(
        c.get<ICoreServiceStateManagementSelectorUserOnlyGetNavigation>(
          CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE
        )
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_NAVIGATION_TREE_GET_RESOURCE_DATA_PRIVATE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageNavigationTreeGetResourceDataPrivate(
        c.get<ICoreServiceStateManagementInstanceUserBase>(
          CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE
        ),
        c.get(CORE_RESOURCE_NAVIGATION_TREE_BASE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_SIDEBAR_MAIN_DELETE_RESOURCE_LOGOUT)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageSidebarMainDeleteResourceLogout(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE),
        c.get(CORE_SERVICE_TOAST_BASE)
      )
    )

  container
    .bind(CORE_VIEW_ORGANISM_SIDEBAR_MAIN_FOOTER_BASE)
    .toConstantValue(CoreViewOrganismSidebarMainFooter)

  return container
}
