import { DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit } from '@/resources'
import { OrganizationalStructureViewOrganismModalPurchasingGroupUpdate } from '@/views'

import {
  ICoreControllerSharedPageModalManagementWithData,
  useCoreControllerSharedModalManagementWithData,
} from '@procuraid-frontend/core'

export type IOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
> = ICoreControllerSharedPageModalManagementWithData<DataType>

export const ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_UPDATE =
  'OrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate'

export function useOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
>(): IOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate<DataType> {
  return useCoreControllerSharedModalManagementWithData<DataType>({
    component:
      OrganizationalStructureViewOrganismModalPurchasingGroupUpdate,
  })
}
