import {
  AppReduxContext,
  CORE_CONTROLLER_SHARED_MAIN_COMPONENT_PROPS_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_THEME_COLORS,
  CoreViewAtomOverlay,
  ICoreControllerSharedMainComponentPropsBase,
  ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'

import { Provider } from 'react-redux'
import { HistoryRouter } from 'redux-first-history/rr6'
import { PersistGate } from 'redux-persist/integration/react'
import { ThemeProvider } from 'styled-components'
import { ToastContainer } from 'react-toastify'
import { Provider as BusProvider } from 'react-bus-esm'
import { ConfigProvider } from 'antd'

import { ComponentProviderChildLevel2 } from './ChildLevel2'

export const ComponentProviderChildLevel1 = () => {
  const mainComponentProps =
    useContainerGet<ICoreControllerSharedMainComponentPropsBase>(
      CORE_CONTROLLER_SHARED_MAIN_COMPONENT_PROPS_BASE
    )
  const themeColors =
    useContainerGet<ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors>(
      CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REACT_CONTEXT_CUSTOMER_ASSETS_THEME_COLORS
    )

  const { store, persistor, history } = mainComponentProps.getProps({
    devTools: import.meta.env.DEV,
  })

  return (
    <Provider store={store} context={AppReduxContext}>
      <PersistGate persistor={persistor}>
        <HistoryRouter history={history}>
          <ThemeProvider theme={themeColors}>
            <ConfigProvider
              theme={{ token: { colorPrimary: themeColors.colors.primary } }}
            >
              <BusProvider>
                <CoreViewAtomOverlay />
                <ToastContainer icon={false} progressStyle={{ height: 2 }} />
                <ComponentProviderChildLevel2 />
              </BusProvider>
            </ConfigProvider>
          </ThemeProvider>
        </HistoryRouter>
      </PersistGate>
    </Provider>
  )
}
