import styled from 'styled-components'

import { Formik, Form, FormikHelpers } from 'formik'
import {
  ButtonFormSubmit,
  ButtonOutline,
  InputFormControl,
  LabelForm,
  Modal,
  ModalBasePropsWithData,
  ModalBody,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  addRefProps,
  commonTextMixin,
  greyMed,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import {
  ACTION_CANCEL,
  ACTION_EDIT_RESOURCE,
  ACTION_SUBMIT,
  LABEL_CODE,
  LABEL_DESCRIPTION,
  LABEL_NAME,
  LABEL_SCOPE_OF_SUPPLY,
} from '@procuraid-frontend/localization'
import {
  DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
  DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
} from '@/resources'
import {
  useControllerAdminOrganizationStructureScopeOfSuppliesFormManagementValidator,
  useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateHandleSubmit,
  useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValue,
} from '@/controllers'

export interface OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdateBaseProps<
  DataType extends
    DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit = DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
> extends ModalBasePropsWithData<DataType> {}

const ModalComponent = styled(Modal)`
  .modal-dialog {
    min-width: 24vw;
    max-height: 100%;
    & .modal-content {
      border-radius: 0.625rem;
      .modal-header {
        width: 100%;
        padding: 0.75em 1em;
        align-items: center;
        .modal-title {
          font-size: 1.5rem;
        }
        .close {
          > span {
            ${(props) =>
              commonTextMixin(props?.theme?.colors?.greyMed ?? greyMed)}
            font-size: .875rem;
          }
        }
      }
      .modal-body {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 1rem 1.5rem;
        max-height: 60vh;
        overflow-y: scroll;
        .body-badge {
          padding: 0.5rem 0.875rem;
          border-radius: 5px;
          background-color: ${(props) =>
            props.theme?.colors?.greyMed ?? greyMed};
        }
      }
      .modal-footer {
        border-top: 1px solid rgb(222, 226, 230) !important;
        width: 100%;
        padding: 14px 16px;
        justify-content: flex-end;
        .btn-actions > span {
          font-size: 0.875rem !important;
        }
      }
    }
  }
`

const OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdateBase = <
  DataType extends
    DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit = DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
  PropType extends
    OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdateBaseProps<DataType> = OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdateBaseProps<DataType>,
  PayloadType extends
    DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate = DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
>({
  innerRef,
  onHide,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  data = null,
  ...otherProps
}: PropType) => {
  const { handleSubmit: handleUpdateScopeOfSupply } =
    useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateHandleSubmit<PayloadType>()

  const { initialValue: getInitialValue } =
    useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValue<
      PayloadType,
      DataType
    >()

  const translate = useCoreControllerSharedLocalizationTranslate()

  const { validator: getValidator } =
    useControllerAdminOrganizationStructureScopeOfSuppliesFormManagementValidator()

  const handleSubmit = async (
    payload: PayloadType,
    helpers: FormikHelpers<PayloadType>
  ) => {
    try {
      await handleUpdateScopeOfSupply(payload, helpers)
    } finally {
      onHide?.()
    }
  }

  return (
    <Formik
      enableReinitialize
      initialValues={getInitialValue({ data, mode: 'UPDATE' })}
      validationSchema={getValidator()}
      onSubmit={handleSubmit}
    >
      <Form>
        <ModalComponent ref={innerRef} centered onHide={onHide} {...otherProps}>
          <ModalHeader closeButton={false} onHide={onHide}>
            <ModalTitle>
              {translate(ACTION_EDIT_RESOURCE, {
                resource: translate(LABEL_SCOPE_OF_SUPPLY),
              })}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div className="d-flex flex-column" style={{ gap: '0.5rem' }}>
              <LabelForm
                label={translate(LABEL_CODE)}
                htmlFor="code"
                className="mb-0"
                required={true}
              />
              <InputFormControl id="code" name="code" />
              <LabelForm
                label={translate(LABEL_NAME)}
                htmlFor="name"
                className="mb-0"
                required={true}
              />
              <InputFormControl id="name" name="name" />
              <LabelForm
                label={translate(LABEL_DESCRIPTION)}
                htmlFor="desc"
                className="mb-0"
                required={false}
              />
              <InputFormControl id="desc" name="desc" />
            </div>
          </ModalBody>
          <ModalFooter>
            <ButtonOutline
              onClick={onHide}
              className="btn-actions"
              title={translate(ACTION_CANCEL)}
            />
            <ButtonFormSubmit compact size="sm">
              {translate(ACTION_SUBMIT)}
            </ButtonFormSubmit>
          </ModalFooter>
        </ModalComponent>
      </Form>
    </Formik>
  )
}

export const OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdate =
  addRefProps(OrganizationalStructureViewOrganismModalScopeOfSuppliesUpdateBase)
