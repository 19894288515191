import { useRef } from 'react'

import {
  ButtonDataTableRow,
  ButtonGhostWithSVGIcon,
  // ButtonRow,
  DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit,
  DefaultGeneralConfigurationScopeOfSupplyResponseBodyGet,
  ICoreControllerSharedPageModalManagementBase,
  IconPlus,
  useCoreControllerSharedLocalizationTranslate,
  useCoreControllerSharedPageDataTableManagementGetComponentPropsBase,
} from '@procuraid-frontend/core'

import {
  ACTION_EDIT,
  ACTION_NEW_ENTRY,
  LABEL_ACTION,
  LABEL_CODE,
  LABEL_DESCRIPTION,
  LABEL_NAME,
  LABEL_PARENT,
  LABEL_SCOPE_OF_SUPPLY_TYPE,
} from '@procuraid-frontend/localization'
import {
  IGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById,
  IGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate,
  useGeneralConfigurationControllerAdminScopeOfSupplyManageItemsFromTableGet,
} from '@procuraid-frontend/general-configuration'

import iconEdit from '@/assets/svg/icon-action-edit.svg'

export function useGeneralConfigurationControllerAdminScopeOfSupplyDataTableManagementGetComponentPropsBase<
  DataByIdType extends
    DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit = DefaultGeneralConfigurationScopeOfSupplyResponseBodyEdit,
  DataType extends
    DefaultGeneralConfigurationScopeOfSupplyResponseBodyGet = DefaultGeneralConfigurationScopeOfSupplyResponseBodyGet,
>(
  getScopeOfSupplyByIdCtrl: IGeneralConfigurationControllerAdminScopeOfSupplyGetResourceDataById<DataByIdType>,
  modalUpdateScopeOfSupplyByIdCtrl: IGeneralConfigurationControllerAdminScopeOfSupplyModalManagementUpdate<DataByIdType>,
  modalCreateSingleScopeOfSupplyCtrl: ICoreControllerSharedPageModalManagementBase
) {
  const translate = useCoreControllerSharedLocalizationTranslate()

  const { onTableUpdated: actionOnUpdate, refreshTableData } =
    useGeneralConfigurationControllerAdminScopeOfSupplyManageItemsFromTableGet<DataType>()

  const initialResource = useRef({
    data: null,
    params: {
      column_defs: [],
      filter_params: {
        state_match: '',
      },
      page: 1,
      per_page: 10,
      sort_info: {
        fields: ['created_at'],
        directions: ['desc'],
      },
    },
  })

  const openCreateSingleScopeOfSupply = () =>
    modalCreateSingleScopeOfSupplyCtrl.toggleShowModalComponent(true)

  const openModalUpdateScopeOfSupplyById =
    (scopeOfSupplyId: string) => async () => {
      const scopeOfSupplyByIdData =
        await getScopeOfSupplyByIdCtrl.getData(scopeOfSupplyId)
      modalUpdateScopeOfSupplyByIdCtrl.toggleShowModalComponent(true)
      modalUpdateScopeOfSupplyByIdCtrl.setDataIntoModalComponent(
        scopeOfSupplyByIdData
      )
    }

  const utilityButtons = () => (
    <div className="d-flex" style={{ gap: '0.5rem' }}>
      <ButtonGhostWithSVGIcon
        svg={<IconPlus />}
        title={translate(ACTION_NEW_ENTRY)}
        onClick={openCreateSingleScopeOfSupply}
      />
    </div>
  )

  return useCoreControllerSharedPageDataTableManagementGetComponentPropsBase<DataType>(
    {
      initialResource: initialResource.current,
      columns: [
        {
          dataField: 'scope_of_supply_type',
          text: translate(LABEL_SCOPE_OF_SUPPLY_TYPE),
        },
        { dataField: 'parent_name', text: translate(LABEL_PARENT) },
        { dataField: 'code', text: translate(LABEL_CODE) },
        { dataField: 'name', text: translate(LABEL_NAME) },
        { dataField: 'desc', text: translate(LABEL_DESCRIPTION) },
        /**
         * @todo add created by
         */
        {
          dataField: 'id',
          text: translate(LABEL_ACTION),
          classes: 'medium-width sticky p-0 border-left',
          headerClasses: 'medium-width sticky',
          searchable: false,
          formatter: (scopeOfSupplyId) => (
            <ButtonDataTableRow
              onClick={openModalUpdateScopeOfSupplyById(scopeOfSupplyId)}
            >
              <img
                src={iconEdit}
                alt="Icon Edit Scope Of Supplies"
                style={{ marginRight: '1rem' }}
              />
              <span style={{ textDecoration: 'underline' }}>
                {translate(ACTION_EDIT)}
              </span>
            </ButtonDataTableRow>
          ),
        },
      ],
      actionOnUpdate,
      utilityButtons,
    },
    { refreshTableData }
  )
}
