import { GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreate } from '@/views/general-configuration/admin/03-organisms'

import {
  ICoreControllerSharedPageModalManagementBase,
  useCoreControllerSharedModalManagementBase,
} from '@procuraid-frontend/core'

export const GENERAL_CONFIGURATION_CONTROLLER_ADMIN_SCOPE_OF_SUPPLY_MODAL_MANAGEMENT_CREATE =
  'GeneralConfigurationControllerAdminScopeOfSupplyModalManagementCreate'

export function useGeneralConfigurationControllerAdminScopeOfSupplyModalManagementCreate(): ICoreControllerSharedPageModalManagementBase {
  return useCoreControllerSharedModalManagementBase({
    component: GeneralConfigurationViewOrganismAdminModalScopeOfSupplyCreate,
  })
}
