import { useState } from 'react'

import {
  DefaultSpreadsheetResponseBodyImport,
  DefaultSpreadsheetResponseBodyImportOriginal,
  ICoreResourceSpreadsheetOnlyImport,
  ICoreServiceAttachmentFormDataHandler,
} from '@procuraid-frontend/core'

import {
  DefaultCoreControllerSharedSpreadsheetManagementGetResourceDataFromFileOptions,
  ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFile,
} from './types'
import { useCoreControllerSharedSpreadsheetManagementTransformResponseBodyImport } from '../transform-response-body'

export const CORE_CONTROLLER_SHARED_SPREADSHEET_MANAGEMENT_GET_RESOURCE_DATA_FROM_FILE =
  'CoreControllerSharedSpreadsheetManagementGetResourceDataFromFile'

export function useCoreControllerSharedSpreadsheetManagementGetResourceDataFromFile<
  DataType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
  OptionsType extends
    DefaultCoreControllerSharedSpreadsheetManagementGetResourceDataFromFileOptions = DefaultCoreControllerSharedSpreadsheetManagementGetResourceDataFromFileOptions,
  InitialDataType extends
    DefaultSpreadsheetResponseBodyImportOriginal = DefaultSpreadsheetResponseBodyImportOriginal,
>(
  spreadsheetResource: ICoreResourceSpreadsheetOnlyImport<InitialDataType>,
  formDataHandlerService: ICoreServiceAttachmentFormDataHandler
): ICoreControllerSharedSpreadsheetManagementGetResourceDataFromFile<
  DataType,
  OptionsType
> {
  const [loading, setLoading] = useState(false)

  const { transform } =
    useCoreControllerSharedSpreadsheetManagementTransformResponseBodyImport<
      InitialDataType,
      DataType
    >()

  const getData = async (options?: OptionsType) => {
    const files = options?.files ?? []
    const sprdsheet_type_id = options?.sprdsheet_type_id ?? ''
    const additionalImportQueryParams =
      options?.additionalImportQueryParams ?? {}
    const prefix = options?.formDataPrefix ?? ''

    setLoading(true)
    try {
      const file = files?.[0]
      const formData = formDataHandlerService.constructFormDataFromResource(
        {
          file,
        },
        { prefix }
      )
      const importResponse = await spreadsheetResource.import(
        {
          sprdsheet_type_id,
          ...additionalImportQueryParams,
        },
        formData
      )
      const initialReturn = importResponse.data.data
      return transform(initialReturn)
    } finally {
      setLoading(false)
    }
  }

  return {
    getData,
    loading,
  }
}
