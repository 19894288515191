import { ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors } from '@procuraid-frontend/core'

export const CoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors: ICoreServiceThirdPartyIntegratorReactContextCustomerAssetsThemeColors =
  {
    colors: {
      primary: '#C1272D',
      secondary: '#6c757d',
      danger: '#a62834',
      info: '#17a2b8',
      warning: '#ffc107',
      success: '#28a745',
      light: '#f8f9fa',
      dark: '#333c44',
      greyFormMed: '#ebebeb',
      greyMed: '#868686',
      greyPlaceholder: '#acacac',
      greyDark: '#424242',
      greyLight: '#f5f5f5',
      warnLight: '#ffebb4',
      warnMed: '#ffbf10',
      warnDark: '#be8b00',
      successDark: '#14b420',
      successMed: '#09d019',
      infoDark: '#1aaae8',
      infoMed: '#69c9f2',
      infoLight: '#b4e2ff',
      dangerMed: '#ed4949',
      darkLight: '#3e4953',
      grayscaleDarkLight: '#484848',
      bright: '#fafafa',
      grayLight: '#636c72',
      grayLight2: '#999',
      green: '#007540',
      redPrimary: '#C1272D',
      redSecondary: '#C1272D29',
    },
  }
