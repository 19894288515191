import { useOrganizationalStructureControllerAdminRegionGetCalculatedResourceSpreadsheetURLReference } from '@/controllers'
import {
  CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW,
  ICoreControllerSharedPageResourceNavigationBase,
  ICoreServiceJavaScriptGlobalWindow,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'

export interface DefaultRegionResourceNavigationDownloadFileOptionsType {
  /**
   * @default null
   */
  url?: string | null
  /**
   * @default DownloadFiles
   */
  targetName?: string
  /**
   * @default "" (empty string)
   * it will be appended after `noopener,noreferrer` default value
   */
  features?: string

  /**
   * @default false
   */
  openNewTab?: boolean
}

export type ICoreControllerSharedPageRegionResourceNavigationDownloadFile<
  OptionsType extends
    DefaultRegionResourceNavigationDownloadFileOptionsType = DefaultRegionResourceNavigationDownloadFileOptionsType,
> = ICoreControllerSharedPageResourceNavigationBase<void, OptionsType>

export function useCoreControllerSharedPageRegionResourceNavigationDownloadFile<
  OptionsType extends
    DefaultRegionResourceNavigationDownloadFileOptionsType = DefaultRegionResourceNavigationDownloadFileOptionsType,
>(): ICoreControllerSharedPageRegionResourceNavigationDownloadFile<OptionsType> {
  const windowService = useContainerGet<ICoreServiceJavaScriptGlobalWindow>(
    CORE_SERVICE_JAVASCRIPT_GLOBAL_WINDOW
  )

  const next = (options?: OptionsType) => {
    const url = options?.url ?? null
    if (typeof url === 'string') {
      const openNewTab = options?.openNewTab ?? false
      const targetName = openNewTab
        ? '_blank'
        : options?.targetName ?? 'DownloadFiles'
      const features = options?.features ?? ''
      windowService.open(
        url,
        targetName,
        `noopener,noreferrer${features.length > 0 ? `,${features}` : ''}`
      )
    }
  }

  return {
    next,
  }
}

export function useOrganizationalStructureControllerAdminRegionResourceNavigationDownloadSpreadsheetFileReference(): ICoreControllerSharedPageResourceNavigationBase {
  const { getCalculatedResource: getURL } =
    useOrganizationalStructureControllerAdminRegionGetCalculatedResourceSpreadsheetURLReference()
  const { next: downloadFile } =
    useCoreControllerSharedPageRegionResourceNavigationDownloadFile()

  const next = () => {
    const url = getURL()
    downloadFile({ url, openNewTab: true })
  }

  return {
    next,
  }
}
