import type { FormikHelpers } from 'formik'
import { useContainerGet } from 'inversify-hooks-esm'

import {
  CORE_SERVICE_TOAST_BASE,
  ICoreControllerSharedPageFormManagementHandleSubmit,
  ICoreResourceOrganizationalStructureBase,
  ICoreServiceToastBase,
  useCoreControllerSharedLocalizationTranslate,
} from '@procuraid-frontend/core'

import {
  LABEL_PURCHASING_GROUP,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED,
  NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED,
} from '@procuraid-frontend/localization'
import {
  CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE,
  DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate,
} from '@/resources'

export function useOrganizationalStructureControllerAdminPurchasingGroupFormManagementCreateOrUpdateHandleSubmit<
  PayloadType extends
    DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate = DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate,
>(): ICoreControllerSharedPageFormManagementHandleSubmit<PayloadType> {
  const purchOrgResource =
    useContainerGet<ICoreResourceOrganizationalStructureBase>(
      CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE
    )
  const toastService = useContainerGet<ICoreServiceToastBase>(
    CORE_SERVICE_TOAST_BASE
  )

  const translate = useCoreControllerSharedLocalizationTranslate()

  const handleSubmit = async (
    data: PayloadType,
    { setSubmitting }: FormikHelpers<PayloadType>
  ) => {
    setSubmitting(true)
    try {
      if (data?.id) {
        await purchOrgResource.update(data.id, { data: data })
        toastService.showSuccessToast({
          subtitle: translate(NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_UPDATED, {
            resource: translate(LABEL_PURCHASING_GROUP),
          }),
        })
      } else {
        await purchOrgResource.save({ data: data })
        toastService.showSuccessToast({
          subtitle: translate(NOTIFICATION_SUBTITLE_RESOURCE_HAS_BEEN_ADDED, {
            resource: translate(LABEL_PURCHASING_GROUP),
          }),
        })
      }
    } finally {
      setSubmitting(false)
    }
  }

  return {
    handleSubmit,
  }
}
