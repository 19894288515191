import {
  ICoreControllerSharedPageModalManagementBase,
  useCoreControllerSharedModalManagementBase,
} from '@procuraid-frontend/core'

import { GeneralConfigurationViewOrganismModalBankCreate } from '@/views/general-configuration/admin/03-organisms'

export const GENERAL_CONFIGURATION_CONTROLLER_ADMIN_BANK_MODAL_MANAGEMENT_CREATE =
  'GeneralConfigurationControllerAdminBankModalManagementCreate'

export function useGeneralConfigurationControllerAdminBankModalManagementCreate(): ICoreControllerSharedPageModalManagementBase {
  return useCoreControllerSharedModalManagementBase({
    component: GeneralConfigurationViewOrganismModalBankCreate,
  })
}
