import { ICoreServiceThirdPartyIntegratorI18nextResource } from '@procuraid-frontend/core'
import {
  defaultEnTranslations,
  defaultIdTranslations,
} from '@procuraid-frontend/localization'

export const CoreServiceThirdPartyIntegratorI18nextResourceBase: ICoreServiceThirdPartyIntegratorI18nextResource =
  {
    en: {
      translation: {
        ...defaultEnTranslations,
        // ...enTranslationsOverrides,
      },
    },
    id: {
      translation: {
        ...defaultIdTranslations,
        // ...idTranslationsOverrides,
      },
    },
  }
