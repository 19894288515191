import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getViewAdminGeneralConfigurationRegionListsContainer } from './lists'

export function getViewAdminGeneralConfigurationRegionContainer(
  container = defaultContainer
) {
  return compose(getViewAdminGeneralConfigurationRegionListsContainer)(
    container
  )
}
