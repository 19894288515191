// import { getSharedContainer } from '@/di/shared'
import { container as defaultContainer } from 'inversify-hooks-esm'
import compose from 'just-compose'

import { getMainThreadInitializerControllerContainer } from './controllers'

import { getMainThreadInitializerServicesContainer } from './services'

function getMainThreadInitializerContainer(container = defaultContainer) {
  return compose(
    getMainThreadInitializerControllerContainer,
    getMainThreadInitializerServicesContainer
    // getSharedContainer
  )(container)
}

export const initializerContainer = getMainThreadInitializerContainer()
