import { snakeCase } from 'lodash-es'

import {
  DefaultNavigationTreeResponseBodyGet,
  DefaultUserInstanceStateBase,
  ICoreServiceNavigationItemBase,
} from '@procuraid-frontend/core'

export interface DefaultServiceNavigationItemOnlyGetRoutePathInput<
  ItemType extends
    DefaultNavigationTreeResponseBodyGet = DefaultNavigationTreeResponseBodyGet,
> {
  parentPath: string | boolean
  routePaths: string[]
  siblingPath: string
  item: ItemType
}

function getRoutePath<
  ItemType extends
    DefaultNavigationTreeResponseBodyGet = DefaultNavigationTreeResponseBodyGet,
  InputType extends
    DefaultServiceNavigationItemOnlyGetRoutePathInput<ItemType> = DefaultServiceNavigationItemOnlyGetRoutePathInput<ItemType>,
>(input: InputType) {
  const { parentPath, routePaths, siblingPath, item } = input
  return (
    (typeof parentPath === 'string'
      ? `${parentPath}/${
          parentPath.includes(routePaths[1]) ? routePaths[2] : routePaths[1]
        }`
      : siblingPath) ?? `/${routePaths[0]}/${snakeCase(item?.name)}`
  )
}

export function getDemoServiceNavigationItemBase<
  ItemType extends
    DefaultNavigationTreeResponseBodyGet = DefaultNavigationTreeResponseBodyGet,
  UserStateType extends
    DefaultUserInstanceStateBase = DefaultUserInstanceStateBase,
>(): ICoreServiceNavigationItemBase<ItemType, UserStateType> {
  const editNavigationPath = (
    parentPath: boolean | string,
    userState: UserStateType
  ) => {
    const userType = userState?.type ?? ''
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (item: ItemType, _index?: number, _array?: ItemType[]): ItemType => {
      const isExternalLink = item?.external ?? false
      if (isExternalLink) {
        return item
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...routePaths] =
        item?.navigable?.path?.split('/') ?? `/${userType}/`.split('/')
      const siblingPath = `/${routePaths[0]}/${snakeCase(item?.name)}`
      const routePath = getRoutePath<ItemType>({
        item,
        parentPath,
        routePaths,
        siblingPath,
      })
      const path =
        userType === 'admin' && !routePath.includes('/admin')
          ? `/admin${routePath}`
          : routePath
      const navChildren = (
        typeof parentPath === 'boolean' && !parentPath
          ? item?.children ?? [item]
          : null
      ) as ItemType[] | null
      return {
        ...item,
        name: snakeCase(item.name),
        navigable: {
          ...item.navigable,
          path,
        },
        ...(Array.isArray(navChildren)
          ? {
              children: navChildren.map(
                editNavigationPath(siblingPath, userState)
              ),
            }
          : {}),
      }
    }
  }
  return {
    editNavigationPath,
  }
}
