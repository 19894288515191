import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE,
  useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreate,
} from '@/controllers/admin/organizational-structure/purchasing-group/modal-management/create.hooks'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_UPDATE,
  useOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate,
} from '@/controllers/admin/organizational-structure/purchasing-group/modal-management/update.hooks'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_GET_RESOURCE_DATA_BY_ID,
  useOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById,
} from '@/controllers/admin/organizational-structure/purchasing-group/get-resource-data/by-id.hooks'

import { CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE } from '@/resources'
import {
  ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE,
  useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreateFromSpreadsheetFile,
} from '@/controllers/admin/organizational-structure/purchasing-group/modal-management'

/**
 * @description playground buat DI terkait purchasing organization. referensi: . referensi: {@link getViewAdminOrganizationalStructurePurchasingGroupListsContainer}
 * kalo udah selesai ditest, folder dan file-nya tolong di-copy ke library `@procuraid-frontend/organizational-structure` ya
 */
export function getViewAdminOrganizationalStructurePurchasingGroupListsContainer(
  container = defaultContainer
) {
  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreate()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_UPDATE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPurchasingGroupModalManagementUpdate()
    )

    container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_MODAL_MANAGEMENT_CREATE_FROM_SPREADSHEET_FILE
    )
    .toDynamicValue(() =>
      useOrganizationalStructureControllerAdminPurchasingGroupModalManagementCreateFromSpreadsheetFile()
    )

  container
    .bind(
      ORGANIZATIONAL_STRUCTURE_CONTROLLER_ADMIN_PURCHASING_GROUP_GET_RESOURCE_DATA_BY_ID
    )
    .toDynamicValue(({ container: c }) =>
      useOrganizationalStructureControllerAdminPurchasingGroupGetResourceDataById(
        c.get(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_PURCHASING_GROUP_BASE)
      )
    )
  return container
}
