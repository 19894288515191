import {
  DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate,
  DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
} from '@/resources'
import { ICoreControllerSharedPageFormManagementInitialValueBase } from '@procuraid-frontend/core'

export interface DefaultOrganizationalStructureControllerAdminPurchasingGroupFormManagementCreateOrUpdateInitialValueOptions<
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
> {
  /**
   * @default CREATE
   */
  mode?: 'CREATE' | 'UPDATE'
  /**
   * @default null
   */
  data?: DataType | null
}

export function useOrganizationalStructureControllerAdminPurchasingGroupFormManagementCreateOrUpdateInitialValue<
  OutputType extends
    DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate = DefaultOrganizationalStructurePurchasingGroupRequestBodyCreate,
  DataType extends
    DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit = DefaultOrganizationalStructurePurchasingGroupResponseBodyEdit,
  OptionsType extends
    DefaultOrganizationalStructureControllerAdminPurchasingGroupFormManagementCreateOrUpdateInitialValueOptions<DataType> = DefaultOrganizationalStructureControllerAdminPurchasingGroupFormManagementCreateOrUpdateInitialValueOptions<DataType>,
>(): ICoreControllerSharedPageFormManagementInitialValueBase<
  OutputType,
  OptionsType
> {
  const initialValue = (options?: OptionsType) => {
    const mode = options?.mode ?? 'CREATE'
    const data = options?.data ?? null

    if (mode === 'CREATE' && data === null) {
      return {
        id: null,
        party: {
          id: '',
          full_name: '',
        },
        code: '',
        from_date: '',
        thru_date: '',
      } as OutputType
    }
    return {
      ...data,
    } as unknown as OutputType
  }
  return {
    initialValue,
  }
}
