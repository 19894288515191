import {
  DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
  DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
} from '@/resources'
import { ICoreControllerSharedPageFormManagementInitialValueBase } from '@procuraid-frontend/core'

export interface DefaultOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValueOptions<
  DataType extends
    DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit = DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
> {
  /**
   * @default CREATE
   */
  mode?: 'CREATE' | 'UPDATE'
  /**
   * @default null
   */
  data?: DataType | null
}

export function useOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValue<
  OutputType extends
    DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate = DefaultOrganizationalStructureScopeOfSuppliesRequestBodyCreate,
  DataType extends
    DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit = DefaultOrganizationalStructureScopeOfSuppliesResponseBodyEdit,
  OptionsType extends
    DefaultOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValueOptions<DataType> = DefaultOrganizationalStructureControllerAdminScopeOfSuppliesFormManagementCreateOrUpdateInitialValueOptions<DataType>,
>(): ICoreControllerSharedPageFormManagementInitialValueBase<
  OutputType,
  OptionsType
> {
  const initialValue = (options?: OptionsType) => {
    const mode = options?.mode ?? 'CREATE'
    const data = options?.data ?? null

    if (mode === 'CREATE' && data === null) {
      return {
        resource: 'scope_of_supply',
        id: null,
        parent_id: null,
        code: null,
        commodity_code: null,
        name: null,
        desc: null,
        state: 'INACTIVE',
        scope_of_supply_type: null,
      } as OutputType
    }
    return {
      ...data,
    } as unknown as OutputType
  }
  return {
    initialValue,
  }
}
