import { container as defaultContainer } from 'inversify-hooks-esm'

import {
  CORE_CONTROLLER_SHARED_LANGUAGE_BASE,
  CORE_CONTROLLER_SHARED_MAIN_COMPONENT_PROPS_BASE,
  CORE_CONTROLLER_SHARED_PAGE_LOGIN_STATUS_GET_RESOURCE_DATA_BASE,
  CORE_CONTROLLER_SHARED_PAGE_LOOKUP_GET_RESOURCE_DATA_BASE,
  CORE_CONTROLLER_SHARED_PAGE_NAVIGATION_TREE_GET_RESOURCE_DATA_PUBLIC,
  CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_GET_RESOURCE_DATA_LOGIN_STATUS,
  CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_UPDATE_RESOURCE_SET_ROLE,
  CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_RESOURCE_DATA_BASE,
  CORE_RESOURCE_LOOKUP_BASE,
  CORE_RESOURCE_NAVIGATION_TREE_BASE,
  CORE_RESOURCE_PING_BASE,
  CORE_RESOURCE_PRIVILEGE_BASE,
  CORE_RESOURCE_SPREADSHEET_BASE,
  CORE_SERVICE_ATTACHMENT_FORM_DATA_HANDLER,
  CORE_SERVICE_DATE_FORMATTER_SERVER_TIME,
  CORE_SERVICE_LOOKUP_BASE,
  CORE_SERVICE_PRIVILEGE_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE,
  CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_FIRST_HISTORY_BASE,
  CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_BASE,
  ICoreResourceNavigationTreeBase,
  ICoreResourcePingBase,
  ICoreResourceSpreadsheetBase,
  ICoreServicePrivilegeBase,
  ICoreServiceStateManagementInstanceUserBase,
  ICoreServiceStateManagementSelectorUserBase,
  ICoreServiceThirdPartyIntegratorReduxStoreBase,
  getCoreControllerSharedMainComponentPropsBase,
  useCoreControllerSharedLanguageBase,
  useCoreControllerSharedPageLoginStatusGetResourceDataBase,
  useCoreControllerSharedPageLookupGetResourceDataBase,
  useCoreControllerSharedPageNavigationTreeGetResourceDataPublic,
  useCoreControllerSharedPagePrivilegeGetResourceDataLoginStatus,
  useCoreControllerSharedPagePrivilegeUpdateResourceSetRole,
  useCoreControllerSharedPageServiceTimeManagerGetResourceDataBase,
} from '@procuraid-frontend/core'

import {
  CORE_CONTROLLER_SHARED_SPREADSHEET_MANAGEMENT_GET_RESOURCE_DATA_FROM_FILE,
  useCoreControllerSharedSpreadsheetManagementGetResourceDataFromFile,
} from '@/controllers/shared/spreadsheet-management/get-resource-data'

export function getControllersMainThreadIntoContainer(
  container = defaultContainer
) {
  container
    .bind(
      CORE_CONTROLLER_SHARED_SPREADSHEET_MANAGEMENT_GET_RESOURCE_DATA_FROM_FILE
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedSpreadsheetManagementGetResourceDataFromFile(
        c.get<ICoreResourceSpreadsheetBase>(CORE_RESOURCE_SPREADSHEET_BASE),
        c.get(CORE_SERVICE_ATTACHMENT_FORM_DATA_HANDLER)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_LOOKUP_GET_RESOURCE_DATA_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageLookupGetResourceDataBase(
        c.get(CORE_SERVICE_LOOKUP_BASE),
        c.get(CORE_RESOURCE_LOOKUP_BASE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_NAVIGATION_TREE_GET_RESOURCE_DATA_PUBLIC)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageNavigationTreeGetResourceDataPublic(
        c.get<ICoreResourceNavigationTreeBase>(
          CORE_RESOURCE_NAVIGATION_TREE_BASE
        )
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_GET_RESOURCE_DATA_LOGIN_STATUS)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPagePrivilegeGetResourceDataLoginStatus(
        c.get<ICoreServicePrivilegeBase>(CORE_SERVICE_PRIVILEGE_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE),
        c.get(CORE_RESOURCE_PRIVILEGE_BASE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_UPDATE_RESOURCE_SET_ROLE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPagePrivilegeUpdateResourceSetRole(
        c.get<ICoreServiceStateManagementSelectorUserBase>(
          CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE
        ),
        c.get<ICoreServiceStateManagementInstanceUserBase>(
          CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_USER_BASE
        ),
        c.get(CORE_RESOURCE_PRIVILEGE_BASE)
      )
    )

  container
    .bind(
      CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_RESOURCE_DATA_BASE
    )
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageServiceTimeManagerGetResourceDataBase(
        c.get(CORE_SERVICE_DATE_FORMATTER_SERVER_TIME),
        c.get<ICoreResourcePingBase>(CORE_RESOURCE_PING_BASE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_PAGE_LOGIN_STATUS_GET_RESOURCE_DATA_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedPageLoginStatusGetResourceDataBase(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_USER_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE),
        c.get(CORE_CONTROLLER_SHARED_PAGE_LOOKUP_GET_RESOURCE_DATA_BASE),
        c.get(
          CORE_CONTROLLER_SHARED_PAGE_PRIVILEGE_GET_RESOURCE_DATA_LOGIN_STATUS
        ),
        c.get(
          CORE_CONTROLLER_SHARED_PAGE_SERVER_TIME_MANAGER_GET_RESOURCE_DATA_BASE
        )
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_LANGUAGE_BASE)
    .toDynamicValue(({ container: c }) =>
      useCoreControllerSharedLanguageBase(
        c.get(CORE_SERVICE_STATE_MANAGEMENT_SELECTOR_APP_BASE),
        c.get(CORE_SERVICE_STATE_MANAGEMENT_INSTANCE_APP_BASE)
      )
    )

  container
    .bind(CORE_CONTROLLER_SHARED_MAIN_COMPONENT_PROPS_BASE)
    .toDynamicValue(({ container: c }) =>
      getCoreControllerSharedMainComponentPropsBase(
        c.get(CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_FIRST_HISTORY_BASE),
        c.get<ICoreServiceThirdPartyIntegratorReduxStoreBase>(
          CORE_SERVICE_THIRD_PARTY_INTEGRATOR_REDUX_STORE_BASE
        )
      )
    )

  return container
}
