/* eslint-disable @typescript-eslint/no-explicit-any */
import { CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_REGION_BASE } from '@/resources'
import {
  DefaultDropdownOptionsType,
  ICoreResourceOrganizationalStructureOnlySearch,
  useCoreControllerSharedTransformDataToDropdownOptionsFromResource,
} from '@procuraid-frontend/core'
import { useContainerGet } from 'inversify-hooks-esm'
import { useCallback, useState } from 'react'

export interface DefaultBuyerOptionsFunctionAsyncKeys {
  keys: 'country' | 'district'
}

export function useControllerAdminOrganizationalStructureBankDropdownManagementOptionsCreateOrUpdate<
  KeyType extends
    DefaultBuyerOptionsFunctionAsyncKeys = DefaultBuyerOptionsFunctionAsyncKeys,
  DropdownOptionsType extends
    DefaultDropdownOptionsType = DefaultDropdownOptionsType,
>() {
  const [loadings, setLoadings] = useState({
    country: true,
    district: true,
  } as Record<KeyType['keys'], boolean>)
  const [options, setOptions] = useState({
    country: [],
    district: [],
  } as Record<KeyType['keys'], DropdownOptionsType[]>)

  const { transformResourceDataToDropdownOptions } =
    useCoreControllerSharedTransformDataToDropdownOptionsFromResource()

  const regionResourceSearch = useContainerGet<
    ICoreResourceOrganizationalStructureOnlySearch<{ data: { items: any[] } }>
  >(CORE_RESOURCE_ORGANIZATIONAL_STRUCTURE_REGION_BASE)

  const createOptions = useCallback(async () => {
    try {
      const country = (await transformResourceDataToDropdownOptions(
        () =>
          regionResourceSearch.search({
            params: {
              region_type: 'COUNTRY',
            },
          }),
        (item) => `${item?.['code']} - ${item?.['name']}`
      )) as DropdownOptionsType[]
      const district = (await transformResourceDataToDropdownOptions(
        () =>
          regionResourceSearch.search({
            params: {
              region_type: 'DISTRICT',
            },
          }),
        (item) => `${item?.['code']} - ${item?.['name']}`
      )) as DropdownOptionsType[]
      return {
        country,
        district,
      }
    } finally {
      setLoadings({
        country: false,
        distrcit: false,
      } as unknown as Record<KeyType['keys'], boolean>)
    }
  }, [regionResourceSearch, transformResourceDataToDropdownOptions])

  const loadOptions = useCallback(async () => {
    const options = await createOptions()
    setOptions(options)
  }, [createOptions])

  return {
    options,
    createOptions,
    loadings,
    loadOptions,
    regionResourceSearch,
  }
}
