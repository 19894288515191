import '@/assets/styles/procura-styles.scss'
import '@fontsource/open-sans/latin.css'
import 'simplebar-react/dist/simplebar.min.css'
import 'react-bootstrap-table-next-esm/dist/css/react-bootstrap-table-next-esm.min.css'
import '@achmadk/react-epic-spinner-css/dist/style.css'

import {
  CORE_CONTROLLER_SHARED_APP_INITIALIZER_BASE,
  ICoreControllerSharedAppInitializerBase,
} from '@procuraid-frontend/core'

import { initializerContainer } from '@/di/main-thread/initializer'

const appInitializer =
  initializerContainer.get<ICoreControllerSharedAppInitializerBase>(
    CORE_CONTROLLER_SHARED_APP_INITIALIZER_BASE
  )

appInitializer.init()
