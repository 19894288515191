import {
  DefaultSpreadsheetResponseBodyImport,
  DefaultSpreadsheetResponseBodyImportOriginal,
  ICoreControllerSharedPageTransformToViewBase,
} from '@procuraid-frontend/core'

export function useCoreControllerSharedSpreadsheetManagementTransformResponseBodyImport<
  InputType extends
    DefaultSpreadsheetResponseBodyImportOriginal = DefaultSpreadsheetResponseBodyImportOriginal,
  OutputType extends
    DefaultSpreadsheetResponseBodyImport = DefaultSpreadsheetResponseBodyImport,
>(): ICoreControllerSharedPageTransformToViewBase<InputType, OutputType> {
  const transform = (input: InputType) => {
    input.data.rows = input.data.rows.map((row) => {
      const data = input.data.headers.map((header) => {
        return {
          ...header,
          value: row.cell_hsh?.[header.name] ?? null,
        }
      })
      return {
        ...row,
        data,
      }
    })
    return input as unknown as OutputType
  }

  return {
    transform,
  }
}
